import React, { useEffect, useRef } from "react";

import { Typography } from "../typography";
import { TextInput } from "../input";
import { generateUID } from "src/services/Helpers";
import { Button } from "../button/Button";
import { ModalContactProps } from "./Types";

export const ModalContactForm = ({
  dataContainer,
  handleSubmit,
  handleChange,
  data,
  errors,
  isCorrect,
  setIsOpen,
}: ModalContactProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOnDocumentClick = (e) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(e.target) &&
        setIsOpen
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOnDocumentClick, true);

    return () => {
      document.removeEventListener("click", handleOnDocumentClick);
    };
  }, [modalRef]);

  const handleOnCloseButtonClick = () => setIsOpen && setIsOpen(false);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center absolute bg-smoke z-[20] sm:z-[15]">
      <div className="max-w-full max-h-full h-full sm:max-w-[90%] sm:w-[600px] sm:max-h-[70%] overflow-scroll bg-white p-10 relative" ref={modalRef}>
        <div className="absolute right-[2.5rem] top-[2rem] bg-white">
          <Button
            icon={"Close"}
            className="mr-auto"
            onClick={handleOnCloseButtonClick}
          />
        </div>
        {isCorrect ? (
          <h2 className="text-center text-primary text-4xl py-4 font-bold">
            {dataContainer?.sectionKey(
              "Translations",
              "contact_modal_confirmation"
            )}
          </h2>
        ) : (
          <div className="mt-3">
            <h2 className="text-4xl text-primary mb-5">
              {dataContainer?.sectionKey("Translations", "contact_modal_title")}
            </h2>
            <p className="text-3xl text-black border-b pb-5 border-gray-mid">
              {dataContainer?.sectionKey("Translations", "contact_modal_text")}
            </p>
            <Typography
              tag="h4"
              className="pt-5 font-medium text-black mb-5"
              parentClassName="h-auto"
            >
              {dataContainer?.sectionKey("Translations", "form_label")}
            </Typography>
            <form onSubmit={handleSubmit}>
              <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-4 gap-y-2 w-full mb-14">
                <TextInput
                  id="first_name"
                  value={data.first_name}
                  placeholder="Förnamn*"
                  onChange={handleChange}
                  error={errors?.first_name}
                  parentClassName="order-1 sm:order-none"
                />
                <TextInput
                  type="email"
                  id="email"
                  value={data.email}
                  placeholder="E-post*"
                  onChange={handleChange}
                  error={errors?.email}
                  parentClassName="order-2 sm:order-none"
                />
                <TextInput
                  id="phone_number"
                  value={data.phone_number}
                  placeholder="Telefonnummer"
                  onChange={handleChange}
                  error={errors?.phone_number}
                  parentClassName="order-3 sm:order-none"
                />
                <TextInput
                  id="country"
                  value={data.country}
                  placeholder="Ort*"
                  onChange={handleChange}
                  error={errors?.country}
                  parentClassName="order-4 sm:order-none"
                />
              </div>
              <div className="mb-12">
                {Object.keys(errors).map(
                  (key) =>
                    errors[key] && (
                      <p
                        className="text-red text-xs mb-3.5 pl-5"
                        key={generateUID(9)}
                      >
                        {errors[key]}
                      </p>
                    )
                )}
              </div>
              <div className="overflow-hidden">
                <label
                  className="sm:text-3xl text-2xl text-black font-medium"
                  htmlFor="message"
                >
                  {dataContainer?.sectionKey(
                    "Translations",
                    "contact_modal_help_text"
                  )}
                </label>
                <textarea
                  rows={5}
                  id="message"
                  name="message"
                  placeholder="Om du har en särskild önskan skriv detta här."
                  className="w-full sm:p-5 p-3 sm:text-3xl text-2xl mt-2 bg-gray-light resize-none outline-0 placeholder:text-black sm:placeholder:text-3xl placeholder:text-2xl block placeholder:font-normal placeholder:opacity-50"
                  value={data.message}
                  onChange={handleChange}
                />
              </div>
              <div className="block md:flex mt-5">
                <div className="max-w-[100%] w-full md:w-[50%] flex justify-between items-center mb-5">
                  <input
                    type="checkbox"
                    name="terms"
                    id="terms"
                    className="flex items-center mr-5"
                    onChange={handleChange}
                  />
                  <label htmlFor="terms" className="text-4xl text-[#333]">
                    {dataContainer?.sectionKey("Translations", "policy_text")}
                    {
                      <a
                        href={dataContainer?.sectionKey(
                          "Translations",
                          "policy_link"
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2 text-primary"
                      >
                        policy
                      </a>
                    }
                  </label>
                </div>
                <Button
                  label={dataContainer?.sectionKey(
                    "Translations",
                    "contact_modal_button"
                  )}
                  icon="RightGreenArrow"
                  className="flex flex-row-reverse self-center bg-white py-3 px-6 text-primary border border-gray sm:mx-auto md:ml-auto md:mr-0"
                  iconClassName="pl-4 stroke-primary"
                  onClick={() => ({})}
                />
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
