import React, { useEffect, useRef } from "react";
import "@google/model-viewer/dist/model-viewer";
import { Button } from "../components/button/Button";
import { useLocation } from "react-router";
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "model-viewer": ModelViewerJSX &
        React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

type ModelViewerJSX = {
  src: string;
  ar: boolean;
};
type LocationState = {
  from: string;
};

export const AugmentedRealityPage = () => {
  const location = useLocation().state;
  const { from } = location as LocationState;
  const inputEl = useRef(null);

  useEffect(() => {
    const handleMeshes = (e) => {
      e.target.activateAR();
    };

    if (inputEl.current) {
      inputEl.current.addEventListener("load", handleMeshes);
    }
    return () => inputEl.current.removeEventListener("load", handleMeshes);
  }, [inputEl]);

  return (
    <>
      <Button
        to={from.replace("&ar=ok", "")}
        icon="BackArrow"
        className="absolute left-1/2 bottom-16 -translate-x-1/2 z-50 w-11 h-11 rounded-full flex justify-center items-center bg-white border border-smoke"
      />
      <model-viewer
        src={localStorage.getItem("ar")}
        camera-controls
        auto-rotate
        ar={true}
        ar-modes={"webxr scene-viewer quick-look"}
        ref={inputEl}
        ar-scale="auto"
        style={{ width: "100%", height: "80vh" }}
      />
    </>
  );
};
