import React from "react";
import * as Icons from "./icons";
import { IconProps } from "./Types";

export const Icon = ({ className, name, fill, stroke }: IconProps) => {
  const IconComponent = Icons[name];

  if (!name || !IconComponent) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any;
  }
  return (
    <div>
      <IconComponent
        name={name}
        className={className}
        fill={fill}
        stroke={stroke}
      />
    </div>
  );
};
