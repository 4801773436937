import { CubicEase, EasingFunction, Animation, Tools } from "@babylonjs/core";
import { AccordionEnum } from "src/services/enums";

const FRAMES_PER_SECOND = 100;

const SPEED_RATIO = 2;
const LOOP_MODE = false;
const FROM_FRAME = 0;
const TO_FRAME = 100;

export const firstAnimation = [
  AccordionEnum.Profile,
  AccordionEnum.Ventilation,
  AccordionEnum.Hinge,
];

export const secondAnimation = [
  AccordionEnum.Handle,
  AccordionEnum.Lock,
  AccordionEnum.Cover_Plate,
];

export function animateCameraOffset(camera, newOffset) {
  Animation.CreateAndStartAnimation(
    "offset",
    camera,
    "targetScreenOffset",
    900,
    300,
    camera.targetScreenOffset,
    newOffset,
    Animation.ANIMATIONLOOPMODE_CONSTANT
  );
}

export function moveActiveCamera(
  scene,
  camera,
  { radius, alpha, beta },
  speedRatio?,
  frames_per_second?
) {
  camera.animations = [
    createAnimation({
      property: "radius",
      from: camera.radius,
      to: radius,
      frames_per_second: frames_per_second || FRAMES_PER_SECOND,
    }),
    createAnimation({
      property: "beta",
      from: simplifyRadians(camera.beta),
      to: beta,
      frames_per_second: frames_per_second || FRAMES_PER_SECOND,
    }),
    createAnimation({
      property: "alpha",
      from: simplifyRadians(camera.alpha),
      to: alpha,
      frames_per_second: frames_per_second || FRAMES_PER_SECOND,
    }),
  ];

  if (scene.beginAnimation !== undefined) {
    scene.beginAnimation(
      camera,
      FROM_FRAME,
      TO_FRAME,
      LOOP_MODE,
      speedRatio || SPEED_RATIO
    );
  }
}

function createAnimation({ property, from, to, frames_per_second }) {
  const ease = new CubicEase();
  ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

  const animation = Animation.CreateAnimation(
    property,
    Animation.ANIMATIONTYPE_FLOAT,
    frames_per_second || FRAMES_PER_SECOND,
    ease
  );
  animation.setKeys([
    {
      frame: 0,
      value: from,
    },
    {
      frame: 100,
      value: to,
    },
  ]);

  return animation;
}

function simplifyRadians(radians) {
  const simplifiedRadians = radians % (2 * Math.PI);

  return simplifiedRadians < 0
    ? simplifiedRadians + Tools.ToRadians(360)
    : simplifiedRadians;
}
