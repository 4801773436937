import React from "react";
import { DoorsGridProps } from "./types";
import classnames from "classnames";
import { Icon } from "../../../components/icon/Icon";
import { Link } from "react-router-dom";
import { generateUID } from "../../../services/Helpers";
import useCheckMobileScreen from "../../../hooks/CheckMobileScreen";

const numberOfCols = { singleDoor: 4, doubleDoors: 3 };

export const DoorsGrid = ({
  children,
  className,
  variant,
  seeAllModels,
}: DoorsGridProps) => {
  const childrenCount = React.Children.count(children);
  const colsToSpan =
    numberOfCols[variant] - (childrenCount % numberOfCols[variant]);

  const styles = {
    gridColumn: `span ${colsToSpan} / span ${colsToSpan}`,
  };
  const isMobile = useCheckMobileScreen();

  return (
    <div
      className={classnames(
        "grid-cols-auto gap-4 md:gap-5",
        className,
        innerWidth > 640 && innerWidth < 900 ? "flex flex-wrap justify-center" : "grid"
      )}
    >
      {children}
      {seeAllModels && (
        <Link
          to={`${seeAllModels.URL}?isFromMenu=true`}
          className={classnames(
            "flex gap-6 self-center justify-self-center items-center sm:mt-0 mt-10 hover:opacity-70 active:opacity-80 hover:cursor-pointer",
            { "mt-6": colsToSpan === numberOfCols[variant] },
            {"col-span-3": isMobile}
          )}
          style={!isMobile ? styles : {}}
        >
          <div className="flex flex-col text-center text-4xl">
            {seeAllModels.text.split(" ").map((word) => (
              <span key={generateUID(10)}>{word}</span>
            ))}
          </div>
          <Icon name="RightArrow" />
        </Link>
      )}
    </div>
  );
};
