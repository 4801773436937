import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  HomePage,
  SingleProduct,
  AllModelsPage,
  OrderPage,
  ConfirmationPage,
} from "./views";
import { DataContainerProvider } from "./common/context/DataContainerProvider";
import { AugmentedRealityPage } from "./views";

function App() {
  return (
    <DataContainerProvider>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/windows" element={<HomePage />} />
        <Route path="/models/:category/:variant" element={<AllModelsPage />} />
        <Route path="/:productType/:category/:singleID" element={<SingleProduct />} />
        <Route path="/summary" element={<OrderPage />} />
        <Route path="/confirmation" element={<ConfirmationPage />} />
        <Route path="/ar" element={<AugmentedRealityPage />} />
      </Routes>
    </DataContainerProvider>
  );
}

export default App;
