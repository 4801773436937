import React, { useContext, useEffect } from "react";
import { DataContainerContext } from "../common/context/DataContainerProvider";
import { Icon } from "../components/icon/Icon";
import { Logo } from "../components/logo/Logo";
import { Typography } from "../components/typography";

export const ConfirmationPage = () => {
  const { dataContainer } = useContext(DataContainerContext);
  
  useEffect(() => {
    if(dataContainer) {
      dataContainer.logEvent("page_entered", "Confirmation page", {product: ""});
    }
  }, [dataContainer]);

  return (
    <div className="p-10 w-full h-full bg-smoke">
      <div className="flex justify-center items-center w-full h-full bg-white">
        <div className="flex flex-col justify-center items-center text-center">
          <Logo to="/" className="sm:!w-[7.5rem] sm:!h-24 mb-10" />
          <Typography
            tag="h1"
            className="text-primary font-normal leading-5 mb-8 text sm:text-6xl text-4xl"
          >
            {dataContainer?.sectionKey(
              "Translations",
              "confirmation_page_title"
            )}
          </Typography>
          <Typography
            tag="p"
            className="mb-24 text-black-faded sm:px-32  px-6 sm:text-3xl text-2xl"
          >
            {dataContainer?.sectionKey(
              "Translations",
              "confirmation_page_text"
            )}
          </Typography>
          <a
            href={dataContainer?.sectionKey(
              "Translations",
              "confirmation_page_link"
            )}
            className="flex text-black-faded font-normal sm:text-xs text-3xl leading-4"
          >
            {dataContainer?.sectionKey("Translations", "confirmation_page_btn")}
            <Icon name="RightArrow" className="ml-4 sm:h-auto h-3.5" />
          </a>
        </div>
      </div>
    </div>
  );
};
