/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  DimensionsMeshes,
  BaseDimensions,
  DimensionsDivider,
  productTypes,
  WindowCategories,
  ModelVariants,
  CategoryIds,
  AccordionEnum,
} from "src/services/enums";
import { kickplateNodes } from "../HideMeshes";
import { applyAirDividers, hideAllMeshes } from "./AirDividersHandlers";
import { applyDividers } from "./ApplyDividers";
import { handleSprojsPositions } from "./DividersHandles";

const BABYLON_UNIT_MULTIPLIER = 1000;

export const extractDimensions = (variant, productType) => {
  return [
    Number(variant.assets[0].attribute_values[0].value) /
      DimensionsDivider[productType],
    Number(variant.assets[0].attribute_values[1].value) /
      DimensionsDivider[productType],
  ];
};

const extractVisibeNodes = (scene) => {
  const visibleNodes: any = [];

  DimensionsMeshes.forEach((dimensionMesh) => {
    scene.getTransformNodesById(dimensionMesh).forEach((node) => {
      let parentMesh = node.parent.parent;
      if (parentMesh.getClassName() === "TransformNode") {
        parentMesh = parentMesh.parent;
      }
      if (parentMesh.isVisible) {
        visibleNodes.push(node);
      }
    });
  });

  return visibleNodes;
};

export const saveInitialPosition = (scene) => {
  const allMeshes = DimensionsMeshes.concat(kickplateNodes);
  allMeshes.forEach((dimensionMesh) => {
    scene.getTransformNodesById(dimensionMesh).forEach((node) => {
      node.metadata.position = { x: node.position.x, y: node.position.y };
    });
  });
};

const getNewWidth = (width, category) => {
  if (!WindowCategories.includes(ModelVariants[category])) {
    return width / 10 / 2;
  }

  if (ModelVariants[category] === CategoryIds.windows["door-window-double"].id) {
    return width / 10;
  }

  return 1 - width / 10;
};

const getNewHeight = (height, category) => {
  if (!WindowCategories.includes(ModelVariants[category]) ||
  ModelVariants[category] === CategoryIds.windows["door-window-double"].id
  ) {
    return height / 10;
  }

  return -1 + height / 10;
};

export const applyDimensions = (
  state,
  dimensions,
  dataContainer,
  initialLoad = false
) => {
  const category = dataContainer?.getCategoryOfCurrentModel();

  const newWidth = getNewWidth(dimensions[0], category);
  const newHeight = getNewHeight(dimensions[1], category);

  const currentModel = dataContainer?.findVariationBy(
    dataContainer?.getCurrentModel()
  );

  const isAsymmetric = currentModel?.key?.includes("asymm") && category === "double";

  const visibleNodes = extractVisibeNodes(state.scene);
  visibleNodes.forEach((node) => {
    if (
      category === productTypes.doubleDoors ||
      category === productTypes.doubleDoorWindows
    ) {
      applyDoubleDimensions(node, { newWidth, newHeight }, category, isAsymmetric);
    } else {
      applySingleDimensions(node, { newWidth, newHeight }, category);
    }
  });

  if (WindowCategories.includes(ModelVariants[category])) {
    if ([17, 18].includes(ModelVariants[category])) {
      const luftTypeVariation =
        dataContainer?.findSelectedVariantByComponentName(
          AccordionEnum.Luft_Types
        );

      if (luftTypeVariation) {
        applyAirDividers(
          luftTypeVariation.original_key,
          state.scene,
          currentModel,
          dataContainer,
          initialLoad
        );
      } else {
        applyAirDividers("1a", state.scene, currentModel, dataContainer);
      }
      handleSprojsPositions(state.scene, dataContainer);
      const sprojsTypeVariation =
      dataContainer?.findSelectedVariantByComponentName("Sprojs");
      if(sprojsTypeVariation){
        applyDividers(state.scene);
      }
    } else {
      applyAirDividers("1a", state.scene, currentModel, dataContainer);
      dataContainer?.setConfigurationMetadata(
        "frameDimensions",
        {
          width: dimensions[0] * 100,
          height: dimensions[1] * 100,
        },
        "windows"
      );
    }
    const sprojsTypeVariation =
      dataContainer?.findSelectedVariantByComponentName("Sprojs");
    if(sprojsTypeVariation){
      applyDividers(state.scene);
    }
  } else {
    hideAllMeshes();
    dataContainer?.setConfigurationMetadata(
      "frameDimensions",
      {
        width: newWidth * BABYLON_UNIT_MULTIPLIER,
        height: newHeight * BABYLON_UNIT_MULTIPLIER,
      },
      "doors"
    );
  }
};

export const applySingleDimensions = (mesh, dimensions, category) => {
  const { newWidth, newHeight } = dimensions;
  switch (mesh.name) {
  case "Top_Left":
    mesh.position.x = -newWidth;
    mesh.position.y = newHeight;
    break;
  case "Top_Left_Door":
    mesh.position.x = -(
      newWidth +
        (Math.abs(mesh.metadata.position.x) - BaseDimensions[category].x)
    );
    mesh.position.y =
        newHeight - (BaseDimensions[category].y - mesh.metadata.position.y);
    break;
  case "Bottom_Left":
    mesh.position.x = -newWidth;
    break;
  case "Bottom_Left_Door":
    mesh.position.x = -(
      newWidth +
        (Math.abs(mesh.metadata.position.x) - BaseDimensions[category].x)
    );
    break;
  case "Top_Right":
  case "bone_frame_top_right":
  case "bone_door_top_right":
    mesh.position.y = newHeight;
    break;
  case "Top_Right_Door":
    mesh.position.y =
        newHeight - (BaseDimensions[category].y - mesh.metadata.position.y);
    break;
  case "bone_door_top_left":
    mesh.position.x =
        newWidth + mesh.metadata.position.x + BaseDimensions[category].x;
    mesh.position.y = newHeight;
    break;
  case "bone_frame_top_left":
    mesh.position.x = newWidth;
    mesh.position.y = newHeight;
    break;
  case "bone_frame_bottom_left":
    mesh.position.x = newWidth;
    break;
  case "bone_door_bottom_left":
  case "bone_door_mid_left":
    mesh.position.x =
        newWidth + mesh.metadata.position.x + BaseDimensions[category].x;
  }
};

export const applyDoubleDimensions = (mesh, dimensions, category, isAsymmetric = false) => {
  const rightRotatorSubtractor = isAsymmetric ? 0.021 : 0;
  const { newWidth, newHeight } = dimensions;
  switch (mesh.name) {
  case "Top_Left":
  case "bone_frame_top_left":
    mesh.position.x = -newWidth;
    mesh.position.y = newHeight;
    break;
  case "Top_Left_DoorL":
  case "bone_doorL_top_left":
    mesh.position.x =
        -newWidth + (mesh.metadata.position.x + BaseDimensions[isAsymmetric ? "double-asymmetric" : category].x);
    mesh.position.y =
        newHeight + (mesh.metadata.position.y - BaseDimensions[isAsymmetric ? "double-asymmetric" : category].y);
    break;
  case "Top_Right_DoorL":
  case "bone_doorL_top_right":
    mesh.position.y =
        newHeight + (mesh.metadata.position.y - BaseDimensions[isAsymmetric ? "double-asymmetric" : category].y);
    break;
  case "Bottom_Left":
  case "bone_frame_bottom_left":
    mesh.position.x = -newWidth;
    break;
  case "Bottom_Left_DoorL":
  case "bone_doorL_bottom_left":
  case "bone_doorL_mid_left":
    mesh.position.x =
        -newWidth + (mesh.metadata.position.x + BaseDimensions[isAsymmetric ? "double-asymmetric" : category].x);
    break;
  case "Top_Right":
  case "bone_frame_top_right":
    mesh.position.x = newWidth;
    mesh.position.y = newHeight;
    break;
  case "Top_Right_DoorR":
    mesh.position.x =
        newWidth -
        (Math.abs(mesh.metadata.position.x) + BaseDimensions[isAsymmetric ? "double-asymmetric" : category].x - rightRotatorSubtractor);
    mesh.position.y =
        newHeight + (mesh.metadata.position.y - BaseDimensions[isAsymmetric ? "double-asymmetric" : category].y);
    break;
  case "Top_Left_DoorR":
  case "bone_doorR_top_left":
    mesh.position.y =
        newHeight + (mesh.metadata.position.y - BaseDimensions[isAsymmetric ? "double-asymmetric" : category].y);
    break;
  case "BottomLRight":
  case "bone_frame_bottom_right":
    mesh.position.x = newWidth;
    break;
  case "Bottom_Right_DoorR":
    mesh.position.x =
        newWidth -
        (Math.abs(mesh.metadata.position.x) + BaseDimensions[isAsymmetric ? "double-asymmetric" : category].x - rightRotatorSubtractor);
    break;
  case "bone_doorR_bottom_right":
  case "bone_doorR_mid_right":
    mesh.position.x =
        newWidth - (BaseDimensions[isAsymmetric ? "double-asymmetric" : category].x - mesh.metadata.position.x);
    break;
  case "bone_doorR_top_right":
    mesh.position.x =
        newWidth - (BaseDimensions[isAsymmetric ? "double-asymmetric" : category].x - mesh.metadata.position.x);
    mesh.position.y =
        newHeight + (mesh.metadata.position.y - BaseDimensions[isAsymmetric ? "double-asymmetric" : category].y);
    break;
  }
};
