import React from "react";
import { TextInputProps } from "./Types";
import classnames from "classnames";

export const TextInput = ({
  value,
  type,
  uniqueKey,
  id,
  onChange,
  disabled,
  className,
  placeholder,
  label,
  error,
  parentClassName,
}: TextInputProps) => (
  <div className={parentClassName}>
    <label className="text-base" htmlFor={id}>
      {label && <span>{label}</span>}
      <input
        type={type || "text"}
        id={id}
        value={value}
        name={id}
        className={classnames(
          "w-full py-2 px-5 bg-gray-light text-3xl outline-0 border border-transparent placeholder:text-black placeholder:text-3xl placeholder:font-normal placeholder:opacity-50",
          className,
          {
            "border border-red border-opacity-50 placeholder:text-red placeholder:opacity-100":
              error,
          }
        )}
        onChange={onChange}
        disabled={disabled}
        data-unique-key={uniqueKey}
        placeholder={placeholder}
      />
    </label>
  </div>
);
