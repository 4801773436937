import React from "react";
import { IconProps } from "../Types";

const Measure = ({ className }: IconProps) => (
  <svg
    className={className}
    width="10"
    height="37"
    viewBox="0 0 10 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.909091 0C0.407014 0 0 0.416741 0 0.930818V36.0692C0 36.5833 0.407014 37 0.909091 37H9.09091C9.59299 37 10 36.5833 10 36.0692V0.930817C10 0.41674 9.59299 0 9.09091 0H0.909091ZM0.909091 36.0692V0.930818H2.27273L2.27273 36.0692H0.909091ZM3.18182 31.8805H5.68182C5.93286 31.8805 6.13636 31.6721 6.13636 31.4151C6.13636 31.1581 5.93286 30.9497 5.68182 30.9497H3.18182V26.5283H7.5C7.75104 26.5283 7.95455 26.3199 7.95455 26.0629C7.95455 25.8059 7.75104 25.5975 7.5 25.5975H3.18182L3.18182 21.1761H5.68182C5.93286 21.1761 6.13636 20.9677 6.13636 20.7107C6.13636 20.4537 5.93286 20.2453 5.68182 20.2453H3.18182L3.18182 15.8239H7.5C7.75104 15.8239 7.95455 15.6155 7.95455 15.3585C7.95455 15.1015 7.75104 14.8931 7.5 14.8931H3.18182L3.18182 10.4717H5.68182C5.93286 10.4717 6.13636 10.2633 6.13636 10.0063C6.13636 9.74925 5.93286 9.54088 5.68182 9.54088H3.18182L3.18182 5.1195H7.5C7.75104 5.1195 7.95455 4.91113 7.95455 4.65409C7.95455 4.39705 7.75104 4.18868 7.5 4.18868H3.18182L3.18182 0.930818H9.09091V36.0692H3.18182L3.18182 31.8805Z"
      fill="#333333"
    />
  </svg>
);

export default Measure;
