import classnames from "classnames";
import React from "react";
import { Button } from "../button/Button";
import { GlassModalProps } from "./Types";
import { Image } from "../image/image";

export const GlassModal = ({ image, className, close }: GlassModalProps) => (
  <div className="sm:w-2/3 w-full fixed h-full sm:z-50 z-10 md:z-auto">
    <div className="absolute h-full w-full">
      <div
        onClick={close}
        className="absolute w-full h-full left-0 top-0 z-50 before:content-[''] before:w-full before:h-full before:absolute before:left-0 before:top-0 before:bg-black before:opacity-30"
      />
      <div
        className={classnames(
          "p-3 backdrop-blur-sm sm:max-w-[39.5rem] max-w-[20.5rem] sm:max-h-[39.5rem] max-h-[23.5rem] z-50 absolute bottom-1/2 right-1/2 h-full w-full translate-y-1/2 translate-x-2/4 bg-white",
          className
        )}
      >
        <div className="flex absolute sm:p-4 p-2 right-0 top-0 bg-white">
          <Button icon={"Close"} onClick={close} />
        </div>
        <Image
          src={image?.src}
          title={image?.title}
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  </div>
);
