import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import classnames from "classnames";
import { Card } from "../components/card";
import { Typography } from "../components/typography/Typography";
import { Header } from "../components/header/Header";
import { DataContainerContext } from "../common/context/DataContainerProvider";
import {
  AccordionEnum,
  ModelVariants,
  WindowCategories,
  ProductTypes,
} from "../services/enums";
import { generateUID } from "../services/Helpers";

export const AllModelsPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { variant, category } = useParams();
  const showAddButton = searchParams.get("add") === "true";
  const shouldRedirect = searchParams.get("redirect") === "true";
  const isFromMenu = searchParams.get("isFromMenu") === "true";

  const { dataContainer } = useContext(DataContainerContext);

  useEffect(() => {
    if (dataContainer && shouldRedirect && category) {
      const categoryConfigurationVariations =
        dataContainer?.getConfigurationVariations(
          dataContainer?.mCurrentConfigurationIndex[category],
          category
        );
      if (!categoryConfigurationVariations.length) {
        setSearchParams({ redirect: "false" });
        return;
      }

      let foundResult = false;

      categoryConfigurationVariations.forEach((variation) => {
        if (
          dataContainer?.findComponentById(variation?.component_id).name ===
          AccordionEnum.Model
        ) {
          const modelCategory = dataContainer?.findModelCategoryByVariationId(
            variation.id
          );

          dataContainer?.updateCurrentConfiguration();
          navigate(`/${category}/${modelCategory}/${variation.id}`);
          foundResult = true;
        }
      });

      if (!foundResult) {
        setSearchParams({ redirect: "false" });
      }
    }
  }, [dataContainer, shouldRedirect, category]);

  const selectedModel = dataContainer?.findSelectedVariantByComponentName(
    AccordionEnum.Model
  );

  const redirect = (modelId) => {
    const modelCategory =
      dataContainer?.findModelCategoryByVariationId(modelId);
    dataContainer?.unselectDependentVariations();
    if(category?.includes("windows")){
      dataContainer?.unselectVariationByComponentName("Sprojs");
    }
    navigate(`/${category}/${modelCategory}/${modelId}`);
  };

  const createConfigurationHandler = (id) => {
    const categoryConfigurationVariations =
      dataContainer?.getConfigurationVariations(
        dataContainer?.mCurrentConfigurationIndex[category],
        category
      );
    if (categoryConfigurationVariations.length) {
      dataContainer?.createNewConfiguration(id, category);
    }
    
    redirect(id);
  };

  useEffect(() => {
    if (dataContainer) {
      dataContainer.logEvent("page_entered", "All models page", {
        product: "",
      });
    }
  }, [dataContainer]);

  const models = dataContainer?.getAllModelsVariations(
    category === ProductTypes.doors.key
      ? [ModelVariants[variant]]
      : WindowCategories
  );

  return (
    !shouldRedirect && (
      <div className="w-full h-full bg-smoke pb-3 overflow-auto">
        <Header 
          isMenuOpen={true}
          toggleMenu={() => {
            searchParams.set("redirect", "true");
            navigate(-1);
          }}
          isMenuVisible={category === ProductTypes.doors.key}
          className={classnames({ "px-10": isFromMenu })}
          menuClassName="flex-col"
          closeButton={{
            isShown: isFromMenu,
            onClick: () =>
              navigate(`/${category}/${variant}/${selectedModel.id}`),
          }}
        />
        <div className="justify-center mb-12 sm:flex hidden">
          <Typography
            tag="h1"
            className="w-full text-5xl text-black-faded font-normal text-center leading-4 tracking-wide"
          >
            {dataContainer?.sectionKey(
              "Translations",
              `all_models_page_title_${category || ""}`
            )}
          </Typography>
        </div>

        {dataContainer && (
          <div className="sm:px-11 px-4 grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-[1px]">
            {models?.map((item) => (
              <div
                className={classnames("shadow-[0_0_0_1px_black] py-5", {
                  "hover:cursor-pointer": !showAddButton,
                })}
                key={generateUID(10)}
                onClick={
                  !showAddButton ? redirect.bind(null, item.id) : undefined
                }
              >
                <Card
                  title={item.key}
                  showAddButton={showAddButton}
                  image={{
                    src: item.assets[0].preview_image_url,
                    title: item.key + " image",
                    height: "132px",
                  }}
                  onAddButtonClick={() => createConfigurationHandler(item.id)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  );
};
