import React from "react";
export * from "./HomePage";
export * from "./AllModelsPage";
export * from "./OrderPage";
export * from "./ConfirmationPage";
export * from "./AugmentedRealityPage";
import BabylonProvider from "../common/context/BabylonProvider";
import { SinglePage } from "./SinglePage";

const SingleProduct = () => {
  return (
    <BabylonProvider>
      <SinglePage />
    </BabylonProvider>
  );
};

export { SingleProduct };
