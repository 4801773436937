import React, { useState, useContext } from "react";
import { AirDividerSlidersProps } from "./types";
import classnames from "classnames";
import { SliderRange } from "../slider";
import { adjustDividerPosition } from "../babylon/services/AirDividersHandlers";
import { AccordionEnum } from "src/services/enums";
import { BabylonContext } from "../../common/context/BabylonProvider";

const values = [200, 300, 400, 500, 600];
const initState = {
  vertical0: values[2],
  vertical1: values[2],
  horizontal0: values[2],
};

const AirDividerSliders = ({
  isVisible,
  dataContainer,
}: AirDividerSlidersProps) => {
  const [value, setValue] = useState(initState);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { state } = useContext<any>(BabylonContext);

  const setSliderValue = (newValue: number, type: string) => {
    setValue({
      ...value,
      [type]: newValue,
    });

    const direction = newValue > value[type] ? 0.01 : -0.01;
    const selectedLuftVariation =
      dataContainer?.findSelectedVariantByComponentName(
        AccordionEnum.Luft_Types
      );
    if (selectedLuftVariation) {
      adjustDividerPosition(
        type,
        direction,
        selectedLuftVariation.original_key,
        state.scene,
        dataContainer
      );
    }
    setTimeout(() => {
      setValue({
        ...value,
        [type]: values[2],
      });
    }, 100);
  };

  return (
    <div
      className={classnames(
        {
          "opacity-100 visible absolute w-full h-full air-dividers": isVisible,
        },
        { "opacity-0 invisible": !isVisible }
      )}
    >
      <div className="absolute  slider-remove-text w-[1px] firstVertical">
        <SliderRange
          values={values}
          value={[value["vertical0"]]}
          defaultValue={100}
          step={100}
          vertical={false}
          prefix="mm"
          onChange={(value) => setSliderValue(value, "vertical0")}
        />
      </div>
      <div className="absolute  slider-remove-text w-[1px] secondVertical">
        <SliderRange
          values={values}
          value={[value["vertical1"]]}
          defaultValue={100}
          step={100}
          vertical={false}
          prefix="mm"
          onChange={(value) => setSliderValue(value, "vertical1")}
        />
      </div>
      <div className="absolute  slider-remove-text h-[1px] max-h-60 top-80 horizontal">
        <SliderRange
          values={values}
          value={[value["horizontal0"]]}
          defaultValue={100}
          step={100}
          vertical={true}
          prefix="mm"
          onChange={(value) => setSliderValue(value, "horizontal0")}
        />
      </div>
    </div>
  );
};

export default AirDividerSliders;
