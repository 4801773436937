export const setKickPlate = (scene, selectedKickplate, category) => {
  const leftDoor = scene
    .getTransformNodesById("Bottom_Left")
    .find((node) => node.parent.parent.isVisible);
  const rightDoor = scene
    .getTransformNodesById("BottomLRight")
    .find((node) => node.parent.parent.isVisible);

  if (category === "double" && isDoubledKickplate(selectedKickplate)) {
    const kickPlateLeft = scene
      .getTransformNodesById("DoorL_Left")
      ?.find((node) => node.parent.parent.parent.isVisible);
    const kickPlateRight = scene
      .getTransformNodesById("DoorR_Right")
      ?.find((node) => node.parent.parent.parent.isVisible);

    if (leftDoor && kickPlateLeft) {
      const leftPositionDifference =
        Math.abs(leftDoor.metadata.position.x) -
        Math.abs(kickPlateLeft.metadata.position.x);
      const rightPositionDifference =
        Math.abs(rightDoor.metadata.position.x) -
        Math.abs(kickPlateRight.metadata.position.x);

      kickPlateLeft.position.x = leftDoor.position.x + leftPositionDifference;
      kickPlateRight.position.x =
        rightDoor.position.x - rightPositionDifference;
    }

    return;
  }

  if (category === "single" && isSingleKickplate(selectedKickplate)) {
    const kickPlateLeft = scene
      .getTransformNodesById("left")
      ?.find((node) => node.parent.parent.isVisible);

    if (leftDoor && kickPlateLeft) {
      kickPlateLeft.position = leftDoor.position;
    }
  }
};

const isSingleKickplate = (selectedKickplate) => {
  return selectedKickplate.some((kickplatePart) => {
    return kickplatePart.name.toLowerCase().includes("single");
  });
};

const isDoubledKickplate = (selectedKickplate) => {
  return selectedKickplate.some(
    (kickplatePart) =>
      kickplatePart.name.toLowerCase().includes("dubble") ||
      kickplatePart.name.toLowerCase().includes("double")
  );
};
