/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from "react";
import {
  Vector3,
  SceneLoader,
  Database,
  SceneOptimizerOptions,
  SceneOptimizer,
  SceneLoaderFlags,
  MeshBuilder,
  Engine,
  AssetsManager,
  ArcRotateCamera,
  Vector2,
  Color3,
  Scene,
  CubeTexture,
  Mesh,
  TransformNode,
  Nullable,
  MeshAssetTask
} from "@babylonjs/core";
import SceneComponent from "./SceneComponent";
import { GLTFFileLoader } from "@babylonjs/loaders";
import { GLTF2Export } from "@babylonjs/serializers/glTF";
import { BabylonContext } from "../../common/context/BabylonProvider";
import { Typography } from "../typography";
import {
  handleMeshes,
  hideMeshes,
  insideMeshes,
  outsideMeshes,
  windowsAccessoriesNodes,
} from "./HideMeshes";
import {
  AccordionEnum,
  MeshNames,
  ComponentNames,
  YaleDoorman,
  AccessoriesEnums,
  PositionNodes,
  WindowCategories,
  ModelVariants,
  WFF_FRAME_MISC,
  ProductTypes,
  WindowModels,
  productTypes,
  Hinge
} from "../../services/enums";
import { useParams, useNavigate } from "react-router";
import { useSearchParams, useLocation } from "react-router-dom";
import {
  applyDimensions,
  extractDimensions,
  saveInitialPosition,
} from "./services/DimensionHandlers";
import { setKickPlate } from "./services/KickplateHandler";
import {
  animateCameraOffset,
  firstAnimation,
  moveActiveCamera,
  secondAnimation,
} from "./services/Animation";
import { handleProfiles } from "./services/ProfilesHandler";
import {
  applyAirDividers,
  saveTaskNameIntoWindowsMeshes,
} from "./services/AirDividersHandlers";
import useCheckMobileScreen from "src/hooks/CheckMobileScreen";
import { handleSprojsPositions, saveWindowSprojsData } from "./services/DividersHandles";
import Loading from "../loading/Loading";
import {
  CAMERA_STATES,
  getCameraValues,
  VALUE_TYPES,
} from "./constants/cameraPositions";
import {
  handleBeslagPosition,
  saveVariantDataIntoBeslagMeshes,
} from "./services/BeslagHandlers";
import { handleHingesPositions, saveWindowHingesData } from "./services/HingesHandlers";
import { applyDividers } from "./services/ApplyDividers";
SceneLoader.RegisterPlugin(new GLTFFileLoader());

const BabylonComponent = (props) => {
  const { dataContainer, selectionAccordion } = props;
  const { dispatch, state } = useContext<any>(BabylonContext);
  const [loadingState, setLoadingState] = useState(true);
  const inMeshes: Array<Mesh> = [];
  const outMeshes: Array<Mesh> = [];
  const { category, productType, singleID } = useParams();
  const [texture, setTexture] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const location = useLocation();
  const getProductType = () => location.pathname.split("/")?.[1];


  const selected = dataContainer
    ? dataContainer.getSelectedVariations().map((variation) => variation.id)
    : [];

  let selectedHandle: Mesh[] = [],
    selectedLock: Mesh[] = [],
    selectedCoverplate: Mesh[] = [],
    handleMaterials: Mesh[] = [],
    coverplateMaterials: Mesh[] = [],
    selectedKickplate: Mesh[] = [],
    selectedYale: Mesh[] = [];

  const setAccessoriesParents = () => {
    setKickPlate(state?.scene, selectedKickplate, category);

    let handleNull: TransformNode | null = null;
    let lockNull: TransformNode | null = null;

    for(let i = 0; i < state.scene?.transformNodes?.length; i++) {
      const transformNode = state.scene?.transformNodes[i] as TransformNode;

      if (transformNode.name === AccessoriesEnums.Handle_Null ) {
        const transformNodeRootMesh = transformNode.parent?.parent?.parent?.parent as Mesh;
        if(transformNodeRootMesh && transformNodeRootMesh?.isVisible) {
          handleNull = transformNode;
        }
      }

      if (transformNode.name === AccessoriesEnums.Lock_Null ) {
        const transformNodeRootMesh = transformNode.parent?.parent?.parent?.parent as Mesh;
        if(transformNodeRootMesh && transformNodeRootMesh?.isVisible) {
          lockNull = transformNode;
        }
      }
        
    }

    const coverPlateMeshName = "4356";

    if (selectedHandle.length) {
      if (handleNull) {
        selectedHandle.forEach((handlePart) => {
          if(handlePart?.name.includes(coverPlateMeshName)) {
            return;
          }
          const handlePartParent = handlePart.parent as TransformNode;
          if(handlePartParent) {
            handlePartParent.setParent(handleNull);
            handlePartParent.position = new Vector3(0, 0, 0);
          }
          if(handlePart.name?.includes("Base")) {
            handlePart.material = handleMaterials[0].material;
          }
          if(handlePart.name?.includes("Trim")) {
            const trimMaterial = handleMaterials.find((material) => material.name?.includes("Trim"));

            if(trimMaterial) {
              handlePart.material = trimMaterial.material;
            }
          }
        });
      }
    }

    if (selectedYale.length) {
      if (selectedYale[0] && handleNull) {
        selectedYale[0].setParent(handleNull);
        selectedYale[0].position = new Vector3(0, 0, 0);
      }
    }

    let currentLock: Nullable<Mesh> = null;

    if (selectedLock.length) {
      selectedLock.forEach((lockPart) => {
        const lockPartParent = lockPart?.parent as TransformNode;
        if(lockPartParent) {
          lockPartParent.setParent(lockNull);
          lockPartParent.position = new Vector3(0, 0, 0);
        }
        if (lockPart.id.includes(AccessoriesEnums[3212])) {
          currentLock = lockPart;
        }
      });
    }

    if (selectedCoverplate.length && handleNull) {
      selectedCoverplate.forEach((coverplatePart) => {
        if(coverplatePart?.name.includes(coverPlateMeshName)) {
          coverplatePart.setParent(handleNull);
          coverplatePart.position = new Vector3(0, 0, 0);
        }
        if (coverplateMaterials[0]) {
          coverplatePart.material = coverplateMaterials[0].material;
        }
        if (coverplatePart.id.includes(AccessoriesEnums.Back) && currentLock) {
          coverplatePart.isVisible = false;
        } 
      });
    }

    const boneLeft = state.scene
      .getTransformNodesById("bone_frame_top_left")
      .find((node) => node.parent.name.includes("skeleton_frame") && node.parent.parent.isVisible);

    const boneRight = state.scene
      .getTransformNodesById("bone_frame_top_right")
      .find((node) => node.parent.name.includes("skeleton_frame") && node.parent.parent.isVisible);

    const ventilNull = state.scene
      .getTransformNodesById("ventilation_null")
      .find((node) => node.parent.parent.parent.isVisible);

    state.scene.meshes.forEach((m) => {
      if (m.name.toLowerCase().includes("ventil") && m.isVisible && ventilNull) {
        m.parent.setParent(ventilNull);
        m.parent.position = new Vector3(0, 0 ,0);
        m.parent.rotation = ventilNull.rotation;
      }
    });

    if(ventilNull && boneRight) {
      ventilNull.parent.position.y = boneRight.position.y;
      ventilNull.parent.position.x = ( boneLeft.position.x + boneRight.position.x ) / 2;
    }
  };

  const handleAccessoriesWithMaterials = (task, componentName, shouldShow) => {
    if (!shouldShow) {
      return;
    }

    switch (componentName) {
    case ComponentNames.Kickplate:
      selectedKickplate = [];
      break;
    case ComponentNames.Handle:
      selectedHandle = [];
      selectedYale = [];
      break;
    case ComponentNames.Lock:
      selectedLock = [];
      break;
    case ComponentNames.Cover_Plate:
      selectedCoverplate = [];
      break;
    case ComponentNames.Cover_Plate_Types:
      coverplateMaterials = task.loadedMeshes?.filter(
        (mesh) => mesh.id !== "__root__"
      );
      break;
    case ComponentNames.Handle_Types:
      handleMaterials = task.loadedMeshes?.filter(
        (mesh) => mesh.id !== "__root__"
      );
      break;
    }
  };

  const applyColorMaterials = () => {

    const outsideColorVariation = dataContainer?.findSelectedVariantByComponentName(AccordionEnum.Color);
    const insideColorVariation = dataContainer?.findSelectedVariantByComponentName(AccordionEnum.Color_Inside);

    outMeshes.forEach(mesh => {
      if(!mesh.material) {
        return;
      }

      if(!outsideColorVariation) {
        mesh.material = mesh.metadata?.colorMaterial;
      }

      const outsideColorMaterialMesh = outsideColorVariation?.mesh?.loadedMeshes?.[1];

      if(outsideColorMaterialMesh) {
        mesh.material = outsideColorMaterialMesh.material;
      }
    });

    inMeshes.forEach(mesh => {
      if(!mesh.material) {
        return;
      }

      if(!insideColorVariation) {
        
        if(!outsideColorVariation) {
          mesh.material = mesh.metadata?.colorMaterial;
        }

        const outsideColorMaterialMesh = outsideColorVariation?.mesh?.loadedMeshes?.[1];

        if(outsideColorMaterialMesh) {
          mesh.material = outsideColorMaterialMesh.material;
        }
      }

      const insideColorMaterialMesh = insideColorVariation?.mesh?.loadedMeshes?.[1];

      if(insideColorMaterialMesh) {
        mesh.material = insideColorMaterialMesh.material;
      }
      
    });
  };

  const saveInsideOutsideMeshes = (task: MeshAssetTask) => {
    if(!task.loadedMeshes?.length) {
      return;
    }

    for(let i = 0; i < task.loadedMeshes.length; i++) {
      const mesh = task.loadedMeshes[i] as Mesh;
      if(mesh.material) {
        mesh.metadata.colorMaterial = mesh.material;
        if(outsideMeshes.some((outsideMesh) => mesh.name.includes(outsideMesh) || mesh.name === WFF_FRAME_MISC)) {
          outMeshes.push(mesh);
        }

        if(insideMeshes.some((insideMesh) => mesh.name.includes(insideMesh))) {
          inMeshes.push(mesh);
        }
      }
    }
  };

  const toggleMeshes = (task) => {
    const data = task.name.split("_");
    const variationId = parseInt(data[0], 10);
    const componentName =
      dataContainer?.findComponentByVariationId(variationId)?.name;
    const shouldShow = dataContainer.isVariationSelected(variationId);
    handleAccessoriesWithMaterials(task, componentName, shouldShow);

    if (componentName === AccordionEnum.Beslag) {
      handleBeslagPosition(state.scene, dataContainer);
    }

    if (componentName === AccordionEnum.Hinge && getProductType() === "windows") {
      handleHingesPositions(state.scene, dataContainer);
    }

    if (componentName === AccordionEnum.Sprojs) {
      handleSprojsPositions(state.scene, dataContainer);
    }

    const skipVariationCheck = windowsAccessoriesNodes.some(
      (substring) =>
        task.name.includes(substring) || componentName === AccordionEnum.Beslag ||
        componentName === AccordionEnum.Hinge
    );

    task.loadedMeshes?.forEach((m) => {
      m.computeBonesUsingShaders = false;
      m.alwaysSelectAsActiveMesh = true;

      if (componentName === ComponentNames.Cover_Plate && shouldShow) {
        selectedCoverplate.push(m);
      }

      if (skipVariationCheck) return;
      m.isVisible =
        shouldShow &&
        !["Types", "Color"].some((component) =>
          componentName.includes(component)
        );

      if (
        sessionStorage.getItem("hangingVariant")?.toUpperCase() === Hinge.leftHinge?.toUpperCase() &&
        productType === ProductTypes.doors.key
      ) {
        if (m.name === "__root__" && m.scaling.x === 1) {
          m.scaling.x = -1;
        }
        if (
          handleMeshes.includes(m.name) ||
          (m.name.includes(MeshNames.ASSA) && m.isVisible)
        ) {
          m.scaling.x = 1;
        }
      }
      if (sessionStorage.getItem("hangingVariant")?.toUpperCase() === Hinge.rightHinge?.toUpperCase()) {
        if (m.name === "__root__") {
          m.scaling.x = 1;
        }
      }

      if (hideMeshes.includes(m.name)) {
        m.visibility = false;
      }

      if (!shouldShow) return;

      if (componentName === ComponentNames.Kickplate) {
        selectedKickplate.push(m);
      }


      if (handleMeshes.includes(m.name?.toUpperCase()) || m.name?.toUpperCase()?.includes(MeshNames.ASSA)) {
        selectedHandle.push(m);
      }

      if (m.name.includes(MeshNames.Door_Accessory)) {
        selectedLock.push(m);
      }
    });

    if (
      getProductType() === "windows" &&
      componentName === AccordionEnum.Model &&
      shouldShow
    ) {
      handleProfiles(task.loadedMeshes, dataContainer);
    }

    if (shouldShow && task.name.includes(YaleDoorman)) {
      selectedYale.push(task.loadedMeshes?.[0]);
    }

    state.scene.unfreezeActiveMeshes();
  };

  const changeCameraofMesh = (camera, scene) => {
    if (!category || !productType) {
      return;
    }

    const frameDimensions: { width: number; height: number } =
      dataContainer?.getConfigurationMetadata(
        "frameDimensions",
        productType,
        dataContainer?.mCurrentConfigurationIndex[productType]
      );

    const toggleAnimation = (
      radiusCameraState: string,
      offsetCameraState: string,
      alphaValue = 1,
    ) => {
      moveActiveCamera(
        scene,
        camera,
        getCameraValues(
          category,
          radiusCameraState,
          VALUE_TYPES.RADIUS,
          isMobile,
          frameDimensions,
          alphaValue
        )
      );
      animateCameraOffset(
        camera,
        getCameraValues(
          category,
          offsetCameraState,
          VALUE_TYPES.OFFSET,
          isMobile,
          frameDimensions,
          alphaValue,
        )
      );
    };


    const zeroPositionWidth = 2000;

    if(frameDimensions?.width && productType === "windows") {
      const cameraXTarget = -((zeroPositionWidth - frameDimensions?.width) / zeroPositionWidth);

      if(category === "door-window-double") {
        state.camera?.setTarget(new Vector3(cameraXTarget + 0.5, 0, 0));
      } else {
        state.camera?.setTarget(new Vector3(cameraXTarget, 0, 0));
      }

    }

    if (!selectionAccordion) {
      camera.wheelPrecision = 100;
      camera.minZ = 0.5;
      camera.lowerRadiusLimit = 1;
      camera.upperRadiusLimit = 20;
      camera.upperBetaLimit = Math.PI / 2;
      camera.lowerBetaLimit = Math.PI / 2;
     
      toggleAnimation(CAMERA_STATES.DEFAULT, CAMERA_STATES.DEFAULT);
      return;
    }

    if (selectionAccordion === AccordionEnum.Size || selectionAccordion === AccordionEnum.Luft_Types) {
      toggleAnimation(CAMERA_STATES.MENU_OPENED, CAMERA_STATES.MENU_OPENED);
      return;
    }

    if (selectionAccordion === AccordionEnum.Beslag) {
      toggleAnimation(CAMERA_STATES.BESLAG_ANIMATION, CAMERA_STATES.BESLAG_ANIMATION);
      return;
    }

    if (secondAnimation.includes(selectionAccordion)) {
      const currentModelMesh = scene
        .getTransformNodesById("Top_Left")
        .find((node) => node?.parent?.parent?.isVisible)?.parent.parent;
      if (!currentModelMesh) {
        return;
      }

      if (currentModelMesh.scaling.x === 1) {
        toggleAnimation(
          CAMERA_STATES.SECOND_ANIMATION,
          CAMERA_STATES.SECOND_ANIMATION
        );
        return;
      }
      toggleAnimation(
        CAMERA_STATES.SECOND_ANIMATION_RIGHT,
        CAMERA_STATES.SECOND_ANIMATION_RIGHT
      );
      return;
    }

    if (
      WindowCategories.includes(ModelVariants[category]) &&
      firstAnimation.includes(selectionAccordion)
    ) {
      const currentModel = dataContainer?.findVariationBy(Number(singleID));

      if(selectionAccordion === AccordionEnum.Ventilation) {
        toggleAnimation(
          CAMERA_STATES.FIRST_ANIMATION,
          CAMERA_STATES.FIRST_ANIMATION,
        );
        return;
      }

      if(selectionAccordion === AccordionEnum.Profile) {
        if(currentModel?.original_key.startsWith(WindowModels.WFIK) || currentModel?.original_key.startsWith(WindowModels.WFDIK)) {
          toggleAnimation(
            CAMERA_STATES.FIRST_ANIMATION,
            CAMERA_STATES.FIRST_ANIMATION,
          );
          return;
        }
        toggleAnimation(
          CAMERA_STATES.FIRST_ANIMATION,
          CAMERA_STATES.FIRST_ANIMATION,
          Math.PI,
        );
        return;
      }

      if(currentModel?.original_key.startsWith(WindowModels.WFIK) || currentModel?.original_key.startsWith(WindowModels.WFDIK)) {
        toggleAnimation(
          CAMERA_STATES.FIRST_ANIMATION,
          CAMERA_STATES.FIRST_ANIMATION,
          Math.PI
        );
        return;
      }
      toggleAnimation(
        CAMERA_STATES.FIRST_ANIMATION,
        CAMERA_STATES.FIRST_ANIMATION,
      );
      return;
    }

    toggleAnimation(CAMERA_STATES.DEFAULT, CAMERA_STATES.MENU_OPENED);
  };

  const loadAssetManager = () => {
    const assetsManager = new AssetsManager(state.scene.length && state.scene);
    assetsManager.useDefaultLoadingScreen = false;
    const allSprojs = [] as any;

    const loadAssetsForTasks = (item, meshTask) => {
      dispatch({ type: "SET_TASKS", payload: (item.mesh = meshTask) });
    };

    for (let idx = 0; idx < state.tasks.length; idx += 1) {
      const component = dataContainer?.findComponentById(
        state.tasks[idx].component_id
      );

      const variationName =
        component.name === AccordionEnum.Beslag
          ? state.tasks[idx].original_key
          : state.tasks[idx].key;
          
      if(component.name === AccordionEnum.Hinge && productType === "windows") {
        for(let i = 0; i < 6; i++) {
          assetsManager.addMeshTask(
            `${state.tasks[idx].id} - ${state.tasks[idx].original_key} - ${component.name}_top${i}`,
            "",
            "",
            state.tasks[idx].assets.length && state.tasks[idx].assets[0].asset_url
          );

          assetsManager.addMeshTask(
            `${state.tasks[idx].id} - ${state.tasks[idx].original_key} - ${component.name}_bottom${i}`,
            "",
            "",
            state.tasks[idx].assets.length && state.tasks[idx].assets[0].asset_url
          );
        }

      }

      if(component.name === AccordionEnum.Sprojs && productType === "windows"){
        for (let id = 1; id < 6; id++) {
          const meshTask = assetsManager.addMeshTask(
            `${state.tasks[idx].id} - ${variationName}-${id} - ${component.name} - ${component.id}`,
            "",
            "",
            state.tasks[idx].assets.length && state.tasks[idx].assets[0].asset_url
          );
          allSprojs.push(meshTask);

          meshTask.onSuccess = function (task) {
            saveInsideOutsideMeshes(task);
            saveWindowSprojsData(allSprojs);
            toggleMeshes(task);
          };
        }
      }

      const meshTask = assetsManager.addMeshTask(
        `${state.tasks[idx].id} - ${variationName} - ${component.name}`,
        "",
        "",
        state.tasks[idx].assets.length && state.tasks[idx].assets[0].asset_url
      );

      loadAssetsForTasks(state.tasks[idx], meshTask);
    }
    setAccessoriesParents();
    assetsManager.load();

    assetsManager.onFinish = (tasks) => {
      saveTaskNameIntoWindowsMeshes(tasks);
      saveVariantDataIntoBeslagMeshes(tasks);
      saveWindowHingesData(tasks);
      saveWindowSprojsData(tasks);

      const currentModel = dataContainer?.findVariationBy(
        dataContainer?.getCurrentModel()
      );
      saveInitialPosition(state.scene);
      state.tasks.forEach((t) => {
        saveInsideOutsideMeshes(t.mesh);
        toggleMeshes(t.mesh);
      });
      const sprojsVariation =
        dataContainer?.findSelectedVariantByComponentName("Sprojs");
        
      if(sprojsVariation){
        handleSprojsPositions(state.scene, dataContainer);
      }
      const dimensionVariation =
        dataContainer?.findSelectedVariantByComponentName("Size");
      
      if (dimensionVariation) {
        const dimensions = extractDimensions(
          dimensionVariation,
          getProductType()
        );
        applyDimensions(state, dimensions, dataContainer, true);
      }
      const airDividerVariation =
        dataContainer?.findSelectedVariantByComponentName(
          AccordionEnum.Luft_Types
        );

      applyAirDividers(
        airDividerVariation ? airDividerVariation?.original_key : "1a",
        state.scene,
        currentModel,
        dataContainer,
        true
      );
      setAccessoriesParents();
      applyColorMaterials();
      setLoadingState(false);
      
      
      setTimeout(() => {
        exportToGLB();
      }, 100);
      
    };
  };

  const exportToGLB = () => {
    
    if (texture) {
      const options = {
        shouldExportNode: function (node) {

          if (node.skeleton) {
            node.skeleton.dispose();
          }

          if (node.name.includes("camera")) {
            return false;
          }

          if (hideMeshes.includes(node.id)) {
            return true;
          }

          if (node.getClassName() === "Mesh") {
            return node.isVisible && node !== texture;
          }

          return true;
        },
      };

      const goToAR = searchParams.get("ar") === "ok";

      if (goToAR) {
        setLoadingState(true);
      }
      GLTF2Export.GLBAsync(state.scene, "model", options).then((glb) => {
        const blob = glb.glTFFiles["model.glb"];
        setLoadingState(false);
        if (blob instanceof Blob) {
          localStorage.setItem("ar", URL.createObjectURL(blob));
          if (goToAR && dataContainer) {
            navigate("/ar", {
              state: { from: dataContainer?.generateShareableLink(location.pathname, true) },
            });
          }
        }
      });
    }
  };

  const onSceneReady = (scene) => {

    const isDoorType = productType === "doors" ||
      category === productTypes.doubleDoorWindows;

    const initialTargetPosition = {
      x: isDoorType ? 0 : -0.75,
      y: isDoorType ? 0 : -0.25
    };

    const camera = new ArcRotateCamera(
      "camera",
      0,
      0,
      10,
      new Vector3(initialTargetPosition.x, initialTargetPosition.y, 0),
      scene
    );
    const canvas = scene.getEngine().getRenderingCanvas();
    const engine = new Engine(canvas, true);

    Database.IDBStorageEnabled = false;
    engine.enableOfflineSupport = false;
    engine.disableManifestCheck = true;
    SceneLoaderFlags.ShowLoadingScreen = false;
    const options = new SceneOptimizerOptions();
    new SceneOptimizer(scene, options);
    dispatch({ type: "SET_SCENE", payload: scene });
    dispatch({ type: "SET_ENGINE", payload: engine });
    dispatch({ type: "SET_CAMERA", payload: camera });
    camera.attachControl(canvas, true);
    camera.alpha = 1.57;
    camera.targetScreenOffset = new Vector2(0, -1.05);

    scene.fogMode = Scene.FOGMODE_LINEAR;
    scene.fogColor = Color3.FromHexString("#f5f7f5");
    scene.fogDensity = 0.01;
    const hdrTexture = new CubeTexture(`${process.env.REACT_APP_PUBLIC_URL || "/" }textures/studio.env`, scene);
    setTexture(scene.createDefaultSkybox(hdrTexture, true, 10000));

    const box_horizontal = MeshBuilder.CreateBox(
      "boxH",
      { height: 0.001, width: 0.001, depth: 0.001 },
      scene
    );
    const box_vertical = MeshBuilder.CreateBox(
      "boxV",
      { height: 0.001, width: 0.001, depth: 0.001 },
      scene
    );

    const calcTransformNodePositon = (nodePosition) => {
      return Vector3.Project(
        Vector3.Zero(),
        nodePosition.getWorldMatrix(),
        scene.getTransformMatrix(),
        camera.viewport.toGlobal(
          engine.getRenderWidth(true),
          engine.getRenderHeight(true)
        )
      );
    };

    const calculateSliderPosition = (
      id: string,
      task: string,
      slider: HTMLElement[],
      positions: { x: number; y: number }
    ) => {
      const node = scene.getTransformNodesById(id).find((node) => {
        return (
          node.parent.parent.isVisible && node?.metadata?.task?.includes(task)
        );
      });

      if (node) {
        const nodePosition = calcTransformNodePositon(node);
        slider[0].style.visibility = "visible";
        slider[0].style.transform = `translate3d(${
          nodePosition.x - positions.x
        }px, ${nodePosition.y - positions.y}px, 0px)`;
        return;
      }
      slider[0].style.visibility = "hidden";
    };

    engine.runRenderLoop(function () {
      engine.resize();

      box_horizontal.position = new Vector3(0, 2.35, 0);
      box_vertical.position = new Vector3(-0.75, 1, 0);

      const sliderH = Array.from(
        document.getElementsByClassName(
          "sliderH"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const sliderV = Array.from(
        document.getElementsByClassName(
          "sliderV"
        ) as HTMLCollectionOf<HTMLElement>
      );
      const sliderVText = document.querySelectorAll<HTMLElement>(
        ".rc-slider-vertical .rc-slider-mark-text"
      );
      const addWindowButton = Array.from(
        document.getElementsByClassName(
          "add-window"
        ) as HTMLCollectionOf<HTMLElement>
      );

      const firstVerticalSlider = Array.from(
        document.getElementsByClassName(
          "firstVertical"
        ) as HTMLCollectionOf<HTMLElement>
      );

      const secondVerticalSlider = Array.from(
        document.getElementsByClassName(
          "secondVertical"
        ) as HTMLCollectionOf<HTMLElement>
      );

      const horizontalSlider = Array.from(
        document.getElementsByClassName(
          "horizontal"
        ) as HTMLCollectionOf<HTMLElement>
      );

      calculateSliderPosition("bone_mullion_top", "vertical0", firstVerticalSlider, {x: 0, y: 50});
      calculateSliderPosition("bone_mullion_top", "vertical1", secondVerticalSlider, {x: 0, y: 50});
      calculateSliderPosition("bone_transom_left", "horizontal0", horizontalSlider, {x: 60, y: 320});

      const topRight = scene
        .getTransformNodesById(
          PositionNodes[getProductType() || "doors"]?.top_right
        )
        .find((node) => node.parent.parent.isVisible);

      const topLeft = scene
        .getTransformNodesById(
          PositionNodes[getProductType() || "doors"]?.top_left
        )
        .find((node) => node.parent.parent.isVisible);

      const bottomRight = scene
        .getTransformNodesById(
          PositionNodes[getProductType() || "doors"]?.bottom_right
        )
        .find((node) => node.parent.parent.isVisible);

      const skylight = scene
        .getMeshesById("skylight_sealing_rubber")
        .find((node) => node.parent.isVisible);

      const lockNull = scene
        .getTransformNodesById("lock_null")
        .find((node) => node.parent.parent.parent.parent.isVisible);

      if (lockNull && topRight && topLeft) {
        const lockNullPosition = calcTransformNodePositon(lockNull);
        const topRightPosition = calcTransformNodePositon(topRight);
        const topLeftPosition = calcTransformNodePositon(topLeft);

        addWindowButton[0].style.transform = isMobile
          ? `translate3d(${
            (topRightPosition.x + topLeftPosition.x) / 2 - 50
          }px, ${topRightPosition.y - 90}px, 0px)`
          : `translate3d(${
            lockNullPosition.x - (category === "double" ? 430 : 230)
          }px, ${lockNullPosition.y - 30}px, 0px)`;
      }

      if (topRight && bottomRight) {
        const topRightPosition = calcTransformNodePositon(topRight);
        const bottomRightPosition = calcTransformNodePositon(bottomRight);

        sliderH[0].style.transform = `translate3d(${
          topRightPosition.x - 250
        }px, ${
          topRightPosition.y - (typeof skylight === "undefined" ? 50 : 90)
        }px, 0px)`;

        sliderV[0].style.transform = `translate3d(${
          bottomRightPosition.x + 50
        }px, ${
          bottomRightPosition.y -
          sliderV[0].offsetHeight -
          (bottomRightPosition.y -
            topRightPosition.y -
            sliderV[0].offsetHeight) /
            2
        }px, 0px)`;
        if (
          sessionStorage.getItem("hangingVariant") === Hinge.leftHinge &&
          productType === ProductTypes.doors.key
        ) {
          sliderVText.forEach((element) => {
            element.style.left = "-90px";
          });
          sliderV[0].style.transform = `translate3d(${
            bottomRightPosition.x - 50
          }px, ${bottomRightPosition.y - 450}px, 0px)`;

          sliderH[0].style.transform = `translate3d(${
            topRightPosition.x - 0
          }px, ${
            topRightPosition.y - (typeof skylight === "undefined" ? 50 : 115)
          }px, 0px)`;

          if (lockNull) {
            const lockNullPosition = calcTransformNodePositon(lockNull);
            addWindowButton[0].style.transform = `translate3d(${
              lockNullPosition.x - 500
            }px, ${lockNullPosition.y - 30}px, 0px)`;
          }
        }
        if (sessionStorage.getItem("hangingVariant") === Hinge.rightHinge) {
          sliderVText.forEach((element) => {
            element.style.left = "20px";
          });
        }
      }
    });
  };

  window.addEventListener(
    "orientationchange",
    (e) => {
      if (e) {
        setTimeout(() => {
          state.scene.getEngine().resize();
        }, 10);
      }
    },
    false
  );

  window.addEventListener("resize", () => {
    setTimeout(() => {
      state.scene.getEngine().resize();
    }, 10);
  });

  const selectionChanged = (variationId) => {
    if (!state.scene.rootNodes.length) {
      return;
    }
    const variation = dataContainer?.findVariationBy(variationId[0]);
    const component = dataContainer?.findComponentById(variation.component_id);
    let dimensionss: number[] = [];
    const currentModel = dataContainer?.findVariationBy(
      dataContainer?.getCurrentModel()
    );
    if (component.name === AccordionEnum.Size) {
      dimensionss = extractDimensions(variation, getProductType());
    }

    if(variation?.original_key?.includes("with_parapet") || component.name === AccordionEnum.Model){
      dataContainer?.unselectVariationByComponentName("Sprojs");
    }

    if (component.name === AccordionEnum.Luft_Types) {
      applyAirDividers(
        variation?.original_key,
        state.scene,
        currentModel,
        dataContainer
      );
    }

    state.tasks.forEach((t) => {
      toggleMeshes(t.mesh);
    });

    if (dimensionss.length && dataContainer?.getCurrentModel()) {
      applyDimensions(state, dimensionss, dataContainer);
    }
    
    setAccessoriesParents();
    applyColorMaterials();

    if (variationId && (dataContainer?.findSelectedVariantByComponentName(AccordionEnum.Sprojs))) {
      applyDividers(state.scene);
    }

    const sprojsTypeVariation = dataContainer?.findSelectedVariantByComponentName("Sprojs");
    if(!component.name.includes(AccordionEnum.Sprojs) && sprojsTypeVariation){
      applyDividers(state.scene);
    }

    if(isMobile) {
      exportToGLB();
    }

  };

  useEffect(() => {
    if (dataContainer && !state.hookRegistered && selected.length) {
      const allVariations = dataContainer.findVariationsByAssetType("glb");
      const filteredVariations = allVariations.filter(
        (variation) => variation.product_category === productType
      );

      dispatch({ type: "SET_HOOK", payload: true });
      dispatch({
        type: "SET_TASKS",
        payload: filteredVariations,
      });
    }
  }, [dataContainer, state, selected.length]);

  useEffect(() => {
    if (state.tasks.length) {
      loadAssetManager();
      dataContainer.registerSelectionHook(selectionChanged);
    }
  }, [state.tasks, dataContainer]);

  useEffect(() => {
    if (state.camera) {
      setTimeout(() => {
        changeCameraofMesh(state.camera, state.scene);
      }, 300);
    }
  }, [changeCameraofMesh]);

  useEffect(() => {
    if(singleID && dataContainer){
      const toggleVariant = dataContainer?.findVariationBy(Number(singleID));
      if(toggleVariant?.original_key.includes("with_parapet")){
        dataContainer?.unselectVariationByComponentName("Sprojs");
      }
    }
  }, [singleID, dataContainer]);

  return (
    <>
      {loadingState && (
        <div className="absolute w-full h-full z-50 bg-smoke bg-opacity-100 flex justify-center items-center">
          <Typography
            tag="h2"
            className="flex flex-col items-center text-primary text-[18px]"
            parentClassName="flex items-center"
          >
            <Loading productType={getProductType()} />
          </Typography>
        </div>
      )}
      <SceneComponent antialias onSceneReady={onSceneReady} />
    </>
  );
};

export { BabylonComponent };
