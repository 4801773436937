import React, { useContext, useState } from "react";
import { SelectGroup } from "../components/subAccordions";
import { SelectInput, SelectOption } from "../components/input";
import { NumberInput } from "../components/input";
import { Button } from "../components/button/Button";
import { generateUID } from "src/services/Helpers";
import { DimensionsProps } from "./types";
import { AccordionEnum, DimensionsMultiplier } from "src/services/enums";
import { useParams } from "react-router";
import { BabylonContext } from "../common/context/BabylonProvider";

const MAX = 10000;

export const Dimensions = ({
  dimensionsOptions,
  dataContainer,
}: DimensionsProps) => {
  const [dimensions, setDimensions] = useState({ width: "", height: "" });
  const [isResetOpen, setResetOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { dispatch } = useContext<any>(BabylonContext);

  const { productType } = useParams();

  const clearDimensions = (type) => {
    if (type === "all") {
      setDimensions({
        width: "",
        height: "",
      });
      return;
    }
    setDimensions({
      ...dimensions,
      [type]: "",
    });
  };

  const toggleVariation = (e) => {
    if (!productType) {
      return;
    }
    dispatch({
      type: "SET_WIDTH",
      payload: Number(e.value.width) * DimensionsMultiplier[productType],
    });
    dispatch({
      type: "SET_HEIGHT",
      payload: Number(e.value.height) * DimensionsMultiplier[productType],
    });
    dataContainer?.toggleVariationSelection(e.value.variationId);
  };

  const customDimensionsHandler = () => {
    if (!dimensions.width || !dimensions.height || !dataContainer) return;

    dataContainer?.setConfigurationMetadata(
      "customDimensions",
      `${dimensions.width}x${dimensions.height}mm`,
      productType
    );

    dataContainer?.setConfigurationMetadata(
      "frameDimensions",
      { width: dimensions.width, height: dimensions.height },
      productType
    );
    dataContainer.unselectVariationByComponentName(AccordionEnum.Size);
    setResetOpen(true);
  };

  const onResetClickHandler = () => {
    dataContainer?.setConfigurationMetadata(
      "customDimensions",
      null,
      productType
    );
    setResetOpen(false);
    clearDimensions("all");
  };

  const sortedDimensions = (dimensions: SelectOption[]) => {
    return dimensions.sort((first, second) => {
      return Number(first?.value?.height) - Number(second?.value?.height);
    });
  };

  return (
    <div>
      <SelectGroup
        title={dataContainer?.sectionKey("Accordions labels", "size")}
        description={dataContainer?.sectionKey(
          "Accordions labels",
          "size_description"
        )}
      >
        {dimensionsOptions?.map((dimensionOptions) => (
          <SelectInput
            id={generateUID(10)}
            name="dimensions"
            options={sortedDimensions(dimensionOptions)}
            placeholder={dimensionOptions[0].label.split("mm")[0]}
            key={generateUID(5)}
            onChange={(e) => toggleVariation(e)}
          />
        ))}
      </SelectGroup>
      <div className="sm:mt-20 mt-7">
        <h2 className="sm:text-5xl text-4xl font-normal text-black-faded">
          Välj dina egna mått:
        </h2>
        <div className="flex flex-col sm:flex-row sm:gap-6 gap-7 text-4xl sm:mt-6 mt-10">
          <NumberInput
            onChange={(e) => {
              if (Number(e.target.value) < MAX) {
                setDimensions({ ...dimensions, width: e.target.value });
              }
            }}
            value={dimensions.width}
            name="nr"
            label="Bredd (mm)"
            clearButton={{
              isShown: Boolean(dimensions.width),
              onClick: () => clearDimensions("width"),
            }}
          />
          <NumberInput
            onChange={(e) => {
              if (Number(e.target.value) < MAX) {
                setDimensions({ ...dimensions, height: e.target.value });
              }
            }}
            value={dimensions.height}
            name="nr"
            label="Höjd (mm)"
            clearButton={{
              isShown: Boolean(dimensions.height),
              onClick: () => clearDimensions("height"),
            }}
          />
        </div>
      </div>
      <div className="mt-12 flex gap-3 justify-center items-center">
        {isResetOpen ? (
          <span
            onClick={onResetClickHandler}
            className="hover:cursor-pointer hover:opacity-80 text-black-faded"
          >
            {dataContainer?.sectionKey("Translations", "clear_fields")}
          </span>
        ) : (
          <>
            <span className="sm:text-5xl text-4xl text-black-faded">
              Applicera
            </span>
            <Button
              icon="BlackPlus"
              className="hover:opacity-75 active:hover:opacity-90"
              onClick={customDimensionsHandler}
            />
          </>
        )}
      </div>
    </div>
  );
};
