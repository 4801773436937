import React from "react";
import { IconProps } from "../Types";

const Circle = ({ className }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8"
      cy="8"
      r="7"
      stroke="white"
      strokeOpacity="0.6"
      strokeWidth="2"
    />
  </svg>
);

export default Circle;
