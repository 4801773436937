import classNames from "classnames";
import React from "react";
import { generateUID } from "src/services/Helpers";
import { TextInput } from "../input";
import { Typography } from "../typography";
import { UseFormValues } from "./Types";

export const ContactForm = ({
  data,
  errors,
  handleChange,
  handleSubmit,
  dataContainer,
}: UseFormValues) => (
  <div className=" mt-5 border-t border-gray-mid">
    <Typography
      tag="h4"
      className="pt-5 font-medium text-black-faded mb-5"
      parentClassName="h-auto"
    >
      {dataContainer?.sectionKey("Translations", "form_label")}
    </Typography>
    <form onSubmit={handleSubmit}>
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-4 gap-y-2 w-full mb-5">
        <TextInput
          id="first_name"
          value={data.first_name}
          placeholder="Förnamn*"
          onChange={handleChange}
          error={errors?.first_name}
          parentClassName="order-1 sm:order-none"
        />
        <TextInput
          type="email"
          id="email"
          value={data.email}
          placeholder="E-post*"
          onChange={handleChange}
          error={errors?.email}
          parentClassName="order-5 sm:order-none"
        />
        <TextInput
          id="last_name"
          value={data.last_name}
          placeholder="Efternamn*"
          onChange={handleChange}
          error={errors?.last_name}
          parentClassName="order-2 sm:order-none"
        />
        <TextInput
          id="phone_number"
          value={data.phone_number}
          placeholder="Telefonnummer"
          onChange={handleChange}
          error={errors?.phone_number}
          parentClassName="order-7 sm:order-none"
        />
        <TextInput
          id="address"
          value={data.address}
          placeholder="Adress*"
          onChange={handleChange}
          error={errors?.address}
          parentClassName="order-3 sm:order-none"
        />
        <TextInput
          id="co"
          value={data.co}
          placeholder="C/O"
          onChange={handleChange}
          error={errors?.co}
          parentClassName="order-8 sm:order-none"
        />
        <TextInput
          id="zip_code"
          value={data.zip_code}
          placeholder="Postnummer*"
          onChange={handleChange}
          error={errors?.zip_code}
          parentClassName="order-4 sm:order-none"
        />
        <TextInput
          id="country"
          value={data.country}
          placeholder="Ort*"
          onChange={handleChange}
          error={errors?.country}
          parentClassName="col-start-1 col-end-2 order-5 sm:order-none"
        />
      </div>
      <div className="mb-12">
        {Object.keys(errors).map(
          (key) =>
            errors[key] && (
              <p className="text-red text-xs mb-3.5 pl-5" key={generateUID(9)}>
                {errors[key]}
              </p>
            )
        )}
      </div>
      <div className="overflow-hidden">
        <label
          className="sm:text-3xl text-2xl text-black-faded font-medium mb-3"
          htmlFor="message"
        >
          {dataContainer?.sectionKey("Translations", "form_message_label")}
        </label>
        <textarea
          rows={5}
          id="message"
          name="message"
          placeholder="Om du har en särskild önskan skriv detta här."
          className="w-full sm:p-5 p-3 sm:text-3xl text-2xl mt-3 bg-gray-light resize-none outline-0 placeholder:text-black sm:placeholder:text-3xl placeholder:text-2xl block placeholder:font-normal placeholder:opacity-50"
          value={data.message}
          onChange={handleChange}
        />
        <div className="flex justify-between items-center mt-5">
          <input
            type="checkbox"
            name="terms"
            id="terms"
            className={classNames("flex items-center mr-5",
              innerWidth < 1200 && innerWidth > 640 ? "w-[40px] h-[40px]" : "w-[20px] h-[20px]",
            )}
            onChange={handleChange}
          />
          <label htmlFor="terms" className="sm:text-4xl text-3xl text-black">
            {dataContainer?.sectionKey("Translations", "policy_text")}
            {
              <a
                href={dataContainer?.sectionKey("Translations", "policy_link")}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-2 text-primary"
              >
                policy
              </a>
            }
          </label>
        </div>
      </div>
    </form>
  </div>
);
