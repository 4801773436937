import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Button } from "../button/Button";
import { Logo } from "../logo/Logo";
import { Typography } from "../typography";
import { ProductModalProps } from "./Types";
import { Image } from "../image";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import "./style.css";
import { generateUID } from "src/services/Helpers";

export const ProductModal = ({
  isOpen,
  text,
  className,
  close,
  images,
}: ProductModalProps) => {
  const [slide, setSlide] = useState(0);
  const windows = images?.map((img) => (
    <Image
      className="max-h-screen object-cover"
      key={generateUID(9)}
      src={img.src}
      title={img.title}
    />
  ));
  useEffect(() => {
    const slideshowTimer = setInterval(() => {
      setSlide(slide === 1 ? 0 : 1);
    }, 3500);
    return () => clearInterval(slideshowTimer);
  }, [slide]);

  return (
    <>
      {isOpen && (
        <div
          className={classnames(
            { "opacity-0 invisible": !isOpen },
            { "opacity-100 visible": isOpen },

            "absolute w-full h-full left-0 bottom-0 z-50 bg-white overflow-auto transition-all",
            className
          )}
        >
          <div className="flex sm:flex-row flex-col">
            <div className="sm:pl-[3.75rem] h-full sm:pt-[3.75rem] pt-[7.6rem] sm:w-7/12 sm:pr-24 px-10"> 
              <Typography
                tag="p"
                parentClassName="h-auto"
                className="text-[0.8rem] mb-16 text-black-faded"
              >
                {text}
              </Typography>
            </div>
            <div className="sm:w-5/12 w-full h-full">
              <SwitchTransition mode="out-in">
                <CSSTransition
                  key={slide}
                  classNames="switch"
                  addEndListener={(node, done) =>
                    node.addEventListener("transitionend", done, false)
                  }
                >
                  <div className="relative">{windows && windows[slide]}</div>
                </CSSTransition>
              </SwitchTransition>
              <Logo
                className="absolute sm:top-auto sm:left-10 sm:bottom-5 flex top-6 left-1/2 sm:translate-x-0 -translate-x-1/2"
                to="/"
              />
            </div>
          </div>
          <div className="Modal__close absolute right-7 top-7">
            <Button icon={"Close"} onClick={close} />
          </div>
        </div>
      )}
    </>
  );
};
