import React from "react";
import classnames from "classnames";
import { ButtonProps } from "./Types";
import { Link } from "react-router-dom";
import { Icon } from "../icon/Icon";

export const Button = ({
  to,
  icon,
  label,
  className,
  disabled,
  primary,
  secondary,
  active,
  onClick,
  iconClassName,
  textClassName,
  additionalIconProps,
}: ButtonProps) => {
  const Component = to ? Link : "button";

  return (
    <Component
      className={classnames(
        "Button",
        {
          "w-6 h-6 flex items-center justify-center rounded-full text-xs bg-primary text-white":
            primary,
        },
        { "inline-flex items-center justify-center": secondary },
        className,
        { Button_active: active },
        { "pointer-events-none": disabled && to }
      )}
      disabled={disabled}
      to={to || "/"}
      onClick={onClick}
    >
      {icon && (
        <div
          className={classnames(iconClassName, { "rounded-full": secondary })}
        >
          <Icon name={icon} {...additionalIconProps} />
        </div>
      )}
      {label && (
        <span
          className={classnames(
            "font-normal text-xs leading-4",
            textClassName,
            { "underline underline-offset-8": active && !primary && !icon }
          )}
        >
          {label}
        </span>
      )}
    </Component>
  );
};
