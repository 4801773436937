import React, { useEffect, useState } from "react";
import { Typography } from "../typography/Typography";
import classNames from "classnames";
import { AccordionComponentProps } from "./type";
import { Icon } from "../icon/Icon";
import { AccordionEnum } from "src/services/enums";
import { Button } from "../button/Button";
import useCheckMobileScreen from "../../hooks/CheckMobileScreen";

function Accordion({
  name,
  icon,
  subname,
  quantity,
  className,
  children,
  isOpen = false,
  isHidden = false,
  onChange,
  id,
}: AccordionComponentProps): JSX.Element {
  const [accordionOpen, setAccordionOpen] = useState(isOpen);
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    setAccordionOpen(isOpen);
  }, [isOpen]);

  const clName = classNames(
    { "sm:border-b-0 sm:pb-0": accordionOpen },
    {
      "sm:border-b sm:border-0 border-smoke border-[1px] py-4 pl-4":
        !accordionOpen,
    },
    { "sm:pt-7 sm:pb-5 sm:pl-2 sm:border-black": !className },
    { hidden: isHidden },
    className
  );

  const subClName = classNames(
    { "opacity-100 visible sm:py-12 py-10 h-auto": accordionOpen },
    { "opacity-0 invisible h-0 duration-100": !accordionOpen }
  );

  const handleAccordion = () => {
    setAccordionOpen(!accordionOpen);
    onChange(!accordionOpen);
  };

  return (
    <div
      className={classNames(clName, "flex flex-col w-full items-center")}
      id={id}
    >
      <div
        className="flex flex-row items-center h-full w-full cursor-pointer"
        onClick={handleAccordion}
      >
        <div
          className={classNames("flex items-center justify-center w-10 h-10", {
            "sm:flex hidden": accordionOpen && !quantity,
          })}
        >
          <Icon name={icon} />
        </div>
        <Button
          secondary
          textClassName="ml-3.5"
          className={classNames(
            { hidden: !accordionOpen },
            { hidden: quantity },
            "sm:hidden flex mt-7 pl-7"
          )}
          icon="Prev"
          label="Tillbaka"
          onClick={() => setAccordionOpen(!accordionOpen)}
        />
        {subname ? (
          <div className="flex flex-col w-full ml-5">
            <Typography tag="h5" className="text-primary text-xs w-full">
              {name}
            </Typography>
            <Typography tag="p" className="text-black text-xs w-full mt-2">
              {subname}
            </Typography>
          </div>
        ) : (
          <div className="w-full">
            <Typography
              tag="h4"
              className={classNames(
                "text-black-faded sm:text-lg text-4xl sm:ml-8 ml-3.5 w-full font-normal",
                { "sm:flex hidden": accordionOpen }
              )}
            >
              {name}
            </Typography>
          </div>
        )}
        {quantity && (
          <div className="bg-black flex-none rounded-full w-7 h-7 mr-14">
            <Typography
              tag="p"
              className="text-white text-xs w-full h-full flex justify-center items-center"
            >
              {quantity}
            </Typography>
          </div>
        )}
        <div
          className={classNames("flex w-6 h-9 items-center ml-4", {
            "sm:flex hidden": isMobile && !quantity,
          })}
        >
          <Icon name={accordionOpen ? "TopArrow" : "BottomArrow"} />
        </div>
      </div>
      <div
        className={classNames(
          subClName,
          "pb-0 transition-all duration-300 w-full px-7 sm:px-0"
        )}
      >
        {(accordionOpen || icon === AccordionEnum.Size) && children}
      </div>
    </div>
  );
}

export { Accordion };
