import React from "react";
import { IconProps } from "../Types";

const Window = ({ className, fill }: IconProps) => {
  return (
    <svg
      className={className}
      width="22"
      height="36"
      viewBox="0 0 22 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 1H1V35H21V1ZM1 0C0.447715 0 0 0.447716 0 1V35C0 35.5523 0.447716 36 1 36H21C21.5523 36 22 35.5523 22 35V1C22 0.447715 21.5523 0 21 0H1Z"
        fill={fill || "black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 4H4V32H18V4ZM4 3C3.44772 3 3 3.44772 3 4V32C3 32.5523 3.44772 33 4 33H18C18.5523 33 19 32.5523 19 32V4C19 3.44772 18.5523 3 18 3H4Z"
        fill={fill || "black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 13L18 13L18 14L4 14L4 13Z"
        fill={fill || "black"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 22L18 22L18 23L4 23L4 22Z"
        fill={fill || "black"}
      />
    </svg>
  );
};

export default Window;
