import React from "react";
import { IconProps } from "../Types";

const Lock = ({ className }: IconProps) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect x="0.5" y="0.5" width="25" height="25" rx="0.5" stroke="black" />
    <rect x="2.42578" y="2.29297" width="9.59259" height="21.4138" rx="0.5" stroke="black" />
    <rect x="13.9814" y="2.29297" width="9.59259" height="21.4138" rx="0.5" stroke="black" />
    <line x1="2.88867" y1="12.9482" x2="11.5553" y2="12.9482" stroke="black" />
    <line x1="14.4443" y1="12.9482" x2="23.111" y2="12.9482" stroke="black" />
  </svg>
);

export default Lock;
