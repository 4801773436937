import React from "react";
import { IconProps } from "../Types";

const WhitePlus = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.5" fill="white" stroke="#333333" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5H9V9L5 9V11H9V15H11V11H15V9L11 9V5Z"
        fill="#333333"
      />
    </svg>
  );
};

export default WhitePlus;
