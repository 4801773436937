import React, { useContext, useEffect, useState } from "react";
import { AccordionEnum, DimensionsMultiplier } from "src/services/enums";
import { SliderRange } from "../slider/Slider";
import { DoorSlidersProps } from "./types";
import { useParams } from "react-router";
import { BabylonContext } from "../../common/context/BabylonProvider";
import { DefaultDimensions } from "../subAccordions/airDividers/AirDividers";

export const DoorSliders = ({
  sliderValues,
  dataContainer,
  selectedModel,
}: DoorSlidersProps) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const { productType, category } = useParams();
  const dimensionsVariation = dataContainer?.findSelectedVariantByComponentName(
    AccordionEnum.Size
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { state } = useContext<any>(BabylonContext);

  const getInitialWidth = () => {
    const luftTypeVariation = dataContainer?.findSelectedVariantByComponentName(
      AccordionEnum.Luft_Types
    );
    const luftType = luftTypeVariation?.original_key;

    if (category === "side" || category === "fixed") {
      return DefaultDimensions[luftType || "1a"][0];
    }
    return sliderValues.horizontal[0];
  };

  const getInitialHeight = () => {
    const luftTypeVariation = dataContainer?.findSelectedVariantByComponentName(
      AccordionEnum.Luft_Types
    );
    const luftType = luftTypeVariation?.original_key;

    if (category === "side" || category === "fixed") {
      return DefaultDimensions[luftType || "1a"][1];
    }
    return sliderValues.vertical[0];
  };

  const toggleDimensionVariation = (
    width = getInitialWidth(),
    height = getInitialHeight()
  ) => {
    const dimensionsVariation = dataContainer?.findModelDimensionsVariation(
      selectedModel,
      {
        width,
        height,
      },
      productType
    );

    if (dimensionsVariation) {
      if (!dataContainer?.isVariationSelected(dimensionsVariation.id)) {
        const sizeVariation = dataContainer?.mSizeVariation;

        if (
          (category === "side" || category === "fixed") &&
          !isInitialRender &&
          sizeVariation === dimensionsVariation.id
        ) {
          return;
        }

        if (isInitialRender) {
          setIsInitialRender(false);
        }
      }
      dataContainer.toggleVariationSelection(dimensionsVariation.id, "add");
    }
  };

  useEffect(() => {
    if (state?.width || state?.height) {
      setWidth(state?.width);
      setHeight(state?.height);
    }
  }, [state?.width, state?.height]);

  useEffect(() => {
    if (productType === "windows") {
      const frameDimensions = dataContainer?.getConfigurationMetadata(
        "frameDimensions",
        "windows",
        dataContainer?.mCurrentConfigurationIndex["windows"]
      );

      if (frameDimensions) {
        setWidth(frameDimensions.width);
        setHeight(frameDimensions.height);

        toggleDimensionVariation(frameDimensions.width, frameDimensions.height);

        return;
      }

      toggleDimensionVariation();

      return;
    }

    if (dimensionsVariation && selectedModel && productType && width) {
      setWidth(
        Number(dimensionsVariation.assets[0]?.attribute_values[0]?.value) *
          DimensionsMultiplier[productType]
      );
      setHeight(
        Number(dimensionsVariation.assets[0]?.attribute_values[1]?.value) *
          DimensionsMultiplier[productType]
      );
    } else {
      toggleDimensionVariation();
    }
  }, [selectedModel, dataContainer, sliderValues]);

  const onAfterChangeHandler = () => {
    toggleDimensionVariation(
      width || getInitialWidth(),
      height || getInitialHeight()
    );
  };

  return (
    <>
      <div className="absolute h-80 max-h-60 sliderV">
        <SliderRange
          values={sliderValues.vertical}
          defaultValue={400}
          step={100}
          vertical={true}
          prefix="mm"
          onChange={(e) => setHeight(e)}
          subtractor={20}
          value={[height]}
          onAfterChange={onAfterChangeHandler}
        />
      </div>
      <div className="absolute w-56 sliderH z-[10]">
        <SliderRange
          values={sliderValues.horizontal}
          defaultValue={Math.max(...sliderValues.horizontal)}
          step={100}
          prefix="mm"
          onChange={(e) => setWidth(e)}
          subtractor={20}
          value={[width]}
          reverse={true}
          onAfterChange={onAfterChangeHandler}
        />
      </div>
    </>
  );
};
