import { Engine, Scene } from "@babylonjs/core";
import React, { useEffect, useRef, useContext } from "react";
import { SceneComponentProps } from "../../common/interfaces/scene.interfaces";
import { BabylonContext } from "../../common/context/BabylonProvider";
import classnames from "classnames";
import useCheckMobileScreen from "src/hooks/CheckMobileScreen";


const SceneComponent: React.FC<SceneComponentProps> = (props) => {
  const reactCanvas = useRef(null);
  const { antialias, engineOptions, sceneOptions, onSceneReady, ...rest } =
    props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { state } = useContext<any>(BabylonContext);  

  useEffect(() => {
    if (reactCanvas.current) {
      const engine = new Engine(
        reactCanvas.current,
        antialias,
        engineOptions,
        true
      );
      const scene = new Scene(engine, sceneOptions);
      scene.collisionsEnabled = true;
      scene.autoClear = false;
      scene.autoClearDepthAndStencil = false;
      scene.blockMaterialDirtyMechanism = true;
      scene.getAnimationRatio();

      if (scene.isReady()) {
        onSceneReady(scene);
      } else {
        scene.onReadyObservable.addOnce((sceneObservable) =>
          onSceneReady(sceneObservable)
        );
      }

      engine.runRenderLoop(() => {
        scene.render();
      });

      const resize = () => {
        scene.getEngine().resize();
      };

      if (window) {
        window.addEventListener("resize", resize);
      }

      return () => {
        scene.getEngine().dispose();
        if (window) {
          window.removeEventListener("resize", resize);
        }
      };
    }
  }, [reactCanvas]);

  const isMobile = useCheckMobileScreen();

  return (
    <>
      <canvas
        id="woodCanvas"
        className={classnames(
          "w-full h-full transition-width duration-700",
          {"w-2/3": state.menuOpen && !isMobile}
        )}
        ref={reactCanvas}
        {...rest}
      />
    </>
  );
};

export default SceneComponent;
