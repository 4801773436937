import { colorsVariants } from "../../consts/colors";

export const styles = {
  menu: (styles) => ({
    ...styles,
    marginTop: 0,
    fontSize: "12px",
    border: `1px solid ${colorsVariants.black.hex}`,
    borderTop: "none",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: colorsVariants.black.hex,
  }),
  control: (styles) => ({
    ...styles,
    boxShadow: "none",
    width: "180px",
    borderColor: colorsVariants.black.hex,
    "&:hover": {
      border: `1px solid ${colorsVariants.black.hex}`,
    },
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    "&:hover": { backgroundColor: colorsVariants.smoke.hex },
    color: colorsVariants.black.hex,
    backgroundColor: isSelected ? colorsVariants.smoke.hex : null,
  }),
};
