import React from "react";
import { LoadingComponentProps } from "./type";

const Loading = ({ productType }: LoadingComponentProps) => {
  const DOOR_LOADING_TEXT = "Din dörrmodell förbereds";
  const WINDOW_LOADING_TEXT = "Ditt fönster laddar";
  const NONE = "Vår konfigurator laddar";

  return (
    <div>
      <h2 className="text-primary text-[18px]">
        {productType === "doors"
          ? DOOR_LOADING_TEXT
          : productType === "windows"
            ? WINDOW_LOADING_TEXT
            : NONE}
      </h2>
      <div className="snippet mt-7 flex justify-center">
        <div className="stage">
          <div className="dot-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
