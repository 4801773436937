import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { Button } from "../button/Button";
import { CounterProps } from "./Types";

function Counter(props: CounterProps) {
  const { className, configuration, dataContainer } = props;

  const [quantity, setQuantity] = useState(
    (configuration &&
      dataContainer?.mConfigurations[configuration?.productType][
        configuration.index
      ]?.metadata?.quantity) ||
      1
  );

  useEffect(() => {
    dataContainer?.setConfigurationMetadata(
      "quantity",
      quantity,
      configuration?.productType,
      configuration?.index
    );
  }, [quantity]);

  const increase = () => {
    setQuantity((quantity) => quantity + 1);
  };

  const decrease = () => {
    if (quantity > 1) {
      setQuantity((quantity) => quantity - 1);
    }
  };

  const length = (quantity + "").length;

  return (
    <div
      className={classnames("w-full flex items-center flex-col text-center sm:w-24", className)}
    >
      <div className="sm:relative absolute sm:left-0 left-24 bottom-4 sm:bottom-0">
        <Button label="-" onClick={decrease} />
        <input className="text-black mx-4" value={quantity} size={length} />
        <Button label="+" onClick={increase} />
      </div>
    </div>
  );
}

export { Counter };
