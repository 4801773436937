import React from "react";
import { IconProps } from "../Types";

const Tillval = ({ className }: IconProps) => (
  <svg className={className} width="15" height="32" viewBox="0 0 15 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14 1H1L1 3H3.9993L4 3L11 3H14V1ZM1 4L3 4L3 9.50016L2.07677 9.88484C1.82187 9.99105 1.70133 10.2838 1.80754 10.5387C1.91375 10.7936 2.20649 10.9141 2.46139 10.8079L3 10.5835V13.5002L2.07677 13.8848C1.82187 13.991 1.70133 14.2838 1.80754 14.5387C1.91375 14.7936 2.20649 14.9141 2.46139 14.8079L3 14.5835V17.5002L2.07677 17.8848C1.82187 17.991 1.70133 18.2838 1.80754 18.5387C1.91375 18.7936 2.20649 18.9141 2.46139 18.8079L3 18.5835V21.5002L2.07677 21.8848C1.82187 21.991 1.70133 22.2838 1.80754 22.5387C1.91375 22.7936 2.20649 22.9141 2.46139 22.8079L3 22.5835L3 25.4617C3 25.4744 3.00024 25.4872 3.00073 25.4999L2.07677 25.8848C1.82187 25.991 1.70133 26.2838 1.80754 26.5387C1.91375 26.7936 2.20649 26.9141 2.46139 26.8079L3.4438 26.3986L6.69006 30.8815C7.08929 31.4328 7.91071 31.4328 8.30994 30.8815L11.8099 26.0482C11.9335 25.8776 12 25.6723 12 25.4617V22.8335L13.5383 22.1925C13.7932 22.0863 13.9138 21.7936 13.8075 21.5387C13.7013 21.2838 13.4086 21.1632 13.1537 21.2695L12 21.7502V18.8335L13.5383 18.1925C13.7932 18.0863 13.9138 17.7936 13.8075 17.5387C13.7013 17.2838 13.4086 17.1632 13.1537 17.2695L12 17.7502V14.8335L13.5383 14.1925C13.7932 14.0863 13.9138 13.7936 13.8075 13.5387C13.7013 13.2838 13.4086 13.1632 13.1537 13.2695L12 13.7502V10.8335L13.5383 10.1925C13.7932 10.0863 13.9138 9.79359 13.8075 9.53869C13.7013 9.28379 13.4086 9.16325 13.1537 9.26946L12 9.75016L12 6.8335L13.5383 6.19253C13.7932 6.08632 13.9138 5.79359 13.8075 5.53869C13.7013 5.28379 13.4086 5.16325 13.1537 5.26946L12 5.75016V4H14C14.5523 4 15 3.55228 15 3V1C15 0.447715 14.5523 0 14 0H1C0.447715 0 0 0.447715 0 1V3C0 3.55228 0.447715 4 1 4ZM11 6.16683V4L4 4L4 9.0835L11 6.16683ZM4 10.1668V13.0835L11 10.1668V7.25016L4 10.1668ZM4 17.0835V14.1668L11 11.2502V14.1668L4 17.0835ZM4 18.1668V21.0835L11 18.1668V15.2502L4 18.1668ZM4 25.0835V22.1668L11 19.2502V22.1668L4 25.0835ZM4.39228 26.0034L7.5 30.295L11 25.4617V23.2502L4.39228 26.0034Z" fill="#111111" />
  </svg>
);

export default Tillval;
