/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AbstractAssetTask,
  MeshAssetTask,
  Node,
  Mesh,
  Scene,
} from "@babylonjs/core";
import { AccordionEnum } from "src/services/enums";
import { windowsAccessoriesNodes } from "../HideMeshes";
import {
  CustomDimensionsType,
  handlePositions,
} from "./AirDividersPositionHandler";
import { handleBeslagPosition } from "./BeslagHandlers";
import { WindowsAccessoriesByLuftType } from "./constants";
import { handleSprojsPositions } from "./DividersHandles";
import { handleHingesPositions } from "./HingesHandlers";

let allMeshes: Node[] = [];
const minimumDifference = 0.5;

export const saveTaskNameIntoWindowsMeshes = (tasks: AbstractAssetTask[]) => {
  allMeshes = [];

  const windowsAccessoriesTasks = tasks.filter((task) =>
    windowsAccessoriesNodes.some((substring) => task.name.includes(substring))
  ) as MeshAssetTask[];

  windowsAccessoriesTasks.forEach((task) => {
    const rootNode = task.loadedMeshes?.[0];
    const name = task.name.split(" - ")[1];
    const sprojs = task.name.split(" - ");

    const rootChildrens = rootNode.getDescendants();
    rootChildrens.forEach((child) => {
      if(sprojs[1].includes("sp")){
        child.metadata.task = sprojs[1].split("-")[0];
        child.metadata.sprojsOrder = sprojs[1].split("-")[1] || "0";
      }else{
        child.metadata.task = name;
        allMeshes.push(child);
      }
    });
    rootNode.metadata
      ? (rootNode.metadata.task = name)
      : (rootNode.metadata = { task: name });
    allMeshes.push(rootNode);
  });
};

const setMeshesVisibilityByTaskNameAndModel = (
  taskName: string,
  model: string,
  visibility: boolean
) => {
  allMeshes.forEach((node) => {
    if (
      node instanceof Mesh &&
      node?.metadata?.task.includes(taskName) &&
      node?.metadata?.task.includes(`${model}_`)
    ) {
      node.isVisible = visibility;
    }
  });
};

export const hideAllMeshes = () => {
  allMeshes.forEach((node) => {
    if (node instanceof Mesh) {
      node.isVisible = false;
    }
  });
};

export const applyAirDividers = (
  type: string,
  scene: Scene,
  currentModel: { original_key: string },
  dataContainer: any,
  initialLoad = false
) => {
  displayAccessories(type, currentModel.original_key.toLocaleLowerCase());
  const dividersPositions = dataContainer?.getConfigurationMetadata(
    "dividersPositions",
    "windows",
    dataContainer?.mCurrentConfigurationIndex["windows"]
  );
  const newCustomDimensions = handlePositions(type, scene, false, initialLoad, dividersPositions);
  applyCustomDimensions(newCustomDimensions, dataContainer);
  handleBeslagPosition(scene, dataContainer);
  handleHingesPositions(scene, dataContainer);
};

const displayAccessories = (type: string, model: string) => {
  hideAllMeshes();
  const accessories = WindowsAccessoriesByLuftType[type];
  accessories?.forEach((accessory) =>
    setMeshesVisibilityByTaskNameAndModel(accessory, model, true)
  );
};

export const getWindowsPositionNodes = (scene: Scene) => {
  const topLeftNode = findVisibleNodeByName("bone_frame_top_left", scene);
  const topRightNode = findVisibleNodeByName("bone_frame_top_right", scene);
  const bottomLeftNode = findVisibleNodeByName("bone_frame_bottom_left", scene);
  const bottomRightNode = findVisibleNodeByName(
    "bone_frame_bottom_right",
    scene
  );

  return { topLeftNode, topRightNode, bottomLeftNode, bottomRightNode };
};

const findVisibleNodeByName = (nodeName: string, scene: Scene, task = "") => {
  return scene?.getTransformNodesById(nodeName).find((node) => {
    const rootNode = node.parent?.parent;

    if (rootNode instanceof Mesh) {
      if (!task) {
        return rootNode.isVisible;
      }
      return rootNode?.isVisible && node?.metadata?.task?.includes(task);
    }
    return false;
  });
};

export const getMiscNodes = (task: string, scene: Scene) => {
  const topLeftMisc = findVisibleNodeByName(
    "bone_frame_misc_top_left",
    scene,
    task
  );
  const bottomLeftMisc = findVisibleNodeByName(
    "bone_frame_misc_bottom_left",
    scene,
    task
  );
  const topRightMisc = findVisibleNodeByName(
    "bone_frame_misc_top_right",
    scene,
    task
  );
  const bottomRightMisc = findVisibleNodeByName(
    "bone_frame_misc_bottom_right",
    scene,
    task
  );

  if (topLeftMisc && bottomLeftMisc && topRightMisc && bottomRightMisc) {
    return { topLeftMisc, topRightMisc, bottomLeftMisc, bottomRightMisc };
  }
  return null;
};

const findSprojsVisibleNodeByName = (nodeName: string, scene: Scene, task = "", order) => {
  return scene?.getTransformNodesById(nodeName).find((node) => {
    const rootNode = node.parent?.parent;

    if (rootNode instanceof Mesh) {
      return rootNode?.isVisible &&
        node?.metadata?.task?.includes(task) &&
        node?.metadata?.sprojsOrder?.includes(order);
    }
    return false;
  });
};

export const getSprojsNodes = (task: string, scene: Scene, order) => {
  const topLeftSprojs = findSprojsVisibleNodeByName(
    "bone_grills_horizontal_top_left",
    scene,
    task,
    order
  );
  const bottomLeftSprojs = findSprojsVisibleNodeByName(
    "bone_grills_horizontal_bottom_left",
    scene,
    task,
    order
  );
  const topRightSprojs = findSprojsVisibleNodeByName(
    "bone_grills_horizontal_top_right",
    scene,
    task,
    order
  );
  const bottomRightSprojs = findSprojsVisibleNodeByName(
    "bone_grills_horizontal_bottom_right",
    scene,
    task,
    order
  );

  if (topLeftSprojs && bottomLeftSprojs && topRightSprojs && bottomRightSprojs) {
    return { topLeftSprojs, topRightSprojs, bottomLeftSprojs, bottomRightSprojs };
  }
  return null;
};

export const getSprojsVerticalNodes = (task: string, scene: Scene, order) => {
  const topLeftSprojs = findSprojsVisibleNodeByName(
    "bone_grills_vertical_top_left",
    scene,
    task,
    order
  );
  const bottomLeftSprojs = findSprojsVisibleNodeByName(
    "bone_grills_vertical_bottom_left",
    scene,
    task,
    order
  );
  const topRightSprojs = findSprojsVisibleNodeByName(
    "bone_grills_vertical_top_right",
    scene,
    task,
    order
  );
  const bottomRightSprojs = findSprojsVisibleNodeByName(
    "bone_grills_vertical_bottom_right",
    scene,
    task,
    order
  );

  if (topLeftSprojs && bottomLeftSprojs && topRightSprojs && bottomRightSprojs) {
    return { topLeftSprojs, topRightSprojs, bottomLeftSprojs, bottomRightSprojs };
  }
  return null;
};

export const getDividerNodes = (task: string, scene: Scene) => {
  if (task.includes("vertical")) {
    const mullionTop = findVisibleNodeByName("bone_mullion_top", scene, task);

    const mullionBottom = findVisibleNodeByName(
      "bone_mullion_bottom",
      scene,
      task
    );

    if (mullionBottom && mullionTop) {
      return { mullionTop, mullionBottom };
    }
  } else {
    const transomLeft = findVisibleNodeByName("bone_transom_left", scene, task);

    const transomRight = findVisibleNodeByName(
      "bone_transom_right",
      scene,
      task
    );

    if (transomLeft && transomRight) {
      return { transomRight, transomLeft };
    }
  }
};

export const adjustDividerPosition = (
  dividerType: string,
  amount: number,
  variation: string,
  scene: Scene,
  dataContainer: any
) => {
  const divider = getDividerNodes(dividerType, scene);

  if (dividerType.includes("vertical")) {
    if (!divider?.mullionBottom || !divider?.mullionTop) {
      return;
    }

    if (canAdjustVertically(divider, dividerType, amount, variation, scene)) {
      divider.mullionBottom.position.x += amount;
      divider.mullionTop.position.x += amount;
    }

    const newCustomDimensions = handlePositions(variation, scene, true);
    applyCustomDimensions(newCustomDimensions, dataContainer);
    handleBeslagPosition(scene, dataContainer);
    handleSprojsPositions(scene, dataContainer);

    return;
  }
  if (!divider?.transomLeft || !divider.transomRight) {
    return;
  }

  if (canAdjustHorizontally(divider, amount, scene)) {
    divider.transomLeft.position.y += amount;
    divider.transomRight.position.y += amount;
  }

  const newCustomDimensions = handlePositions(variation, scene, true);
  applyCustomDimensions(newCustomDimensions, dataContainer);
  handleBeslagPosition(scene, dataContainer);
  handleSprojsPositions(scene, dataContainer);
};

const canAdjustHorizontally = (divider, amount, scene) => {
  const { topLeftNode, bottomLeftNode } = getWindowsPositionNodes(scene);

  if (!topLeftNode || !bottomLeftNode) {
    return false;
  }

  if (amount > 0) {
    return (
      Math.abs(divider.transomLeft.position.y - topLeftNode?.position.y) >
      minimumDifference
    );
  }
  return (
    Math.abs(divider.transomLeft.position.y - bottomLeftNode?.position.y) >
    minimumDifference
  );
};

const canAdjustVertically = (
  divider,
  dividerType,
  amount,
  variation,
  scene
) => {
  const { topLeftNode, topRightNode, bottomLeftNode } =
    getWindowsPositionNodes(scene);

  if (!topLeftNode || !topRightNode || !bottomLeftNode) {
    return false;
  }

  switch (variation) {
  case "2_2":
  case "2": {
    if (amount > 0) {
      return (
        Math.abs(
          divider.mullionBottom.position.x - topRightNode?.position.x
        ) > minimumDifference
      );
    }
    return (
      Math.abs(divider.mullionBottom.position.x - topLeftNode?.position.x) >
        minimumDifference
    );
  }
  case "3_3a":
  case "3_3b":
  case "3a":
  case "3b": {
    if (dividerType === "vertical0") {
      const secondVertical = getDividerNodes("vertical1", scene);
      if (!secondVertical?.mullionBottom) return false;

      return amount > 0
        ? Math.abs(
          divider.mullionBottom.position.x -
                secondVertical.mullionBottom.position.x
        ) > minimumDifference
        : Math.abs(
          divider.mullionBottom.position.x - topLeftNode?.position.x
        ) > minimumDifference;
    }
    const firstVertical = getDividerNodes("vertical0", scene);
    if (!firstVertical?.mullionBottom) return false;

    return amount > 0
      ? Math.abs(divider.mullionBottom.position.x - topRightNode.position.x) >
            minimumDifference
      : Math.abs(
        divider.mullionBottom.position.x -
              firstVertical?.mullionBottom.position.x
      ) > minimumDifference;
  }
  default:
    return false;
  }
};

const applyCustomDimensions = (
  customDimensions: CustomDimensionsType | undefined,
  dataContainer: any
) => {
  if (customDimensions && dataContainer) {
    dataContainer?.setConfigurationMetadata(
      "customDimensions",
      customDimensions.formatedDimensions,
      "windows"
    );
    dataContainer?.setConfigurationMetadata(
      "frameDimensions",
      customDimensions.frameDimensions,
      "windows"
    );
    dataContainer?.setConfigurationMetadata(
      "dividersPositions",
      customDimensions.dividersPositions,
      "windows"
    );

    const sizeVariation = dataContainer?.findSelectedVariantByComponentName(AccordionEnum.Size);

    if(sizeVariation) {
      dataContainer.mSizeVariation = sizeVariation.id;
    }
    
    dataContainer.unselectVariationByComponentName(AccordionEnum.Size, true);
  }
};
