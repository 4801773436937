/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AbstractAssetTask,
  MeshAssetTask,
  AbstractMesh,
  Scene,
  TransformNode,
  Vector3,
} from "@babylonjs/core";
import { AccordionEnum } from "src/services/enums";
import { getDoorWindowMisc, getWindowMiscs } from "./BeslagHandlers";

const visibleHinges = {
  "1a": ["0"],
  "1b": ["0"],
  "1_1a": ["0", "1"],
  "1_1b": ["0", "1"],
  "2": ["0", "1"],
  "2_2": ["0", "1", "2", "3"],
  "3a": ["0", "1", "2"],
  "3b": ["0", "1", "2"],
  "3_3a": ["0", "1", "2", "3", "4", "5"],
  "3_3b": ["0", "1", "2", "3", "4", "5"],
};

let allMeshes: AbstractMesh[] = [];

export const saveWindowHingesData = (tasks: AbstractAssetTask[]) => {
  allMeshes = [];

  tasks.forEach((task) => {
    if (task.name.includes(AccordionEnum.Hinge)) {
      const assetTask = task as MeshAssetTask;
      const taskNameParts = task.name.split(" - ");
      const variationName = taskNameParts?.[1];

      if (!variationName) {
        return;
      }

      assetTask?.loadedMeshes?.forEach((mesh) => {
        allMeshes.push(mesh);

        if (!mesh.metadata) {
          mesh.metadata = {};
        }
        const hingePlacement = taskNameParts.at(-1);
        mesh.metadata.task = variationName;
        mesh.metadata.hingePlacement = hingePlacement?.split("_")[1];
      });
    }
  });
};

const hideAllMeshes = () => {
  allMeshes?.forEach((mesh) => (mesh.isVisible = false));
};

const showMeshes = (hingeVariation: string, luftType: string) => {
  const visibleHingeTypes = visibleHinges[luftType] || [];
  const hingeVariationName = hingeVariation.split("_").at(-2);

  allMeshes.forEach((mesh) => {
    if (
      hingeVariationName === mesh.metadata.task.split("_").at(-2) &&
      visibleHingeTypes.includes(mesh.metadata.hingePlacement.slice(-1))
    ) {
      mesh.isVisible = true;
    }
  });
};

export const handleHingesPositions = (scene: Scene, dataContainer: any) => {
  hideAllMeshes();

  const hingeType = dataContainer?.findSelectedVariantByComponentName(
    AccordionEnum.Hinge
  )?.original_key;

  if (!hingeType) {
    return;
  }

  const miscNodes = getWindowMiscs(scene)?.firstMisc
    ? getWindowMiscs(scene)
    : getDoorWindowMisc(scene);
  const luftTypeVariation = dataContainer?.findSelectedVariantByComponentName(
    AccordionEnum.Luft_Types
  );
  const luftType = luftTypeVariation ? luftTypeVariation.original_key : "1a";

  showMeshes(hingeType, luftType);
  handlePositions(miscNodes, luftType);
};

const handlePositions = (miscNodes: any, luftType: any) => {
  switch (luftType) {
  case "1a":
  case "1b": 
    handleSingleMiscWindows(miscNodes, luftType);
    break;
  case "2":
    handleDoubleMiscWindows(miscNodes);
    break;
  case "2_2":
    handleFourMiscWindows(miscNodes);
    break;
  case "1_1a":
  case "1_1b":
    handleDoubleMiscVerticalWindows(miscNodes, luftType);
    break;
  case "3a":
  case "3b":
    handleTripleMiscWindows(miscNodes, luftType);
    break;
  case "3_3a":
  case "3_3b":
    handleSixMiscWindows(miscNodes, luftType);
    break;
  }
};

const findHingeParent = (luftPlacement: string) => {
  const visibleMesh = allMeshes.find((mesh) => {
    return (
      mesh.isVisible &&
      mesh.metadata.hingePlacement === luftPlacement &&
      mesh.parent instanceof TransformNode &&
      mesh.parent?.parent?.name?.includes("root")
    );
  });

  const hinges1228 = ["acorn", "cone", "flat"];

  if (visibleMesh) {
    if(hinges1228.some((hingeName) => visibleMesh.name?.endsWith(hingeName))) {
      return visibleMesh?.parent;
    }
    return visibleMesh?.parent?.parent;
  }

  return null;
};

const setHingeParent = (hingePosition: string, miscNode: TransformNode) => {
  const hingeNode = findHingeParent(hingePosition) as TransformNode;
  const hingeNull = miscNode?.getChildren()?.[0] as TransformNode;

  if (hingeNode && hingeNull) {
    hingeNode.setParent(hingeNull);
    hingeNode.position = new Vector3(0, 0, 0);

    if(hingeNode?.name?.includes("bultgangjarn")) {
      hingeNode.rotation = hingeNull.rotation;
    }
  }

};

const handleSingleMiscWindows = (miscNodes: any, luftType) => {
  const miscAlignment = luftType === "1a" ? "Left" : "Right";
  const topHingeNull = miscNodes?.firstMisc?.[`top${miscAlignment}Misc`];
  const bottomHingeNull = miscNodes?.firstMisc?.[`bottom${miscAlignment}Misc`];

  setHingeParent("top0", topHingeNull);
  setHingeParent("bottom0", bottomHingeNull);
};

const handleDoubleMiscWindows = (miscNodes: any) => {
  const firstTopHingeNull = miscNodes?.firstMisc?.topLeftMisc;
  const firstBottomHingeNull = miscNodes?.firstMisc?.bottomLeftMisc;
  setHingeParent("top0", firstTopHingeNull);
  setHingeParent("bottom0", firstBottomHingeNull);

  const secondTopHingeNull = miscNodes?.secondMisc?.topRightMisc;
  const secondBottomHingeNull = miscNodes?.secondMisc?.bottomRightMisc;
  setHingeParent("top1", secondTopHingeNull);
  setHingeParent("bottom1", secondBottomHingeNull);
};

const handleFourMiscWindows = (miscNodes: any) => {
  const firstTopHingeNull = miscNodes?.firstMisc?.topLeftMisc;
  const firstBottomHingeNull = miscNodes?.firstMisc?.bottomLeftMisc;
  setHingeParent("top0", firstTopHingeNull);
  setHingeParent("bottom0", firstBottomHingeNull);

  const secondTopHingeNull = miscNodes?.secondMisc?.topRightMisc;
  const secondBottomHingeNull = miscNodes?.secondMisc?.bottomRightMisc;
  setHingeParent("top1", secondTopHingeNull);
  setHingeParent("bottom1", secondBottomHingeNull);

  const thirdTopHingeNull = miscNodes?.thirdMisc?.topLeftMisc;
  const thirdBottomHingeNull = miscNodes?.thirdMisc?.bottomLeftMisc;
  setHingeParent("top2", thirdTopHingeNull);
  setHingeParent("bottom2", thirdBottomHingeNull);

  const fourthTopHingeNull = miscNodes?.fourthMisc?.topRightMisc;
  const fourthBottomHingeNull = miscNodes?.fourthMisc?.bottomRightMisc;
  setHingeParent("top3", fourthTopHingeNull);
  setHingeParent("bottom3", fourthBottomHingeNull);

};

const handleDoubleMiscVerticalWindows = (miscNodes: any, luftType: string) => {
  const miscAlignment = luftType === "1_1a" ? "Left" : "Right";
  
  const firstTopHingeNull = miscNodes?.firstMisc?.[`top${miscAlignment}Misc`];
  const firstBottomHingeNull = miscNodes?.firstMisc?.[`bottom${miscAlignment}Misc`];
  setHingeParent("top0", firstTopHingeNull);
  setHingeParent("bottom0", firstBottomHingeNull);

  const secondTopHingeNull = miscNodes?.secondMisc?.[`top${miscAlignment}Misc`];
  const secondBottomHingeNull = miscNodes?.secondMisc?.[`bottom${miscAlignment}Misc`];
  setHingeParent("top1", secondTopHingeNull);
  setHingeParent("bottom1", secondBottomHingeNull);
};

const handleTripleMiscWindows = (miscNodes: any, luftType: string) => {
  const miscAlignment = luftType === "3a" ? "Left" : "Right";
  
  const firstTopHingeNull = miscNodes?.firstMisc?.topLeftMisc;
  const firstBottomHingeNull = miscNodes?.firstMisc?.bottomLeftMisc;
  setHingeParent("top0", firstTopHingeNull);
  setHingeParent("bottom0", firstBottomHingeNull);

  const secondTopHingeNull = miscNodes?.secondMisc?.[`top${miscAlignment}Misc`];
  const secondBottomHingeNull = miscNodes?.secondMisc?.[`bottom${miscAlignment}Misc`];
  setHingeParent("top1", secondTopHingeNull);
  setHingeParent("bottom1", secondBottomHingeNull);

  const thirdTopHingeNull = miscNodes?.thirdMisc?.topRightMisc;
  const thirdBottomHingeNull = miscNodes?.thirdMisc?.bottomRightMisc;
  setHingeParent("top2", thirdTopHingeNull);
  setHingeParent("bottom2", thirdBottomHingeNull);

};

const handleSixMiscWindows = (miscNodes, luftType) => {
  const miscAlignment = luftType === "3_3a" ? "Left" : "Right";
  
  const firstTopHingeNull = miscNodes?.firstMisc?.topLeftMisc;
  const firstBottomHingeNull = miscNodes?.firstMisc?.bottomLeftMisc;
  setHingeParent("top0", firstTopHingeNull);
  setHingeParent("bottom0", firstBottomHingeNull);

  const secondTopHingeNull = miscNodes?.secondMisc?.[`top${miscAlignment}Misc`];
  const secondBottomHingeNull = miscNodes?.secondMisc?.[`bottom${miscAlignment}Misc`];
  setHingeParent("top1", secondTopHingeNull);
  setHingeParent("bottom1", secondBottomHingeNull);

  const thirdTopHingeNull = miscNodes?.thirdMisc?.topRightMisc;
  const thirdBottomHingeNull = miscNodes?.thirdMisc?.bottomRightMisc;
  setHingeParent("top2", thirdTopHingeNull);
  setHingeParent("bottom2", thirdBottomHingeNull);

  const fourthTopHingeNull = miscNodes?.fourthMisc?.topLeftMisc;
  const fourthBottomHingeNull = miscNodes?.fourthMisc?.bottomLeftMisc;
  setHingeParent("top3", fourthTopHingeNull);
  setHingeParent("bottom3", fourthBottomHingeNull);

  const fifthTopHingeNull = miscNodes?.fifthMisc?.[`top${miscAlignment}Misc`];
  const fifthBottomHingeNull = miscNodes?.fifthMisc?.[`bottom${miscAlignment}Misc`];
  setHingeParent("top4", fifthTopHingeNull);
  setHingeParent("bottom4", fifthBottomHingeNull);

  const sixthTopHingeNull = miscNodes?.sixthMisc?.topRightMisc;
  const sixthBottomHingeNull = miscNodes?.sixthMisc?.bottomRightMisc;
  setHingeParent("top5", sixthTopHingeNull);
  setHingeParent("bottom5", sixthBottomHingeNull);
};
