import React, { useEffect, useState } from "react";
import { ListProps } from "./Types";
import { Typography } from "../typography/Typography";
import classnames from "classnames";

export const List = ({ title, items, className, listOnChange }: ListProps) => {
  const [isActive, setIsActive] = useState(items[0].id);

  useEffect(() => {
    if (!items.some((item) => item.id === isActive)) {
      setIsActive(items[0].id);
    }
  }, [items]);

  return (
    <div>
      {title && (
        <Typography
          tag="h1"
          className={classnames(
            "mb-9 leading-6 tracking-wider text-black-faded font-normal sm:block hidden",
            className
          )}
        >
          {title}
        </Typography>
      )}
      {items && (
        <ul>
          {items.map((item, index) => (
            <li
              key={`list-item-${item.id}-${index}`}
              className={classnames(
                "flex relative mb-5 sm:pl-4 text-xs cursor-pointer sm:justify-start justify-center text-black-faded before:content-[''] before:w-1 before:h-1 before:bg-primary before:rounded-full before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:opacity-0",
                (isActive === item.id &&
                  "pl-4 opacity-100 before:opacity-100") ||
                  "opacity-40 sm:text-xs text-3xl",
                item.className
              )}
              onClick={() => {
                setIsActive(item.id);
                listOnChange(item.value);
              }}
            >
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
