import React from "react";
import { Icon } from "../icon/Icon";
import { Typography } from "../typography";
import { AddCategoryProps } from "./types";

export const AddCategory = ({ title, icon, onClick }: AddCategoryProps) => (
  <div
    className="absolute z-10 gap-2 add-window items-center hover:cursor-pointer hidden"
    onClick={onClick}
  >
    <div className="flex justify-center items-center px-3 py-2 bg-primary rounded-full">
      <Icon name={icon} />
    </div>
    <Typography tag="p" className="text-3xl text-black-secondary">
      {title}
    </Typography>
  </div>
);
