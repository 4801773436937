import {
  getWindowsPositionNodes,
  getMiscNodes,
  getDividerNodes,
  hideAllMeshes,
} from "./AirDividersHandlers";
import { Scene, TransformNode, Vector3 } from "@babylonjs/core";
import { WindowMiscOffsets } from "./constants";

const MILIMETER_MULTIPLIER = 1000;
const SUBTRACTOR = 20;

type Values = [number, number];

export type CustomDimensionsType = {
  formatedDimensions: string;
  frameDimensions: {
    width: number;
    height: number;
  };
  dividersPositions?: {[key: string]: TransformNode}[] | Record<string, never>
}

const savePositionsIntoMetadata = (
  nodes: { [key: string]: TransformNode }[]
) => {
  const result = {};

  nodes.forEach((node) => {
    const key = Object.keys(node)[0];
    result[key] = { x: node[key].position.x, y: node[key].position.y };
  });

  return result;
};

const getMiscDimensions = (widthValues: Values, heightValues: Values): Values => {
  const width = convertBabylonUnitToMM(widthValues[0], widthValues[1]);
  const height = convertBabylonUnitToMM(heightValues[0], heightValues[1]);

  return [width, height];
};

const getFrameDimensions = (topLeftNode: Vector3, topRightNode: Vector3, bottomLeftNode: Vector3) => {
  const width = convertBabylonUnitToMM(topLeftNode.x, topRightNode.x);
  const height = convertBabylonUnitToMM(topLeftNode.y, bottomLeftNode.y);

  return {width, height};
};

const getFormatDimensions = (dimensions: Array<Values>) => {
  const formatedDimensions: Array<string> = [];
  dimensions.forEach(dimension => {
    formatedDimensions.push(`${Math.round(dimension[0]) - SUBTRACTOR}x${Math.round(dimension[1]) - SUBTRACTOR}mm`);
  });

  return formatedDimensions.join(" / ");
};

const convertBabylonUnitToMM = (firstCoordinate: number, secondCoordinate: number) => {
  return Math.abs(firstCoordinate - secondCoordinate) * MILIMETER_MULTIPLIER;
};

const getWindowType = (positionNode: TransformNode) => {
  if(!positionNode) {
    return;
  }

  const windowNodes = positionNode.parent?.parent?.getChildren();
  const windowFrame = windowNodes?.find(node => node.id?.endsWith("_frame"));
  return windowFrame?.id?.split("_")?.[0]?.toLowerCase();
};

export const handlePositions = (
  type: string,
  scene: Scene,
  ignoreDividers = false,
  initialLoad = false,
  savedDividersPositions = {}
) => {
  const { bottomLeftNode, topLeftNode, topRightNode } =
    getWindowsPositionNodes(scene);

  let formatedDimensions = "";
  let dividersPositions = {};

  if (!bottomLeftNode || !topLeftNode || !topRightNode) {
    return;
  }


  const windowType = getWindowType(topLeftNode);

  if(!windowType || !WindowMiscOffsets[windowType]) {
    return;
  }

  const { left, right, top, bottom } = WindowMiscOffsets[windowType];


  const middlePositionX = (topRightNode?.position.x + topLeftNode?.position.x) / 2;
  const middlePositionY = (topLeftNode.position.y + bottomLeftNode.position.y) / 2;

  const firstThirdPosition = topLeftNode.position.x + Math.abs((topRightNode.position.x - topLeftNode.position.x) / 3);
  const secondThirdPosition = topRightNode.position.x - Math.abs((topRightNode.position.x - topLeftNode.position.x) / 3);

  switch (type) {
  case "2": {
    const mullionNodes = getDividerNodes("vertical0", scene);
    const firstMiscNodes = getMiscNodes("misc0", scene);
    const secondMiscNodes = getMiscNodes("misc1", scene);
    if (
      firstMiscNodes &&
        secondMiscNodes &&
        mullionNodes?.mullionTop &&
        mullionNodes.mullionBottom
    ) {
      if (!ignoreDividers) {
        if (Object.keys(savedDividersPositions)?.length === 2 && initialLoad) {
          mullionNodes.mullionTop.position = new Vector3(
            savedDividersPositions["mullion-top"].x,
            savedDividersPositions["mullion-top"].y
          );
          mullionNodes.mullionBottom.position = new Vector3(
            savedDividersPositions["mullion-bottom"].x,
            savedDividersPositions["mullion-bottom"].y
          );
        } else {
          mullionNodes.mullionTop.position = new Vector3(middlePositionX, topLeftNode?.position.y);
          mullionNodes.mullionBottom.position.x = middlePositionX;
        }
      }

      firstMiscNodes.bottomLeftMisc.position = new Vector3(bottomLeftNode.position.x, bottomLeftNode.position.y);
      firstMiscNodes.topLeftMisc.position = new Vector3(topLeftNode.position.x, topLeftNode.position.y);
      firstMiscNodes.topRightMisc.position = new Vector3(mullionNodes.mullionBottom.position.x + left, topLeftNode.position.y);
      firstMiscNodes.bottomRightMisc.position = new Vector3(mullionNodes.mullionBottom.position.x + left, bottomLeftNode.position.y);
  
      secondMiscNodes.topRightMisc.position = new Vector3(topRightNode.position.x, topRightNode.position.y);
      secondMiscNodes.topLeftMisc.position = new Vector3(mullionNodes.mullionBottom.position.x + right, topLeftNode.position.y);
      secondMiscNodes.bottomLeftMisc.position = new Vector3(mullionNodes.mullionBottom.position.x + right, bottomLeftNode.position.y);
      secondMiscNodes.bottomRightMisc.position = new Vector3(topRightNode.position.x, bottomLeftNode.position.y);

      dividersPositions = savePositionsIntoMetadata([
        { "mullion-top": mullionNodes.mullionTop },
        { "mullion-bottom": mullionNodes.mullionBottom },
      ]);

      const firstMiscDimensions = getMiscDimensions([mullionNodes.mullionBottom.position.x, bottomLeftNode.position.x], [bottomLeftNode.position.y, topLeftNode.position.y]);
      const secondMiscDimensions = getMiscDimensions([mullionNodes.mullionBottom.position.x, topRightNode.position.x], [bottomLeftNode.position.y, topLeftNode.position.y]);

      formatedDimensions = getFormatDimensions([firstMiscDimensions, secondMiscDimensions]);
      break;
    }
    break;
  }
  case "2_2": {
    const mullionNodes = getDividerNodes("vertical0", scene);
    const transomNodes = getDividerNodes("horizontal0", scene);
    const firstMiscNodes = getMiscNodes("misc0", scene);
    const secondMiscNodes = getMiscNodes("misc1", scene);
    const thirdMiscNodes = getMiscNodes("misc2", scene);
    const fourthMiscNodes = getMiscNodes("misc3", scene);

    if (
      !firstMiscNodes ||
        !secondMiscNodes ||
        !thirdMiscNodes ||
        !fourthMiscNodes ||
        !transomNodes?.transomLeft ||
        !transomNodes.transomRight ||
        !mullionNodes?.mullionBottom ||
        !mullionNodes?.mullionTop
    ) {
      break;
    }

    if (!ignoreDividers) {
      if (Object.keys(savedDividersPositions)?.length === 4 && initialLoad) {
        transomNodes.transomRight.position = new Vector3(
          savedDividersPositions["transom-right"].x,
          savedDividersPositions["transom-right"].y
        );
        transomNodes.transomLeft.position = new Vector3(
          savedDividersPositions["transom-left"].x,
          savedDividersPositions["transom-left"].y
        );

        mullionNodes.mullionTop.position = new Vector3(
          savedDividersPositions["mullion-top"].x,
          savedDividersPositions["mullion-top"].y
        );
        mullionNodes.mullionBottom.position = new Vector3(
          savedDividersPositions["mullion-bottom"].x,
          savedDividersPositions["mullion-bottom"].y
        );
      } else {
        transomNodes.transomLeft.position = new Vector3(topLeftNode.position.x, middlePositionY);
        transomNodes.transomRight.position = new Vector3(topRightNode.position.x, middlePositionY);
      
        mullionNodes.mullionTop.position = new Vector3(middlePositionX, topLeftNode?.position.y);
        mullionNodes.mullionBottom.position.x = middlePositionX;
      }
    }

    firstMiscNodes.bottomLeftMisc.position = new Vector3(bottomLeftNode.position.x, transomNodes.transomLeft.position.y + bottom);
    firstMiscNodes.topLeftMisc.position = new Vector3(topLeftNode.position.x, topLeftNode.position.y);
    firstMiscNodes.bottomRightMisc.position = new Vector3(mullionNodes.mullionTop.position.x + left, transomNodes.transomLeft.position.y + bottom);
    firstMiscNodes.topRightMisc.position = new Vector3(mullionNodes.mullionTop.position.x + left, topRightNode.position.y);

    secondMiscNodes.bottomLeftMisc.position = new Vector3(mullionNodes.mullionTop.position.x + right, transomNodes.transomLeft.position.y + bottom);
    secondMiscNodes.topLeftMisc.position = new Vector3(mullionNodes.mullionTop.position.x + right, topLeftNode.position.y);
    secondMiscNodes.bottomRightMisc.position = new Vector3(topRightNode.position.x, transomNodes.transomLeft.position.y + bottom);
    secondMiscNodes.topRightMisc.position = new Vector3(topRightNode.position.x, topRightNode.position.y);

    thirdMiscNodes.bottomLeftMisc.position = new Vector3(bottomLeftNode.position.x, bottomLeftNode.position.y);
    thirdMiscNodes.topLeftMisc.position = new Vector3(topLeftNode.position.x, transomNodes.transomLeft.position.y + top);
    thirdMiscNodes.bottomRightMisc.position = new Vector3(mullionNodes.mullionTop.position.x + left, bottomLeftNode.position.y);
    thirdMiscNodes.topRightMisc.position = new Vector3(mullionNodes.mullionTop.position.x + left, transomNodes.transomLeft.position.y + top);

    fourthMiscNodes.bottomLeftMisc.position = new Vector3(mullionNodes.mullionTop.position.x + right, bottomLeftNode.position.y);
    fourthMiscNodes.topLeftMisc.position = new Vector3(mullionNodes.mullionTop.position.x + right, transomNodes.transomLeft.position.y + top);
    fourthMiscNodes.bottomRightMisc.position = new Vector3(topRightNode.position.x, bottomLeftNode.position.y);
    fourthMiscNodes.topRightMisc.position = new Vector3(topRightNode.position.x, transomNodes.transomLeft.position.y + top);

    dividersPositions = savePositionsIntoMetadata([
      { "mullion-top": mullionNodes.mullionTop },
      { "mullion-bottom": mullionNodes.mullionBottom },
      { "transom-left": transomNodes.transomLeft },
      { "transom-right": transomNodes.transomRight },
    ]);

    const firstMiscDimensions = getMiscDimensions([mullionNodes.mullionBottom.position.x, bottomLeftNode.position.x], [transomNodes.transomLeft.position.y, topLeftNode.position.y]);
    const secondMiscDimensions = getMiscDimensions([mullionNodes.mullionBottom.position.x, topRightNode.position.x], [transomNodes.transomLeft.position.y, topLeftNode.position.y]);
    const thirdMiscDimensions = getMiscDimensions([mullionNodes.mullionBottom.position.x, bottomLeftNode.position.x], [transomNodes.transomLeft.position.y, bottomLeftNode.position.y]);
    const fourthMiscDimensions = getMiscDimensions([mullionNodes.mullionBottom.position.x, topRightNode.position.x], [transomNodes.transomLeft.position.y, bottomLeftNode.position.y]);
    
    formatedDimensions = getFormatDimensions([firstMiscDimensions, secondMiscDimensions, thirdMiscDimensions, fourthMiscDimensions]);

    break;
  }
  case "1b":
  case "1a": {
    const firstMiscNodes = getMiscNodes("misc0", scene);
    if (firstMiscNodes) {
      firstMiscNodes.bottomLeftMisc.position = new Vector3(bottomLeftNode.position.x, bottomLeftNode.position.y);
      firstMiscNodes.topLeftMisc.position = new Vector3(topLeftNode.position.x, topLeftNode.position.y);
      firstMiscNodes.topRightMisc.position = new Vector3(topRightNode.position.x, topLeftNode.position.y);
      firstMiscNodes.bottomRightMisc.position = new Vector3(topRightNode.position.x, bottomLeftNode.position.y);

      const firstMiscDimensions = getMiscDimensions([topLeftNode.position.x, topRightNode.position.x], [bottomLeftNode.position.y, topLeftNode.position.y]);
      formatedDimensions = getFormatDimensions([firstMiscDimensions]);
    }
    break;

  }
  case "1_1b":
  case "1_1a": {
    const firstMiscNodes = getMiscNodes("misc0", scene);
    const secondMiscNodes = getMiscNodes("misc1", scene);
    const transomNodes = getDividerNodes("horizontal0", scene);

    if (
      !firstMiscNodes ||
        !secondMiscNodes ||
        !transomNodes?.transomLeft ||
        !transomNodes.transomRight
    ) {
      break;
    }
    if (!ignoreDividers) {
      if (Object.keys(savedDividersPositions)?.length === 2 && initialLoad) {
        transomNodes.transomRight.position = new Vector3(
          savedDividersPositions["transom-right"].x,
          savedDividersPositions["transom-right"].y
        );
        transomNodes.transomLeft.position = new Vector3(
          savedDividersPositions["transom-left"].x,
          savedDividersPositions["transom-left"].y
        );
      } else {
        transomNodes.transomLeft.position = new Vector3(topLeftNode.position.x, middlePositionY);
        transomNodes.transomRight.position = new Vector3(topRightNode.position.x, middlePositionY);
      }
    }

    firstMiscNodes.topLeftMisc.position = new Vector3(topLeftNode.position.x, topLeftNode.position.y);
    firstMiscNodes.topRightMisc.position = new Vector3(topRightNode.position.x, topRightNode.position.y);
    firstMiscNodes.bottomRightMisc.position = new Vector3(topRightNode.position.x, transomNodes.transomLeft.position.y + bottom);
    firstMiscNodes.bottomLeftMisc.position = new Vector3(bottomLeftNode.position.x, transomNodes.transomLeft.position.y + bottom);

    secondMiscNodes.topLeftMisc.position = new Vector3(topLeftNode.position.x, transomNodes.transomLeft.position.y + top);
    secondMiscNodes.topRightMisc.position = new Vector3(topRightNode.position.x, transomNodes.transomLeft.position.y + top);
    secondMiscNodes.bottomRightMisc.position = new Vector3(topRightNode.position.x, bottomLeftNode.position.y);
    secondMiscNodes.bottomLeftMisc.position = new Vector3(bottomLeftNode.position.x, bottomLeftNode.position.y);

    dividersPositions = savePositionsIntoMetadata([
      { "transom-left": transomNodes.transomLeft },
      { "transom-right": transomNodes.transomRight },
    ]);

    const firstMiscDimensions = getMiscDimensions([topRightNode.position.x, topLeftNode.position.x], [topLeftNode.position.y, transomNodes.transomLeft.position.y]);
    const secondMiscDimensions = getMiscDimensions([topRightNode.position.x, topLeftNode.position.x], [bottomLeftNode.position.y, transomNodes.transomLeft.position.y]);

    formatedDimensions =  getFormatDimensions([firstMiscDimensions, secondMiscDimensions]);
    break;
  }
  case "3b":
  case "3a": {
    const firstMullionNodes = getDividerNodes("vertical0", scene);
    const secondMullionNodes = getDividerNodes("vertical1", scene);
    const firstMiscNodes = getMiscNodes("misc0", scene);
    const secondMiscNodes = getMiscNodes("misc1", scene);
    const thirdMiscNodes = getMiscNodes("misc2", scene);

    if (
      !firstMiscNodes ||
        !thirdMiscNodes ||
        !secondMiscNodes ||
        !firstMullionNodes?.mullionBottom ||
        !firstMullionNodes.mullionTop ||
        !secondMullionNodes?.mullionBottom ||
        !secondMullionNodes.mullionTop
    ) {
      return;
    }

    if (!ignoreDividers) {
      if(Object.keys(savedDividersPositions)?.length === 4 && initialLoad) {
        firstMullionNodes.mullionTop.position = new Vector3(
          savedDividersPositions["firstMullion-top"].x,
          savedDividersPositions["firstMullion-top"].y
        );
        firstMullionNodes.mullionBottom.position = new Vector3(
          savedDividersPositions["firstMullion-bottom"].x,
          savedDividersPositions["firstMullion-bottom"].y
        );
        secondMullionNodes.mullionTop.position = new Vector3(
          savedDividersPositions["secondMullion-top"].x,
          savedDividersPositions["secondMullion-top"].y
        );
        secondMullionNodes.mullionBottom.position = new Vector3(
          savedDividersPositions["secondMullion-bottom"].x,
          savedDividersPositions["secondMullion-bottom"].y
        );
      }else {
        firstMullionNodes.mullionTop.position = new Vector3(firstThirdPosition, topLeftNode.position.y);
        firstMullionNodes.mullionBottom.position = new Vector3(firstThirdPosition, bottomLeftNode.position.y);
        secondMullionNodes.mullionTop.position = new Vector3(secondThirdPosition, topLeftNode.position.y);
        secondMullionNodes.mullionBottom.position = new Vector3(secondThirdPosition, bottomLeftNode.position.y);
      }
    }

    firstMiscNodes.bottomLeftMisc.position = new Vector3(bottomLeftNode.position.x, bottomLeftNode.position.y);
    firstMiscNodes.topLeftMisc.position = new Vector3(topLeftNode.position.x, topLeftNode.position.y);
    firstMiscNodes.bottomRightMisc.position = new Vector3(firstMullionNodes.mullionTop.position.x + left, bottomLeftNode.position.y);
    firstMiscNodes.topRightMisc.position = new Vector3(firstMullionNodes.mullionTop.position.x + left, topRightNode.position.y);

    secondMiscNodes.bottomLeftMisc.position = new Vector3(firstMullionNodes.mullionTop.position.x + right, bottomLeftNode.position.y);
    secondMiscNodes.topLeftMisc.position = new Vector3(firstMullionNodes.mullionTop.position.x + right, topLeftNode.position.y);
    secondMiscNodes.bottomRightMisc.position = new Vector3(secondMullionNodes.mullionTop.position.x + left, bottomLeftNode.position.y);
    secondMiscNodes.topRightMisc.position = new Vector3(secondMullionNodes.mullionTop.position.x + left, topRightNode.position.y);

    thirdMiscNodes.bottomLeftMisc.position = new Vector3(secondMullionNodes.mullionTop.position.x + right, bottomLeftNode.position.y);
    thirdMiscNodes.topLeftMisc.position = new Vector3(secondMullionNodes.mullionTop.position.x + right, topLeftNode.position.y);
    thirdMiscNodes.bottomRightMisc.position = new Vector3(topRightNode.position.x, bottomLeftNode.position.y);
    thirdMiscNodes.topRightMisc.position = new Vector3(topRightNode.position.x, topRightNode.position.y);

    dividersPositions = savePositionsIntoMetadata([
      { "firstMullion-top": firstMullionNodes.mullionTop },
      { "firstMullion-bottom": firstMullionNodes.mullionBottom },
      { "secondMullion-top": secondMullionNodes.mullionTop },
      { "secondMullion-bottom": secondMullionNodes.mullionBottom },
    ]);

    const firstMiscDimensions = getMiscDimensions([firstMullionNodes.mullionBottom.position.x, bottomLeftNode.position.x], [bottomLeftNode.position.y, topLeftNode.position.y]);
    const secondMiscDimensions = getMiscDimensions([firstMullionNodes.mullionBottom.position.x, secondMullionNodes.mullionBottom.position.x], [bottomLeftNode.position.y, topLeftNode.position.y]);
    const thirdMiscDimensions = getMiscDimensions([secondMullionNodes.mullionBottom.position.x, topRightNode.position.x], [bottomLeftNode.position.y, topLeftNode.position.y]);

    formatedDimensions =  getFormatDimensions([firstMiscDimensions, secondMiscDimensions, thirdMiscDimensions]);
    break;
  }
  case "3_3a":
  case "3_3b": {
    const firstMullionNodes = getDividerNodes("vertical0", scene);
    const secondMullionNodes = getDividerNodes("vertical1", scene);
    const firstTransomNodes = getDividerNodes("horizontal0", scene);
    const firstMiscNodes = getMiscNodes("misc0", scene);
    const secondMiscNodes = getMiscNodes("misc1", scene);
    const thirdMiscNodes = getMiscNodes("misc2", scene);
    const fourthMiscNodes = getMiscNodes("misc3", scene);
    const fifthMiscNodes = getMiscNodes("misc4", scene);
    const sixthMiscNodes = getMiscNodes("misc5", scene);

    if (
      !firstMiscNodes ||
        !secondMiscNodes ||
        !thirdMiscNodes ||
        !fourthMiscNodes ||
        !fifthMiscNodes ||
        !sixthMiscNodes ||
        !firstMullionNodes?.mullionBottom ||
        !firstMullionNodes?.mullionTop ||
        !secondMullionNodes?.mullionBottom ||
        !secondMullionNodes?.mullionTop ||
        !firstTransomNodes?.transomLeft ||
        !firstTransomNodes?.transomRight
    ) {
      return;
    }

    if(!ignoreDividers) {

      if(Object.keys(savedDividersPositions)?.length === 6 && initialLoad) {
        firstMullionNodes.mullionTop.position = new Vector3(
          savedDividersPositions["firstMullion-top"].x,
          savedDividersPositions["firstMullion-top"].y
        );
        firstMullionNodes.mullionBottom.position = new Vector3(
          savedDividersPositions["firstMullion-bottom"].x,
          savedDividersPositions["firstMullion-bottom"].y
        );

        secondMullionNodes.mullionTop.position = new Vector3(
          savedDividersPositions["secondMullion-top"].x,
          savedDividersPositions["secondMullion-top"].y
        );
        secondMullionNodes.mullionBottom.position = new Vector3(
          savedDividersPositions["secondMullion-bottom"].x,
          savedDividersPositions["secondMullion-bottom"].y
        );

        firstTransomNodes.transomRight.position = new Vector3(
          savedDividersPositions["firstTransom-right"].x,
          savedDividersPositions["firstTransom-right"].y
        );
        firstTransomNodes.transomLeft.position = new Vector3(
          savedDividersPositions["firstTransom-left"].x,
          savedDividersPositions["firstTransom-left"].y
        );
      }else {
        firstMullionNodes.mullionTop.position = new Vector3(firstThirdPosition, topLeftNode.position.y);
        firstMullionNodes.mullionBottom.position = new Vector3(firstThirdPosition, bottomLeftNode.position.y);

        secondMullionNodes.mullionTop.position = new Vector3(secondThirdPosition, topLeftNode.position.y);
        secondMullionNodes.mullionBottom.position = new Vector3(secondThirdPosition, bottomLeftNode.position.y);

        firstTransomNodes.transomLeft.position = new Vector3(topLeftNode.position.x, middlePositionY);
        firstTransomNodes.transomRight.position = new Vector3(topRightNode.position.x, middlePositionY);
      }
    }

    firstMiscNodes.bottomLeftMisc.position = new Vector3(bottomLeftNode.position.x, firstTransomNodes.transomLeft.position.y + bottom);
    firstMiscNodes.topLeftMisc.position = new Vector3(topLeftNode.position.x, topLeftNode.position.y);
    firstMiscNodes.bottomRightMisc.position = new Vector3(firstMullionNodes.mullionTop.position.x + left, firstTransomNodes.transomLeft.position.y + bottom);
    firstMiscNodes.topRightMisc.position = new Vector3(firstMullionNodes.mullionTop.position.x + left, topRightNode.position.y);

    secondMiscNodes.bottomLeftMisc.position = new Vector3(firstMullionNodes.mullionTop.position.x + right, firstTransomNodes.transomLeft.position.y + bottom);
    secondMiscNodes.topLeftMisc.position = new Vector3(firstMullionNodes.mullionTop.position.x + right, topLeftNode.position.y);
    secondMiscNodes.bottomRightMisc.position = new Vector3(secondMullionNodes.mullionTop.position.x + left, firstTransomNodes.transomLeft.position.y + bottom);
    secondMiscNodes.topRightMisc.position = new Vector3(secondMullionNodes.mullionTop.position.x + left, topRightNode.position.y);

    thirdMiscNodes.bottomLeftMisc.position = new Vector3(secondMullionNodes.mullionTop.position.x + right, firstTransomNodes.transomLeft.position.y + bottom);
    thirdMiscNodes.topLeftMisc.position = new Vector3(secondMullionNodes.mullionTop.position.x + right, topLeftNode.position.y);
    thirdMiscNodes.bottomRightMisc.position = new Vector3(topRightNode.position.x, firstTransomNodes.transomLeft.position.y + bottom);
    thirdMiscNodes.topRightMisc.position = new Vector3(topRightNode.position.x, topRightNode.position.y);

    fourthMiscNodes.bottomLeftMisc.position = new Vector3(bottomLeftNode.position.x, bottomLeftNode.position.y);
    fourthMiscNodes.topLeftMisc.position = new Vector3(topLeftNode.position.x, firstTransomNodes.transomLeft.position.y + top);
    fourthMiscNodes.bottomRightMisc.position = new Vector3(firstMullionNodes.mullionTop.position.x + left, bottomLeftNode.position.y);
    fourthMiscNodes.topRightMisc.position = new Vector3(firstMullionNodes.mullionTop.position.x + left, firstTransomNodes.transomLeft.position.y + top);

    fifthMiscNodes.bottomLeftMisc.position = new Vector3(firstMullionNodes.mullionTop.position.x + right, bottomLeftNode.position.y);
    fifthMiscNodes.topLeftMisc.position = new Vector3(firstMullionNodes.mullionTop.position.x + right, firstTransomNodes.transomLeft.position.y + top);
    fifthMiscNodes.bottomRightMisc.position = new Vector3(secondMullionNodes.mullionTop.position.x + left, bottomLeftNode.position.y);
    fifthMiscNodes.topRightMisc.position = new Vector3(secondMullionNodes.mullionTop.position.x + left, firstTransomNodes.transomLeft.position.y + top);

    sixthMiscNodes.bottomLeftMisc.position = new Vector3(secondMullionNodes.mullionTop.position.x + right, bottomLeftNode.position.y);
    sixthMiscNodes.topLeftMisc.position = new Vector3(secondMullionNodes.mullionTop.position.x + right, firstTransomNodes.transomLeft.position.y + top);
    sixthMiscNodes.bottomRightMisc.position = new Vector3(topRightNode.position.x, bottomLeftNode.position.y);
    sixthMiscNodes.topRightMisc.position = new Vector3(topRightNode.position.x, firstTransomNodes.transomLeft.position.y + top);

    dividersPositions = savePositionsIntoMetadata([
      { "firstMullion-top": firstMullionNodes.mullionTop },
      { "firstMullion-bottom": firstMullionNodes.mullionBottom },
      { "secondMullion-top": secondMullionNodes.mullionTop },
      { "secondMullion-bottom": secondMullionNodes.mullionBottom },
      { "firstTransom-left": firstTransomNodes.transomLeft },
      { "firstTransom-right": firstTransomNodes.transomRight },
    ]);

    const firstMiscDimensions = getMiscDimensions([firstMullionNodes.mullionBottom.position.x, bottomLeftNode.position.x], [firstTransomNodes.transomLeft.position.y, topLeftNode.position.y]);
    const secondMiscDimensions = getMiscDimensions([firstMullionNodes.mullionBottom.position.x, secondMullionNodes.mullionBottom.position.x], [firstTransomNodes.transomLeft.position.y, topLeftNode.position.y]);
    const thirdMiscDimensions = getMiscDimensions([secondMullionNodes.mullionBottom.position.x, topRightNode.position.x], [firstTransomNodes.transomLeft.position.y, topLeftNode.position.y]);
    const fourthMiscDimensions = getMiscDimensions([firstMullionNodes.mullionBottom.position.x, bottomLeftNode.position.x], [firstTransomNodes.transomLeft.position.y, bottomLeftNode.position.y]);
    const fifthMiscDimensions = getMiscDimensions([firstMullionNodes.mullionBottom.position.x, secondMullionNodes.mullionBottom.position.x], [firstTransomNodes.transomLeft.position.y, bottomLeftNode.position.y]);
    const sixthDimensions = getMiscDimensions([secondMullionNodes.mullionBottom.position.x, topRightNode.position.x], [firstTransomNodes.transomLeft.position.y, bottomLeftNode.position.y]);


    formatedDimensions = getFormatDimensions([firstMiscDimensions, secondMiscDimensions, thirdMiscDimensions, fourthMiscDimensions, fifthMiscDimensions, sixthDimensions]);
    break;
  }
  default:
    hideAllMeshes();
    break;
  }

  const frameDimensions = getFrameDimensions(topLeftNode.position, topRightNode.position, bottomLeftNode.position);

  return {formatedDimensions, frameDimensions, dividersPositions};

};
