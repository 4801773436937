import React from "react";
import { Typography } from "../typography";
import { Image } from "../image";
import { ProductIntroProps } from "./types";
import { Icon } from "../icon/Icon";

export const ProductIntro = ({
  primaryProductData,
  className,
}: ProductIntroProps) => (
  <div className="w-full">
    <div
      className={`relative flex flex-col w-full md:flex-row items-center gap-[1.875rem] md:gap-9 ${className} md:pl-[2rem]`}
    >
      <Image
        title={primaryProductData?.image?.title}
        src={primaryProductData?.image?.src}
        className={`h-[18.25rem] sm:h-[21.875rem] lg:h-[26.25rem] xl:h-[32rem] w-auto ${primaryProductData?.image?.className}`}
        onClick={primaryProductData.button.onClick}
      />
      <div className="flex flex-col items-center text-center w-3/5 gap-5 md:w-[40%] xl-[20%]  md:text-left md:items-start ">
        <Typography
          tag="h3"
          className="text-4xl md:text-5xl text-primary leading-[1.094rem] font-normal tracking-wider"
        >
          {primaryProductData.title}
        </Typography>
        <Typography
          tag="p"
          className="text-2xl lg:text-3xl font-medium leading-5 text-black-faded"
        >
          {primaryProductData.description}
        </Typography>
        <button
          className="text-4xl font-normal lg:mt-16 xl:mt-20 text-black-faded"
          onClick={primaryProductData.button.onClick}
        >
          <span className="flex gap-4 hover:opacity-70 text-2xl md:text-4xl items-center">
            {primaryProductData.button?.text.toUpperCase()}
            <Icon
              name={"RightArrow"}
              className={`w-[0.375rem] h-[0.813rem] md:h-[1.125rem] md:w-[0.625rem] ${primaryProductData?.image?.className}`}
            />
          </span>
        </button>
      </div>
    </div>
  </div>
);
