import React from "react";
import { IconProps } from "../Types";

const Remove = ({ className }: IconProps) => (
  <svg
    className={className}
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.714286 12.4444C0.714286 13.3 1.35714 14 2.14286 14H7.85714C8.64286 14 9.28571 13.3 9.28571 12.4444V3.11111H0.714286V12.4444ZM10 0.777778H7.5L6.78571 0H3.21429L2.5 0.777778H0V2.33333H10V0.777778Z"
      fill="#333333"
    />
  </svg>
);

export default Remove;
