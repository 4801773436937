import React from "react";
import { IconProps } from "../Types";

const Spröjs = ({ className }: IconProps) => (
  <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1 1H25V25H1V1ZM0 1C0 0.447716 0.447715 0 1 0H25C25.5523 0 26 0.447715 26 1V25C26 25.5523 25.5523 26 25 26H1C0.447716 26 0 25.5523 0 25V1ZM14 3H23V12L14 12V3ZM13 2H14H23C23.5523 2 24 2.44772 24 3V12V13V23C24 23.5523 23.5523 24 23 24H14H13H3C2.44772 24 2 23.5523 2 23L2 13L2 12V3C2 2.44772 2.44772 2 3 2H13ZM14 23H23V13L14 13V23ZM13 13V23H3V13L13 13ZM13 12L3 12V3H13V12Z" fill="black" />
  </svg>
);

export default Spröjs;
