import React from "react";
import { IconProps } from "../Types";

const Clear = ({ className }: IconProps) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389953 7.20038 -0.17433 9.00998 0.172936 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89471 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.387 0 9 0ZM13.5 12.4312C13.6492 12.5804 13.733 12.7828 13.733 12.9937C13.733 13.2047 13.6492 13.4071 13.5 13.5562C13.3508 13.7054 13.1485 13.7892 12.9375 13.7892C12.7265 13.7892 12.5242 13.7054 12.375 13.5562L9 10.1812L5.625 13.5675C5.55113 13.6414 5.46344 13.7 5.36693 13.7399C5.27041 13.7799 5.16697 13.8005 5.0625 13.8005C4.95804 13.8005 4.85459 13.7799 4.75808 13.7399C4.66157 13.7 4.57387 13.6414 4.5 13.5675C4.42613 13.4936 4.36754 13.4059 4.32756 13.3094C4.28758 13.2129 4.26701 13.1095 4.26701 13.005C4.26701 12.9005 4.28758 12.7971 4.32756 12.7006C4.36754 12.6041 4.42613 12.5164 4.5 12.4425L7.875 9.045L4.40438 5.54625C4.25519 5.39706 4.17138 5.19473 4.17138 4.98375C4.17138 4.77277 4.25519 4.57043 4.40438 4.42125C4.55356 4.27206 4.7559 4.18825 4.96688 4.18825C5.17786 4.18825 5.38019 4.27206 5.52938 4.42125L9 7.93125L12.4706 4.46062C12.5445 4.38676 12.6322 4.32816 12.7287 4.28818C12.8252 4.24821 12.9287 4.22763 13.0331 4.22763C13.1376 4.22763 13.241 4.24821 13.3376 4.28818C13.4341 4.32816 13.5218 4.38676 13.5956 4.46062C13.6695 4.53449 13.7281 4.62219 13.7681 4.7187C13.808 4.81521 13.8286 4.91866 13.8286 5.02312C13.8286 5.12759 13.808 5.23103 13.7681 5.32755C13.7281 5.42406 13.6695 5.51176 13.5956 5.58562L10.125 9.045L13.5 12.4312Z"
      fill="#333333"
    />
  </svg>
);

export default Clear;
