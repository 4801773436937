export const hideMeshes = [
  "Top_Left",
  "Top_Right",
  "Bottom_Left",
  "Bottom_Center",
  "Bottom_Right",
  "Top_Left_Door",
  "Top_Right_Door",
  "Bottom_Left_Door",
  "Bottom_Right_Door",
  "BottomLRight",
  "01_null",
  "Rotator",
  "skeleton",
  "Top_Left_DoorL",
  "Top_Right_DoorL",
  "Top_Left_DoorR",
  "Bottom_Left_DoorR",
  "Bottom_Right_DoorR",
  "Bottom_Left_DoorL",
  "Bottom_Right_DoorL",
  "Top_Right_DoorR",
  "RotatorL",
  "RotatorR",
  "trim_and_plate01",
];

export const outsideMeshes = [
  "_primitive0",
  "_misc_list",
  "_misc_profile",
  "_misc_metal",
  "_frame_profile",
];

export const insideMeshes = [
  "_primitive1",
  "WFF_mullion_profile_ingen",
  "WFF_mullion_profile_parlan",
  "WFF_mullion_profile_svanhals",
];

export const handleMeshes = [
  "Handle_Base",
  "No_Handle_Base",
  "ASSA",
  "Door_Accessory",
  "Yale",
];

export const kickplateNodes = ["left", "right", "DoorL_Left", "DoorR_Right"];
export const windowsAccessoriesNodes = ["_misc", "_horizontal", "_vertical", "sp10", "sp20", "sp30", "sp11", "sp21", "sp12", "sp22", "sp31", "sp32", "sp01", "sp02"];
