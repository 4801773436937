import React, { useEffect, useRef, useState } from "react";
import { Button } from "../button/Button";
import { Typography } from "../typography/Typography";
import { MenuComponentProps } from "./type";
import { Tabs } from "../input/Tabs";
import classnames from "classnames";
import { Accordion } from "../accordion";
import { useNavigate, useParams } from "react-router";
import {
  ProductTypes,
  ExcludedFromSummaryComponents,
} from "src/services/enums";

function Menu({
  option,
  name,
  children,
  onClose,
  className,
  scrollIndex,
  dataContainer,
  onTabChange,
  isAccordionOpen,
  selectedModel,
}: MenuComponentProps): JSX.Element {
  const ref = useRef<HTMLInputElement | null>(null);
  const accordionRef = useRef<HTMLInputElement | null>(null);
  const [selectedVariations, setSelectedVariations] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { productType } = useParams();
  const navigate = useNavigate();
  const getSelectedVariations = () => {
    setSelectedVariations(
      dataContainer.getSelectedVariations(ExcludedFromSummaryComponents)
    );
  };

  const removeSelectedProduct = (id) => {
    dataContainer?.toggleVariationSelection(id);
  };

  dataContainer?.registerSelectionHook(() => {
    setShouldRefresh(!shouldRefresh);
  });

  useEffect(() => {
    if (scrollIndex) {
      const aRef = accordionRef.current;
      const index = scrollIndex.toString();
      const accordionElements = Array.from(aRef?.children || []);
      if (index && aRef && ref.current) {
        const top = accordionElements
          ?.find((accordion) => accordion.id === index)
          ?.getBoundingClientRect()?.top;
        ref.current.scrollTop = top ? top - 149 : 0;
      }
    }
  }, [scrollIndex]);
  useEffect(() => {
    if (dataContainer) {
      getSelectedVariations();
    }
  }, [dataContainer, shouldRefresh]);
  return (
    <div
      ref={ref}
      className={classnames(
        className,
        {"h-[60vh]": window.innerWidth <= 992},
        {"w-1/3": window.innerWidth > 992},
        "bg-white h-full w-full fixed right-0 lg:top-0 bottom-0 overflow-y-auto w-full sm:px-10 pb-9 z-10 transition-all duration-500"
      )}
    >
      <div className="sticky sm:px-0 px-7 top-0 z-50 bg-white py-[1.5rem] after:absolute after:bottom-0 after:block after:w-full after:shadow-sm after:border-b after:border-[rgba(222,222,222,0.1)]">
        <div className="flex flex-row w-full relative">
          <Tabs data={option} onTabClick={(e) => onTabChange(e)} />
          <Button
            icon="Close"
            className="absolute right-0 z-10 w-7 h-7"
            onClick={() => onClose()}
          />
        </div>
        <div className="w-full mt-[1rem] flex flex-col">
          <Typography
            tag="h4"
            className="text-black-faded text-xs leading-6 w-full font-normal"
          >
            {productType === "doors" ? "Ytterdörr" : "Fönster"}
          </Typography>
          <Typography
            tag="h4"
            className="sm:text-primary text-black text-xs sm:leading-4 leading-6 w-full font-normal"
          >
            {name}
          </Typography>
        </div>
      </div>
      <div
        ref={accordionRef}
        className={classnames(
          "w-full overflow-hidden grid sm:grid-cols-1 grid-cols-2",
          {
            "grid-cols-1": isAccordionOpen,
          }
        )}
      >
        {children}
      </div>
      <div className="w-full sm:px-0 px-7 pt-16 pb-2.5">
        <Accordion
          className="bg-smoke pt-5 px-7 pb-4 border-none"
          name={ProductTypes[productType || "doors"].name}
          subname={
            selectedModel
              ? dataContainer?.findVariationBy(selectedModel).key
              : ""
          }
          quantity={
            selectedVariations.length > 0 ? selectedVariations.length : ""
          }
          icon="Model"
          onChange={() => ({})}
        >
          {selectedVariations.map((product: { key: string; id: number }) => (
            <div
              key={product.key}
              className="flex items-center justify-between pb-3.5 mb-6 border-black border-b-[0.5px]"
            >
              <Typography tag="h3" className="font-normal">
                {product.key}
              </Typography>
              <Button
                icon="Clear"
                onClick={() => {
                  removeSelectedProduct(product.id);
                }}
              />
            </div>
          ))}
        </Accordion>
      </div>
      <div className="sm:px-0 px-7">
        <Button
          onClick={() => navigate("/summary", { state: window.location.href })}
          label={dataContainer?.sectionKey(
            "Translations",
            "configuration_btn_label"
          )}
          className="flex flex-row-reverse items-center px-5 py-7 w-full justify-center text-white bg-primary"
          iconClassName="sm:flex hidden ml-4"
          icon="Right"
        />
      </div>
    </div>
  );
}
export { Menu };
