import React from "react";
import { IconProps } from "../Types";

const Drawings = ({ className }: IconProps) => (
  <svg
    className={className}
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.07031 2.23103V1.07031H2.23103V2.14174H2.14174V2.23103H1.07031ZM6.87388 1.07031H4.55245V2.14174H6.87388V1.07031ZM9.19531 1.07031V2.14174H11.5167V1.07031H9.19531ZM13.8382 1.07031V2.14174H13.9275V2.23103H14.9989V1.07031H13.8382ZM14.9989 4.55245H13.9275V6.87388H14.9989V4.55245ZM14.9989 9.19531H13.9275V11.5167H14.9989V9.19531ZM14.9989 13.8382H13.9275V13.9275H13.8382V14.9989H14.9989V13.8382ZM11.5167 14.9989V13.9275H9.19531V14.9989H11.5167ZM6.87388 14.9989V13.9275H4.55246V14.9989H6.87388ZM2.23103 14.9989V13.9275H2.14174V13.8382H1.07031V14.9989H2.23103ZM1.07031 11.5167H2.14174V9.19531H1.07031V11.5167ZM1.07031 6.87388H2.14174V4.55246H1.07031V6.87388Z"
      fill="#8E9D99"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.21429 0H0V3.21429V11.7857H3.21429V3.21429L7.5 3.21429V0H3.21429Z"
      fill="#8E9D99"
    />
    <path d="M0 2.14453H1.07143V3.21596H0V2.14453Z" fill="white" />
    <path d="M2.14453 0H3.21596V1.07143H2.14453V0Z" fill="white" />
    <path d="M4.28516 0H5.35658V1.07143H4.28516V0Z" fill="white" />
    <path d="M0 4.28516H1.07143V5.35658H0V4.28516Z" fill="white" />
    <path d="M0 6.42969H1.07143V7.50112H0V6.42969Z" fill="white" />
    <path d="M0 8.57031H1.07143V9.64174H0V8.57031Z" fill="white" />
  </svg>
);

export default Drawings;
