/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import classnames from "classnames";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button } from "../button/Button";
import { Header } from "../header/Header";
import { AddCategory } from "../configurator/AddCategory";
import { Menu } from "../menu";
import SubAccordion from "../subAccordions/SubAccordion";
import { Accordion } from "../accordion";
import { GlassModal } from "../glassModal/GlassModal";
import { Modal } from "../modal/Modal";
import { Footer } from "../footer";
import { Logo } from "../logo/Logo";
import {
  AccordionEnum,
  CategoryIds,
  configuratorKeys,
  ProductTypes,
} from "../../services/enums";
import { IconName } from "../icon/Types";
import { useParams } from "react-router";
import { DoorSliders } from "../sliderGroups/DoorSliders";
import { useNavigate } from "react-router";
import { generateUID } from "src/services/Helpers";
import { ProductModalComponent } from "../productModalComponent/ProductModalComponent";
import { BabylonContext } from "../../common/context/BabylonProvider";
import AirDividerSliders from "../sliderGroups/AirDividerSliders";
import useCheckMobileScreen from "../../hooks/CheckMobileScreen";
import { ContactModal } from "../contactModal/ContactModal";

const SingleComponents = (props) => {
  const {
    setSelectedModel,
    dataReady,
    selectedModel,
    dataContainer,
    setSelectionAccordion,
    selectionAccordion,
  } = props;
  const [sliderValues, setSliderValues] = useState({
    vertical: [500, 700, 900, 1100],
    horizontal: [200, 400, 600, 800],
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);
  const [scrollIndex, setScrollIndex] = useState("");
  const [accordion, setAccordion] = useState(false);
  const [iconKey, setIconKey] = useState<string | null>(null);
  const [accordionKey, setAccordionKey] = useState<number | null>();
  const [slider, setSlider] = useState(true);
  const { category, singleID, productType } = useParams();
  const navigate = useNavigate();
  const [modalData, setModalData] = useState({
    isOpen: false,
    image: { src: "", title: "" },
    productId: "",
  });
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const modelsAccordions = dataContainer?.getModelAccordions(
    CategoryIds[productType || "doors"][category || "single"].id
  );
  const { state, dispatch } = useContext<any>(BabylonContext);
  const otherAccordions = dataContainer
    ?.getAllComponents(productType, category)
    .filter(
      (item) =>
        item.name !== "" &&
        !item.name.includes("Types") &&
        !item.name.includes("Inside")
    );
  const singleAccordions = dataContainer?.getSingleAccordions(singleID);
  const allAcordions: any[] = [];
  const accordionItems = dataContainer?.getSortedComponents(
    allAcordions.concat(modelsAccordions, singleAccordions, otherAccordions),
    productType
  );
  const isMobile = useCheckMobileScreen();
  sessionStorage.setItem("colorVariant", "utsida");

  const changeCategories = (e) => {
    const fallbackVariant = e === "doors" ? "single" : "side";

    navigate(`/models/${e}/${fallbackVariant}?redirect=true`);
    return;
  };

  const configuratorModelClickHandler = (idx, modelId) => {
    if (!productType) return;
    const model = dataContainer?.findVariationBy(Number(modelId));
    if (!model) return;
    const productTypeCategories = CategoryIds[productType];
    const productCategory = Object.keys(productTypeCategories).find(
      (c) => productTypeCategories[c].id === model.product_category_id
    );
    dataContainer?.switchConfiguratorModel(idx, false, productType);
    navigate(`/${productType}/${productCategory}/${modelId}`, {
      replace: true,
    });
  };

  const configuratorModelRemoveHandler = (idx) => {
    const modelToShow = dataContainer?.removeModelFromConfiguration(
      idx,
      productType
    );
    navigate(`/${productType}/${modelToShow?.category}/${modelToShow?.model}`, {
      replace: true,
    });
  };

  const selectedModels = (productType, name) =>
    dataContainer?.getConfigurationModels(productType).map((model, idx) => {
      return {
        id: generateUID(10),
        name: `${name} ${idx + 1}`,
        type: ProductTypes[productType || "doors"].name,
        model: model.key,
        description: dataContainer?.extractModelDescription(
          dataContainer?.getConfigurationVariations(idx, productType),
          idx
        ),
        image: { src: model.assets[0].preview_image_url, title: "Door model" },
        onClick: () => configuratorModelClickHandler(idx, model.id),
        onRemoveClick: () => configuratorModelRemoveHandler(idx),
      };
    });

  const generateARLink = () => {
    if(!dataContainer || !dataContainer.mSelectedVariations){
      return;
    }

    return dataContainer?.generateShareableLink(undefined, true);
  };

  const getMenuItems = useCallback(() => {
    const items = accordionItems?.map(
      (item: {
        id: string;
        name: IconName;
        onClick: React.Dispatch<React.SetStateAction<string | null>>;
      }) => {
        return {
          id: item.id,
          icon:
            productType === "windows" && item.name === AccordionEnum.Model
              ? "Window"
              : (item.name.replace(" ", "") as IconName),
          onClick: setIconKey,
        };
      }
    );

    items.push({ id: "300", icon: "OpenMenu", onClick: setIconKey });

    return items;
  }, [accordionItems, productType]);

  const closeMenuHandler = () => {
    setMenuOpen(false);
    dispatch({ type: "SET_MENU_OPEN", payload: false });
    setIconKey(null);
    setSelectionAccordion("");
    setScrollIndex("");
    setAccordionKey(null);
    setSlider(true);
    setModalData({
      isOpen: false,
      image: { src: "", title: "" },
      productId: "",
    });
  };

  useEffect(() => {
    if (iconKey) {
      setMenuOpen(true);
      dispatch({ type: "SET_MENU_OPEN", payload: !state.menuOpen });
      setScrollIndex(iconKey);
    }
  }, [iconKey]);

  useEffect(() => {
    if (state.menuOpen && selectionAccordion !== AccordionEnum.Size) {
      setSlider(false);
    }
  }, [state.menuOpen, selectionAccordion]);

  useEffect(() => {
    if (selectionAccordion && selectionAccordion !== AccordionEnum.Size) {
      setSlider(false);
    }
  }, [selectionAccordion]);

  return (
    <Fragment>
      <Header
        secondary={true}
        className={classnames(
          {
            "opacity-100 visible": !menuOpen || !isModalOpen,
          },
          { "opacity-0 invisible": menuOpen },
          "absolute w-full z-[20] bg-transparent transition-all duration-200"
        )}
        toggleModal={() => setIsModalOpen(!isModalOpen)}
        toggleInfo={() => setIsProductModalOpen(!isProductModalOpen)}
        dataContainer={dataContainer}
      />
      <Logo
        to="/"
        className="sm:hidden flex absolute top-6 left-1/2 -translate-x-1/2"
      />
      <ProductModalComponent
        dataContainer={dataContainer}
        isModalOpen={isProductModalOpen}
        setIsModalOpen={setIsProductModalOpen}
        productType={productType || "doors"}
      />
      <div
        className={classnames(
          { "opacity-0 invisible": menuOpen || !slider || state.isDragged },
          { "opacity-100 visible": !menuOpen && slider && !state.isDragged }
        )}
      >
        {!isMobile && (
          <div
            className={classnames({
              "opacity-0 invisible": productType === "windows",
            })}
          >
            <AddCategory
              title={productType === "doors" ? "+ Fönster" : "+ Dörr"}
              icon={productType === "doors" ? "AddWindow" : "AddDoor"}
              onClick={() => {
                navigate(
                  `/models/${
                    productType === "doors" ? "windows/side" : "doors/single"
                  }`
                );
              }}
            />
          </div>
        )}
      </div>
      <div className="sm:flex absolute sm:h-full sm:w-0 w-full bottom-[5.8rem] sm:bottom-0 sm:justify-end justify-center right-0 flex items-center transition-all duration-300 z-10">
        <Button
          icon={window.innerWidth < 768 ? "MenuArrow" : "LeftLarge"}
          iconClassName="sm:w-10 sm:h-10"
          onClick={() => {
            setMenuOpen(!menuOpen);
            dispatch({ type: "SET_MENU_OPEN", payload: !state.menuOpen });
          }}
        />
      </div>
      <Menu
        className={classnames(
          {
            "sm:right-0 overflow-x-hidden bottom-0 opacity-100 visible":
              menuOpen,
          },
          { "sm:-right-full bottom-0 invisible opacity-0": !menuOpen }
        )}
        option={[
          { name: "Dörr", value: "doors" },
          { name: "Fönster", value: "windows" },
        ]}
        name={
          selectedModel ? dataContainer?.findVariationBy(selectedModel).key : ""
        }
        selectedModel={selectedModel}
        onClose={closeMenuHandler}
        scrollIndex={scrollIndex}
        onTabChange={(e) => changeCategories(e)}
        dataContainer={dataContainer}
        isAccordionOpen={selectionAccordion !== ""}
      >
        {modelsAccordions !== undefined &&
          accordionItems?.map(
            (accordion) =>
              accordion.name && (
                <Accordion
                  id={accordion.id}
                  key={accordion.id}
                  icon={accordion.name.split(" ").join("")}
                  name={dataContainer?.sectionKey(
                    "Translations",
                    accordion.name.toLowerCase().split(" ").join("_")
                  )}
                  isOpen={
                    accordion.id === accordionKey || accordion.id === iconKey
                  }
                  isHidden={
                    isMobile
                      ? selectionAccordion
                        ? selectionAccordion !== accordion.name
                        : false
                      : false
                  }
                  onChange={(e) => {
                    setAccordionKey(accordion.id);
                    setAccordion(e);
                    accordion && setIconKey(null);
                    setSlider(accordion.name === AccordionEnum.Size);
                    setSelectionAccordion(
                      e && accordion.name ? accordion.name : ""
                    );
                  }}
                >
                  <SubAccordion
                    filter={accordion?.filter}
                    actionTitle={accordion?.actionsTitle}
                    id={accordion.id}
                    variant={dataContainer.findVariationType(
                      accordion.name.split(" ").join("")
                    )}
                    items={accordion.available_variations}
                    options={accordion.available_variations?.options}
                    label={accordion.name}
                    dimensions={accordion.available_variations?.dimensions}
                    dataContainer={dataContainer}
                    imageModal={{ modalData, setModalData }}
                    setSelectedModel={setSelectedModel}
                    setSliderValues={setSliderValues}
                    accordionOpen={selectionAccordion}
                  />
                </Accordion>
              )
          )}
      </Menu>
      {modalData.isOpen && (
        <GlassModal
          image={modalData.image}
          close={() =>
            setModalData({ ...modalData, isOpen: false, productId: "" })
          }
        />
      )}
      <ContactModal
        dataContainer={dataContainer}
        isOpen={isContactModalOpen}
        setIsOpen={setIsContactModalOpen}
      />
      <div
        className={classnames(
          {
            "opacity-100 visible absolute w-full h-full": slider && dataReady,
          },
          { "opacity-0 invisible": !slider || !menuOpen },
          "sm:flex hidden"
        )}
      >
        <DoorSliders
          dataContainer={dataContainer}
          sliderValues={sliderValues}
          selectedModel={selectedModel}
        />
      </div>
      <AirDividerSliders
        isVisible={selectionAccordion === AccordionEnum.Luft_Types}
        dataContainer={dataContainer}
        sliderValues={sliderValues}
        selectedModel={selectedModel}
      />
      <Modal
        isOpen={isModalOpen}
        close={() => {
          setIsModalOpen(false);
        }}
        className="absolute h-full flex items-center transition-all duration-300"
        text={
          <p className="pl-5">
            {dataContainer?.sectionKey("Translations", "modal_text")}
          </p>
        }
        QRLink={generateARLink()}
      />
      {dataReady && (
        <>
          {!menuOpen && !isContactModalOpen && (
            <div className="absolute p-6  bg-opacity-100 bottom-[6rem] flex justify-center items-end z-[15] h-max">
              <Button
                icon="QuestionMark"
                secondary
                textClassName="sm:ml-3.5 sm:flex hidden text-black-faded transition-all duration-200"
                label="Fråga oss"
                onClick={() => setIsContactModalOpen(!isContactModalOpen)}
              />
            </div>
          )}
          <Footer
            className={classnames(
              { "opacity-0 invisible": menuOpen },
              { "opacity-100 visible": !menuOpen },
              "transition-all duration-200 z-[10]"
            )}
            door={{
              isActive: productType === "doors",
              items: selectedModels("doors", "Dörr"),
              title: "Dörr",
              icon: "Model",
              showAll: {
                URL: "/models/doors/single?add=true",
                text: dataContainer?.sectionKey(
                  configuratorKeys.doorsDescription,
                  "new_door_description"
                ),
              },
            }}
            menuItems={getMenuItems()}
            setAccordionSelected={setSelectionAccordion}
            window={{
              isActive: productType === "windows",
              items: selectedModels("windows", "Fönster"),
              icon: "Window",
              title: "Fönster",
              showAll: {
                URL: "/models/windows/side?add=true",
                text: dataContainer?.sectionKey(
                  configuratorKeys.windowsDescription,
                  "new_window_description"
                ),
              },
            }}
            product={{
              name: ProductTypes[productType || "doors"].name,
              model:
                selectedModel &&
                dataContainer?.findVariationBy(selectedModel).key,
            }}
          />
        </>
      )}
      <Logo
        className={classnames(
          {
            "visible opacity-100 absolute left-10 bottom-5":
              accordion || iconKey,
          },
          { hidden: !accordion && !iconKey }
        )}
        to="/"
      />
    </Fragment>
  );
};

export { SingleComponents };
