import React from "react";
import { ColorsGridProps } from "./types";
import classnames from "classnames";

export const ColorsGrid = ({
  applyBottomBorder,
  children,
  className,
}: ColorsGridProps) => {
  return (
    <div
      className={classnames(
        "py-[1.875rem] grid-cols-[repeat(6,_minmax(0,_2.625rem))] md:grid-cols-[repeat(7,_minmax(0,3.5rem))] auto-cols-min sm:gap-1 gap-2.5",
        {
          "border-b border-white-off": applyBottomBorder,
        },
        className,
        innerWidth < 1100 ? "flex flex-wrap justify-center" : "grid"
      )}
    >
      {children}
    </div>
  );
};
