import {
  AbstractAssetTask,
  Mesh,
  MeshAssetTask,
  TransformNode,
  Vector3,
} from "@babylonjs/core";
import { AccordionEnum } from "src/services/enums";
import { getMiscNodes } from "./AirDividersHandlers";

const HandleTypes = {
  handle: {
    Handle_Null: "handle_null",
    Handle_L_Null: "handle_L_null",
    Handle_R_Null: "handle_R_null",
  },
  lock: {
    Handle_L_Null: "window_lock_center_L_null",
    Handle_R_Null: "window_lock_center_R_null",
  },
} as const;

const WindowNodes = {
  Skeleton: "skeleton_frame",
  Bone_Top_Left: "bone_frame_top_left",
  Bone_Top_Right: "bone_frame_top_right",
  Bone_Bottom_Left: "bone_frame_bottom_left",
  Bone_Bottom_Right: "bone_frame_bottom_right",
} as const;

export const beslagPosition = {
  "1a": { visibleHandles: ["right"] },
  "1b": { visibleHandles: ["left"] },
  "1_1a": { visibleHandles: ["rightb", "right"] },
  "1_1b": { visibleHandles: ["leftb", "left"] },
  "2": { visibleHandles: ["right", "left"] },
  "2_2": { visibleHandles: ["left", "right", "leftb", "rightb"] },
  "3a": { visibleHandles: ["right", "left", "center"] },
  "3b": { visibleHandles: ["right", "left", "center"] },
  "3_3a": {
    visibleHandles: ["right", "left", "center", "rightb", "leftb", "centerb"],
  },
  "3_3b": {
    visibleHandles: ["right", "left", "center", "rightb", "leftb", "centerb"],
  },
};

const beslagMeshes: Mesh[] = [];

export const saveVariantDataIntoBeslagMeshes = (tasks: AbstractAssetTask[]) => {
  const meshTasks = tasks as MeshAssetTask[];

  meshTasks.forEach((task) => {
    if (!task.name.endsWith(AccordionEnum.Beslag)) {
      return;
    }
    const variantName = task.name?.split(" - ")?.[1];
    const variantNameComponents = variantName.split("_");
    const material = variantNameComponents.at(-1);
    const side = variantNameComponents.at(-2);
    const beslagName = variantNameComponents.slice(0, -2);

    task.loadedMeshes?.map((abstractMesh) => {
      const mesh = abstractMesh as Mesh;
      if (!mesh.metadata) {
        mesh.metadata = {};
      }
      mesh.metadata.material = material;
      mesh.metadata.side = side;
      mesh.metadata.beslagName = beslagName.join("_");
      beslagMeshes.push(mesh);
    });
  });
};

const hideAllMeshes = () => {
  beslagMeshes.forEach((mesh) => (mesh.isVisible = false));
};

const showMeshes = (beslagVariation, luftType) => {
  const visibleBeslags = beslagPosition[luftType]?.visibleHandles || [];
  const beslagVariationNameParts = beslagVariation?.split("_");
  const beslagName = `${beslagVariationNameParts[0]}_${beslagVariationNameParts[1]}`;
  const material = beslagVariationNameParts.at(-1);

  visibleBeslags.forEach((beslagPosition) => {
    beslagMeshes.forEach((mesh) => {
      if (
        mesh?.metadata?.side === beslagPosition &&
        mesh.metadata?.material === material &&
        mesh.metadata?.beslagName?.startsWith(beslagName)
      ) {
        mesh.isVisible = true;
      }
    });
  });
};

export const getWindowMiscs = (scene) => {
  const firstMisc = getMiscNodes("misc0", scene);
  const secondMisc = getMiscNodes("misc1", scene);
  const thirdMisc = getMiscNodes("misc2", scene);
  const fourthMisc = getMiscNodes("misc3", scene);
  const fifthMisc = getMiscNodes("misc4", scene);
  const sixthMisc = getMiscNodes("misc5", scene);

  return { firstMisc, secondMisc, thirdMisc, fourthMisc, fifthMisc, sixthMisc };
};

export const getDoorWindowMisc = (scene) => {
  const firstMiscSkeleton: TransformNode = scene
    ?.getTransformNodesById(WindowNodes.Skeleton)
    ?.find((node) => {
      return node.parent?.isVisible;
    });

  if (!firstMiscSkeleton) {
    return;
  }

  const skeletonChildren = firstMiscSkeleton.getChildren();

  if (!skeletonChildren.length) {
    return;
  }

  let topLeftMisc, topRightMisc, bottomLeftMisc, bottomRightMisc;

  skeletonChildren.forEach((node) => {
    if (node.name === WindowNodes.Bone_Top_Left) {
      topLeftMisc = node as TransformNode;
      return;
    }

    if (node.name === WindowNodes.Bone_Top_Right) {
      topRightMisc = node as TransformNode;
      return;
    }

    if (node.name === WindowNodes.Bone_Bottom_Left) {
      bottomLeftMisc = node as TransformNode;
      return;
    }

    if (node.name === WindowNodes.Bone_Bottom_Right) {
      bottomRightMisc = node as TransformNode;
    }
  });

  return {
    firstMisc: { topLeftMisc, topRightMisc, bottomLeftMisc, bottomRightMisc },
  };
};

export const handleBeslagPosition = (scene, dataContainer) => {
  hideAllMeshes();

  const handleVariation = dataContainer?.findSelectedVariantByComponentName(
    AccordionEnum.Beslag
  )?.original_key;


  if (!handleVariation) {
    return;
  }

  const handleType = handleVariation.includes("fonsterlas") ? "lock" : "handle";

  const miscNodes = getWindowMiscs(scene)?.firstMisc
    ? getWindowMiscs(scene)
    : getDoorWindowMisc(scene);
  const luftTypeVariation = dataContainer?.findSelectedVariantByComponentName(
    AccordionEnum.Luft_Types
  );
  const luftType = luftTypeVariation ? luftTypeVariation.original_key : "1a";

  showMeshes(handleVariation, luftType);
  handlePosition(miscNodes, luftType, handleType);
};

export const getBeslagNull = (scene, dataContainer) => {
  const luftTypeVariation = dataContainer?.findSelectedVariantByComponentName(
    AccordionEnum.Luft_Types
  );
  const luftType = luftTypeVariation ? luftTypeVariation.original_key : "1a";

  const beslagNull: TransformNode = scene
    .getTransformNodesById(`handle_${beslagPosition[luftType]?.side}_null`)
    .find(
      (m) =>
        m.parent?.parent?.parent?.isVisible &&
        m.metadata?.task?.endsWith(beslagPosition[luftType]?.window)
    );

  return beslagNull;
};

const handlePosition = (miscNodes, luftType, handleType) => {
  switch (luftType) {
  case "1a":
  case "1b":
    handleSingleMiscWindows(miscNodes, luftType, handleType);
    break;
  case "2":
    handleDoubleMiscWindows(miscNodes, handleType);
    break;
  case "2_2":
    handleFourMiscWindows(miscNodes, handleType);
    break;
  case "3a":
  case "3b":
    handleTripleMiscWindows(miscNodes, luftType, handleType);
    break;
  case "3_3a":
  case "3_3b":
    handleSixMiscWindows(miscNodes, luftType, handleType);
    break;
  case "1_1a":
  case "1_1b":
    handleDoubleMiscVerticalWindows(miscNodes, luftType, handleType);
    break;
  default:
    throw new Error("Wrong luft type");
  }
};

const setBeslagParent = (beslag, handleNull, { topMisc, bottomMisc }) => {
  if (!beslag || !handleNull || !topMisc || !bottomMisc) {
    return;
  }
  const beslagParent = beslag.parent as Mesh;
  beslagParent.setParent(handleNull);
  beslagParent.position = new Vector3(0, 0, 0);
  const handleParent = handleNull?.parent as TransformNode;
  beslagParent.rotation = new Vector3(
    handleNull?.rotation?.x,
    handleNull?.rotation?.y,
    handleNull?.rotation?.z
  );

  const doubleDoorWindowNullName = "center_null";

  if (handleParent && handleParent?.name !== doubleDoorWindowNullName) {
    handleParent.position.x = topMisc?.position?.x;
    handleParent.position.y =
      (topMisc?.position?.y + bottomMisc?.position?.y) / 2;
  }
};

const getBeslagComponents = (side, handleNullSide, miscName, miscNodes) => {
  let beslag = beslagMeshes.find((mesh) => {
    return (
      mesh.isVisible &&
      (mesh.parent?.name.includes("root") ||
        mesh?.parent?.parent?.name.includes("root")) &&
      mesh?.metadata.side === side
    );
  });

  if (beslag?.name?.includes("window_lock")) {
    beslag = beslag.parent as Mesh;
  }

  const misc = miscNodes[miscName];
  const skeletonFrameMisc: TransformNode = misc?.topLeftMisc?.parent;

  const handleNull = skeletonFrameMisc
    ?.getDescendants()
    ?.find((node) => node.name?.includes(handleNullSide)) as TransformNode;

  return { beslag, misc, handleNull };
};

const handleSingleMiscWindows = (miscNodes, luftType, handleType) => {
  let beslag = beslagMeshes.find((mesh) => {
    return (
      (mesh.isVisible && mesh.parent?.name.includes("root")) ||
      (mesh.isVisible && mesh.parent?.parent?.name.includes("root"))
    );
  });

  if (beslag && handleType === "lock") {
    beslag = beslag?.parent as Mesh;
  }

  const misc = miscNodes?.firstMisc;
  const skeletonFrameMisc: TransformNode = misc?.topLeftMisc?.parent;
  let handleNull: TransformNode;

  if (!skeletonFrameMisc || !beslag) {
    return;
  }

  if (luftType === "1a") {
    handleNull = skeletonFrameMisc
      .getDescendants()
      ?.find((node) =>
        node.name?.includes(HandleTypes[handleType].Handle_R_Null)
      ) as TransformNode;

    if (!handleNull) {
      handleNull = skeletonFrameMisc
        .getDescendants()
        ?.find(
          (node) =>
            node.name?.includes(HandleTypes[handleType].Handle_R_Null) ||
            node.name?.includes(HandleTypes["handle"].Handle_Null)
        ) as TransformNode;
    }
  } else {
    handleNull = skeletonFrameMisc
      .getDescendants()
      ?.find((node) =>
        node.name?.includes(HandleTypes[handleType].Handle_L_Null)
      ) as TransformNode;
  }

  if (!handleNull) {
    return;
  }

  setBeslagParent(beslag, handleNull, {
    topMisc:
      luftType === "1a"
        ? handleNull.name.includes("R")
          ? misc?.topRightMisc
          : misc?.topRightMisc
        : misc?.topLeftMisc,
    bottomMisc:
      luftType === "1a"
        ? handleNull.name.includes("R")
          ? misc?.bottomRightMisc
          : misc?.bottomRightMisc
        : misc?.bottomLeftMisc,
  });
  return;
};

const handleDoubleMiscWindows = (miscNodes, handleType) => {
  const {
    beslag: firstBeslag,
    misc: firstMisc,
    handleNull: firstHandleNull,
  } = getBeslagComponents(
    "right",
    HandleTypes[handleType].Handle_R_Null,
    "firstMisc",
    miscNodes
  );

  const {
    beslag: secondBeslag,
    misc: secondMisc,
    handleNull: secondHandleNull,
  } = getBeslagComponents(
    "left",
    HandleTypes[handleType].Handle_L_Null,
    "secondMisc",
    miscNodes
  );

  setBeslagParent(firstBeslag, firstHandleNull, {
    topMisc: firstMisc?.topRightMisc,
    bottomMisc: firstMisc?.bottomRightMisc,
  });

  setBeslagParent(secondBeslag, secondHandleNull, {
    topMisc: secondMisc?.topLeftMisc,
    bottomMisc: secondMisc?.bottomLeftMisc,
  });
};

const handleFourMiscWindows = (miscNodes, handleType) => {
  const {
    beslag: firstBeslag,
    misc: firstMisc,
    handleNull: firstHandleNull,
  } = getBeslagComponents(
    "right",
    HandleTypes[handleType].Handle_R_Null,
    "firstMisc",
    miscNodes
  );

  const {
    beslag: secondBeslag,
    misc: secondMisc,
    handleNull: secondHandleNull,
  } = getBeslagComponents(
    "left",
    HandleTypes[handleType].Handle_L_Null,
    "secondMisc",
    miscNodes
  );

  const {
    beslag: thirdBeslag,
    misc: thirdMisc,
    handleNull: thirdHandleNull,
  } = getBeslagComponents(
    "rightb",
    HandleTypes[handleType].Handle_R_Null,
    "thirdMisc",
    miscNodes
  );

  const {
    beslag: fourthBeslag,
    misc: fourthMisc,
    handleNull: fourthHandleNull,
  } = getBeslagComponents(
    "leftb",
    HandleTypes["handle"].Handle_L_Null,
    "fourthMisc",
    miscNodes
  );

  setBeslagParent(firstBeslag, firstHandleNull, {
    topMisc: firstMisc?.topRightMisc,
    bottomMisc: firstMisc?.bottomRightMisc,
  });

  setBeslagParent(secondBeslag, secondHandleNull, {
    topMisc: secondMisc?.topLeftMisc,
    bottomMisc: secondMisc?.bottomLeftMisc,
  });

  setBeslagParent(thirdBeslag, thirdHandleNull, {
    topMisc: thirdMisc?.topRightMisc,
    bottomMisc: thirdMisc?.bottomRightMisc,
  });

  setBeslagParent(fourthBeslag, fourthHandleNull, {
    topMisc: fourthMisc?.topLeftMisc,
    bottomMisc: fourthMisc?.bottomLeftMisc,
  });
};

const handleDoubleMiscVerticalWindows = (miscNodes, luftType, handleType) => {
  const position = luftType === "1_1a" ? "right" : "left";
  const nullSide = luftType === "1_1a" ? "R" : "L";
  const miscSide = luftType === "1_1a" ? "Right" : "Left";

  const {
    beslag: firstBeslag,
    misc: firstMisc,
    handleNull: firstHandleNull,
  } = getBeslagComponents(
    position,
    HandleTypes[handleType][`Handle_${nullSide}_Null`],
    "firstMisc",
    miscNodes
  );

  const {
    beslag: secondBeslag,
    misc: secondMisc,
    handleNull: secondHandleNull,
  } = getBeslagComponents(
    `${position}b`,
    HandleTypes[handleType][`Handle_${nullSide}_Null`],
    "secondMisc",
    miscNodes
  );

  setBeslagParent(firstBeslag, firstHandleNull, {
    topMisc: firstMisc[`top${miscSide}Misc`],
    bottomMisc: firstMisc[`bottom${miscSide}Misc`],
  });

  setBeslagParent(secondBeslag, secondHandleNull, {
    topMisc: secondMisc[`top${miscSide}Misc`],
    bottomMisc: secondMisc[`bottom${miscSide}Misc`],
  });
};

const handleTripleMiscWindows = (miscNodes, luftType, handleType) => {
  const middleMiscSide = luftType === "3a" ? "left" : "right";

  const {
    beslag: firstBeslag,
    misc: firstMisc,
    handleNull: firstHandleNull,
  } = getBeslagComponents(
    "right",
    HandleTypes[handleType].Handle_R_Null,
    "firstMisc",
    miscNodes
  );

  const {
    beslag: secondBeslag,
    misc: secondMisc,
    handleNull: secondHandleNull,
  } = getBeslagComponents(
    "center",
    HandleTypes[handleType][
      middleMiscSide === "right" ? "Handle_L_Null" : "Handle_R_Null"
    ],
    "secondMisc",
    miscNodes
  );

  const {
    beslag: thirdBeslag,
    misc: thirdMisc,
    handleNull: thirdHandleNull,
  } = getBeslagComponents(
    "left",
    HandleTypes[handleType].Handle_L_Null,
    "thirdMisc",
    miscNodes
  );
  setBeslagParent(firstBeslag, firstHandleNull, {
    topMisc: firstMisc?.topRightMisc,
    bottomMisc: firstMisc?.bottomRightMisc,
  });

  setBeslagParent(secondBeslag, secondHandleNull, {
    topMisc:
      middleMiscSide === "right"
        ? secondMisc?.topLeftMisc
        : secondMisc?.topRightMisc,
    bottomMisc:
      middleMiscSide === "right"
        ? secondMisc?.bottomLeftMisc
        : secondMisc?.bottomRightMisc,
  });

  setBeslagParent(thirdBeslag, thirdHandleNull, {
    topMisc: thirdMisc?.topLeftMisc,
    bottomMisc: thirdMisc?.bottomLeftMisc,
  });
};

const handleSixMiscWindows = (miscNodes, luftType, handleType) => {
  const middleMiscSide = luftType === "3_3a" ? "left" : "right";

  const {
    beslag: firstBeslag,
    misc: firstMisc,
    handleNull: firstHandleNull,
  } = getBeslagComponents(
    "right",
    HandleTypes[handleType].Handle_R_Null,
    "firstMisc",
    miscNodes
  );

  const {
    beslag: secondBeslag,
    misc: secondMisc,
    handleNull: secondHandleNull,
  } = getBeslagComponents(
    "center",
    HandleTypes[handleType][
      middleMiscSide === "right" ? "Handle_L_Null" : "Handle_R_Null"
    ],
    "secondMisc",
    miscNodes
  );

  const {
    beslag: thirdBeslag,
    misc: thirdMisc,
    handleNull: thirdHandleNull,
  } = getBeslagComponents(
    "left",
    HandleTypes[handleType].Handle_L_Null,
    "thirdMisc",
    miscNodes
  );

  const {
    beslag: fourthBeslag,
    misc: fourthMisc,
    handleNull: fourthHandleNull,
  } = getBeslagComponents(
    "rightb",
    HandleTypes[handleType].Handle_R_Null,
    "fourthMisc",
    miscNodes
  );

  const {
    beslag: fifthBeslag,
    misc: fifthMisc,
    handleNull: fifthHandleNull,
  } = getBeslagComponents(
    "centerb",
    HandleTypes[handleType][
      middleMiscSide === "right" ? "Handle_L_Null" : "Handle_R_Null"
    ],
    "fifthMisc",
    miscNodes
  );

  const {
    beslag: sixthBeslag,
    misc: sixthMisc,
    handleNull: sixthHandleNull,
  } = getBeslagComponents(
    "leftb",
    HandleTypes[handleType].Handle_L_Null,
    "sixthMisc",
    miscNodes
  );

  setBeslagParent(firstBeslag, firstHandleNull, {
    topMisc: firstMisc?.topRightMisc,
    bottomMisc: firstMisc?.bottomRightMisc,
  });

  setBeslagParent(secondBeslag, secondHandleNull, {
    topMisc:
      middleMiscSide === "right"
        ? secondMisc?.topLeftMisc
        : secondMisc?.topRightMisc,
    bottomMisc:
      middleMiscSide === "right"
        ? secondMisc?.bottomLeftMisc
        : secondMisc?.bottomRightMisc,
  });

  setBeslagParent(thirdBeslag, thirdHandleNull, {
    topMisc: thirdMisc?.topLeftMisc,
    bottomMisc: thirdMisc?.bottomLeftMisc,
  });

  setBeslagParent(fourthBeslag, fourthHandleNull, {
    topMisc: fourthMisc?.topRightMisc,
    bottomMisc: fourthMisc?.bottomRightMisc,
  });

  setBeslagParent(fifthBeslag, fifthHandleNull, {
    topMisc:
      middleMiscSide === "right"
        ? fifthMisc?.topLeftMisc
        : fifthMisc?.topRightMisc,
    bottomMisc:
      middleMiscSide === "right"
        ? fifthMisc?.bottomLeftMisc
        : fifthMisc?.bottomRightMisc,
  });

  setBeslagParent(sixthBeslag, sixthHandleNull, {
    topMisc: sixthMisc?.topLeftMisc,
    bottomMisc: sixthMisc?.bottomLeftMisc,
  });
};
