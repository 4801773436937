import React from "react";
import { IconProps } from "../Types";

const AddDoor = ({ className }: IconProps) => (
  <svg
    className={className}
    width="15"
    height="20"
    viewBox="0 0 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="13.3182" height="18.0909" stroke="white" />
  </svg>
);

export default AddDoor;
