import React, { useState, useContext } from "react";
import { ImageRadioInput } from "src/components/input";
import { AirDividersProps } from "./types";
import {
  firstClassTypes,
  secondClassTypes,
  thirdClassTypes,
  fourthClassTypes,
  fifthClassTypes,
  sixthClassTypes,
} from "./constants";
import { ItemProps } from "../types";
import { generateUID } from "src/services/Helpers";
import { useParams } from "react-router";
import { BabylonContext } from "../../../common/context/BabylonProvider";

export const MinimumDimensionsRequirements = {
  "2": [1000, 500],
  "2_2": [1000, 1000],
  "1a": [500, 500],
  "1b": [500, 500],
  "3a": [1500, 500],
  "3b": [1500, 500],
  "1_1a": [500, 1000],
  "1_1b": [500, 1000],
  "3_3a": [1500, 1000],
  "3_3b": [1500, 1000],
};

export const MaximumDimensionsRequirements = {
  "2": [2000, 1800],
  "2_2": [2000, 3000],
  "1a": [1000, 1800],
  "1b": [1000, 1800],
  "3a": [2900, 1800],
  "3b": [2900, 1800],
  "1_1a": [1000, 3000],
  "1_1b": [1000, 3000],
  "3_3a": [2900, 3000],
  "3_3b": [2900, 3000],
};

export const DefaultDimensions = {
  "1a": [900, 1200],
  "1b": [900, 1200],
  "2": [1200, 1400],
  "3a": [1800, 1800],
  "3b": [1800, 1800],
  "1_1a": [900, 1200],
  "1_1b": [900, 1200],
  "2_2": [1300, 1800],
  "3_3a": [1800, 2000],
  "3_3b": [1800, 2000],
};

export const DefaultDimensionsFirst = ["1a", "1b", "1_1a", "1_1b"];

export const AirDividers = ({ dataContainer, items }: AirDividersProps) => {
  const { productType } = useParams();
  const [shouldRefresh, setShouldRefresh] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { dispatch } = useContext<any>(BabylonContext);

  dataContainer?.registerSelectionHook(() => {
    setShouldRefresh(!shouldRefresh);
  });

  const handleSelect = (item: ItemProps) => {
    if (!item.original_key) {
      return;
    }

    const currentModel = dataContainer?.getCurrentModel();

    const [minWidth, minHeight] = DefaultDimensions[item.original_key];

    const dimensionsVariation = dataContainer?.findModelDimensionsVariation(
      currentModel,
      {
        width: minWidth,
        height: minHeight,
      },
      productType
    );

    dispatch({
      type: "SET_WIDTH",
      payload: Number(minWidth),
    });
    dispatch({
      type: "SET_HEIGHT",
      payload: Number(minHeight),
    });

    dataContainer?.toggleVariationSelection(dimensionsVariation?.id, true);
    dataContainer?.toggleVariationSelection(item.id);
  };

  const getSortedItems = () => {
    if (!items) {
      return {};
    }

    const sortedItems: { [original_key: string]: ItemProps[] } = {
      firstClassTypes: [],
      secondClassTypes: [],
      thirdClassTypes: [],
      fourthClassTypes: [],
      fifthClassTypes: [],
      sixthClassTypes: [],
    };

    items.forEach((item) => {
      if (!item.original_key) {
        return;
      }
      if (firstClassTypes.includes(item.original_key)) {
        sortedItems.firstClassTypes.push(item);
        return;
      }
      if (secondClassTypes.includes(item.original_key)) {
        sortedItems.secondClassTypes.push(item);
        return;
      }
      if (thirdClassTypes.includes(item.original_key)) {
        sortedItems.thirdClassTypes.push(item);
        return;
      }
      if (fourthClassTypes.includes(item.original_key)) {
        sortedItems.fourthClassTypes.push(item);
        return;
      }
      if (fifthClassTypes.includes(item.original_key)) {
        sortedItems.fifthClassTypes.push(item);
        return;
      }

      if (sixthClassTypes.includes(item.original_key)) {
        sortedItems.sixthClassTypes.push(item);
        return;
      }
    });

    sortedItems.firstClassTypes.sort((firstItem, secondItem) => {
      return (
        Number(firstItem.original_key?.charAt(0)) -
        Number(secondItem.original_key?.charAt(0))
      );
    });

    return sortedItems;
  };
  return (
    <div>
      {Object.values(getSortedItems()).map((items) => {
        return (
          <div key={generateUID(5)} className="grid grid-cols-3 gap-4 mb-3">
            {items.map((item) => (
              <ImageRadioInput
                key={item.id}
                name={"luft_model"}
                value={item.id}
                title={dataContainer?.sectionKey("Translations", item.key)}
                checked={dataContainer?.isVariationSelected(item.id)}
                image={{
                  src: item.assets[0].preview_image_url,
                  title: item.key + " image",
                }}
                onChange={handleSelect.bind(null, item)}
              />
            ))}
          </div>
        );
      })}
    </div>
  );
};
