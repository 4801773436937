import React from "react";
import { IconProps } from "../Types";

const TopArrow = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 7L8 1L15 7" stroke="#333333" strokeLinecap="round" />
    </svg>
  );
};

export default TopArrow;
