import React from "react";
import { IconProps } from "../Types";

const BlackMinus = ({ className }: IconProps) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#333333" />
    <rect x="5" y="9" width="10" height="2" fill="white" />
  </svg>
);

export default BlackMinus;
