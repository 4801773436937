export const colorsVariants = {
  primary: {
    class: {
      default: "primary",
      bg: "bg-primary",
      text: "text-primary",
    },
    name: "Primary",
    hex: "#8E9D99",
  },
  secondary: {
    class: {
      default: "secondary",
      bg: "bg-secondary",
      text: "text-secondary",
    },
    name: "Secondary",
    hex: "#658481",
  },
  smoke: {
    class: {
      default: "smoke",
      bg: "bg-smoke",
      text: "text-smoke",
    },
    name: "Smoke",
    hex: "#F5F7F5",
  },
  orange: {
    class: {
      default: "orange",
      bg: "bg-orange",
      text: "text-orange",
    },
    name: "Orange",
    hex: "#C8917D",
  },
  black: {
    class: {
      default: "black",
      bg: "bg-black",
      text: "text-black",
    },
    name: "Black",
    hex: "#333333",
  },
  white: {
    class: {
      default: "white",
      bg: "bg-white",
      text: "text-white",
    },
    name: "White",
    hex: "#FFFFFF",
  },
};
