import React from "react";
import { IconProps } from "../Types";

const OpenMenu = ({ className }: IconProps) => (
  <svg
    className={className}
    width="30"
    height="4"
    viewBox="0 0 30 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="28"
      cy="2"
      r="1.5"
      transform="rotate(90 28 2)"
      fill="#F2F2F2"
      stroke="#333333"
    />
    <circle
      cx="15"
      cy="2"
      r="1.5"
      transform="rotate(90 15 2)"
      fill="#F2F2F2"
      stroke="#333333"
    />
    <circle
      cx="2"
      cy="2"
      r="1.5"
      transform="rotate(90 2 2)"
      fill="#F2F2F2"
      stroke="#333333"
    />
  </svg>
);

export default OpenMenu;
