import React from "react";
import { IconProps } from "../Types";

const CardSelect = ({ className }: IconProps) => (
  <svg
    className={className}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="11" r="10.5" stroke="#333333" />
    <path
      d="M6.94727 11.338V9.86516H14.9784V11.338H6.94727ZM10.1292 14.3809V6.94727H11.7687V14.3809H10.1292Z"
      fill="#333333"
    />
  </svg>
);

export default CardSelect;
