import React from "react";
import { IconProps } from "../Types";

const Glass = ({ className }: IconProps) => (
  <svg
    className={className}
    width="22"
    height="36"
    viewBox="0 0 22 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 1H1V35H21V1ZM1 0C0.447715 0 0 0.447716 0 1V35C0 35.5523 0.447716 36 1 36H21C21.5523 36 22 35.5523 22 35V1C22 0.447715 21.5523 0 21 0H1ZM4.35348 24.6465C4.54874 24.4513 4.86532 24.4513 5.06058 24.6465L11.3535 30.9394C11.5487 31.1347 11.5487 31.4513 11.3535 31.6465C11.1582 31.8418 10.8416 31.8418 10.6464 31.6465L4.35348 25.3536C4.15822 25.1584 4.15822 24.8418 4.35348 24.6465ZM4.06058 28.6465C3.86532 28.4513 3.54874 28.4513 3.35348 28.6465C3.15822 28.8418 3.15822 29.1584 3.35348 29.3536L6.64637 32.6465C6.84163 32.8418 7.15822 32.8418 7.35348 32.6465C7.54874 32.4513 7.54874 32.1347 7.35348 31.9394L4.06058 28.6465Z"
      fill="black"
    />
  </svg>
);

export default Glass;
