import React from "react";
import { IconProps } from "../Types";

const HorizontalSlider = ({ className }: IconProps) => (
  <svg
    className={className}
    width="37"
    height="22"
    viewBox="0 0 37 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="37"
      y="22"
      width="37"
      height="22"
      rx="11"
      transform="rotate(-180 37 22)"
      fill="#658481"
    />
    <path d="M12 17L7 11L12 5" stroke="white" />
    <path d="M25 5L30 11L25 17" stroke="white" />
  </svg>
);

export default HorizontalSlider;
