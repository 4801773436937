import React from "react";
import { IconProps } from "../Types";

const Select = ({ className }: IconProps) => (
  <svg
    className={className}
    width="7"
    height="7"
    viewBox="0 0 7 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 3.792V2.52H6.936V3.792H0ZM2.748 6.42V0H4.164V6.42H2.748Z"
      fill="#F5F7F5"
    />
  </svg>
);

export default Select;
