import React from "react";
import { TypographyProps } from "./types";
import classNames from "classnames";

const Typography = ({
  children,
  tag,
  className,
  parentClassName,
}: TypographyProps): JSX.Element => {
  const Tag = tag;

  return (
    <div className={classNames(parentClassName, "prose h-full")}>
      <Tag className={className}>{children}</Tag>
    </div>
  );
};

export { Typography };
