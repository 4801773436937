import { Mesh } from "@babylonjs/core";
import { AccordionEnum, ProfileNames } from "../../../services/enums";

export const handleProfiles = (meshes: Mesh[], dataContainer) => {
  const profileVariation = dataContainer?.findSelectedVariantByComponentName(
    AccordionEnum.Profile
  );
  const selectedProfile = profileVariation
    ? ProfileNames[profileVariation.original_key]
    : ProfileNames.svanhals;

  const profileMeshes = meshes?.filter((mesh) => mesh.id.includes("profile"));

  profileMeshes?.forEach((profileMesh) => {
    if (profileMesh.id.includes(selectedProfile)) {
      profileMesh.isVisible = true;
      return;
    }
    profileMesh.isVisible = false;
  });
};
