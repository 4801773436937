/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useEffect, useState } from "react";
import DataContainer from "../../services/DataContainer";
import { useLocation } from "react-router";

const initialState: {
  dataContainer: any;
  dataReady: boolean;
} = {
  dataContainer: null,
  dataReady: false,
};

export const DataContainerContext = createContext(initialState);

export const DataContainerProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const [dataContainer, setDataContainer] = useState(null);
  const [dataReady, setDataReady] = useState(false);
  const startTime = +new Date();
  const endTime = +new Date();
  const location = useLocation();

  useEffect(() => {
    DataContainer.loadData(() => {
      const urlSearchParams = new URLSearchParams(location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.s) {
        DataContainer.loadSelectionFromHash(params.s);
      }
      setDataContainer(DataContainer.instance() as any);
      setDataReady(true);
    });
  }, []);

  useEffect(() => {
    if(dataContainer) {   
      const duration = parseInt(String((startTime - Number(sessionStorage.getItem(("endTime")))) / 1000));   
      if(sessionStorage.getItem("endTime") && duration != 0){
        DataContainer.logEvent("session_end", "", {
          duration: duration,
        });
      }
      sessionStorage.setItem("endTime", endTime.toString());
    }
  });
  
  
  useEffect(() => {
    if(dataContainer) {
      DataContainer.logEvent("session_start", "", {
        start_time: startTime,
        source: DataContainer.getDeviceType(),
      });
    }
  }, [dataContainer]);

  return (
    <DataContainerContext.Provider value={{ dataContainer, dataReady }}>
      {children}
    </DataContainerContext.Provider>
  );
};
