import React from "react";
import { IconProps } from "../Types";

const Beslag = ({ className }: IconProps) => (
  <svg className={className} width="25" height="34" viewBox="0 0 25 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15.4516" height="33" rx="7.72581" fill="white" stroke="black" />
    <path d="M12.0428 19.6958H11.7544L11.61 19.9454C10.8848 21.1989 9.53072 22.0404 7.98067 22.0404C5.66679 22.0404 3.79102 20.1647 3.79102 17.8508C3.79102 15.5369 5.66679 13.6611 7.98067 13.6611C9.80416 13.6611 11.3568 14.8264 11.9321 16.4544L12.0499 16.7878H12.4036H22.4409C23.2439 16.7878 23.8949 17.4388 23.8949 18.2418C23.8949 19.0448 23.2439 19.6958 22.4409 19.6958H12.0428Z" fill="white" stroke="black" />
  </svg>
);

export default Beslag;
