import React from "react";
import { IconProps } from "../Types";

const OpenDoor = ({ className }: IconProps) => (
  <svg
    className={className}
    width="18"
    height="26"
    viewBox="0 0 18 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.641978 0.347954C0.674151 0.147457 0.847157 0 1.05022 0H16.6515C16.8799 0 17.065 0.185113 17.065 0.413462V22.2994C17.065 22.5277 16.8799 22.7128 16.6515 22.7128H10.422C10.1937 22.7128 10.0086 22.5277 10.0086 22.2994V3.79976L0.920856 0.806165C0.72799 0.742633 0.609806 0.54845 0.641978 0.347954ZM3.62687 0.826923L10.5514 3.10794C10.7209 3.16379 10.8355 3.32214 10.8355 3.50064V21.8859H16.238V0.826923H3.62687Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.807833 0.0784749C0.915304 0.000724828 1.05355 -0.0207407 1.17954 0.020761L10.5513 3.10794C10.7209 3.16379 10.8354 3.32214 10.8354 3.50065V25.3865C10.8354 25.5192 10.7718 25.6438 10.6643 25.7215C10.5569 25.7993 10.4186 25.8208 10.2926 25.7792L0.920819 22.6921C0.751277 22.6362 0.636719 22.4779 0.636719 22.2994V0.413465C0.636719 0.280818 0.700361 0.156225 0.807833 0.0784749ZM1.46364 0.984981V22.0002L10.0085 24.815V3.79976L1.46364 0.984981Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.05116 11.3013C8.27951 11.3013 8.46462 11.4864 8.46462 11.7147V14.0852C8.46462 14.3136 8.27951 14.4987 8.05116 14.4987C7.82281 14.4987 7.6377 14.3136 7.6377 14.0852V11.7147C7.6377 11.4864 7.82281 11.3013 8.05116 11.3013Z"
      fill="black"
    />
  </svg>
);

export default OpenDoor;
