import React from "react";
import { IconProps } from "../Types";

const Ventilation = ({ className }: IconProps)=> (
  <svg className={className} width="22" height="33" viewBox="0 0 22 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H21C21.5523 0 22 0.447715 22 1C22 1.55228 21.5523 2 21 2H1C0.447716 2 0 1.55228 0 1ZM1 5H11V15H1V5ZM0 16V15V5C0 4.44772 0.447715 4 1 4H11H12H21C21.5523 4 22 4.44772 22 5V15V16V32C22 32.5523 21.5523 33 21 33H12H11H1C0.447716 33 0 32.5523 0 32V16ZM21 15V5H12V15H21ZM12 16H21V32H12L12 16ZM11 16L11 32H1V16H11Z" fill="black" />
  </svg>
);

export default Ventilation;
