import React, { useState } from "react";
import { TabProps } from ".";
import { Typography } from "../typography";
import classnames from "classnames";
import { useParams } from "react-router";

const Tabs = ({ data, className, onTabClick }: TabProps) => {
  const { productType } = useParams();
  const [active, setActive] = useState(productType);

  function onTabChange(value) {
    setActive(value);
    onTabClick(value);
  }

  return (
    <div className={classnames(className, "flex flex-row")}>
      {data?.map((item, key) => (
        <button
          onClick={() => onTabChange(item.value)}
          key={key}
          className="mr-8 cursor-pointer"
        >
          <Typography
            tag="p"
            className={classnames(
              "text-primary text-4xl",
              active === item.value &&
                "pb-2 border-b border-[#000] text-black-faded"
            )}
          >
            {item.name}
          </Typography>
        </button>
      ))}
    </div>
  );
};

export { Tabs };
