import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { BabylonComponent } from "../components/babylon/Babylon";
import { use100vh } from "react-div-100vh";
import { DataContainerContext } from "../common/context/DataContainerProvider";
import { SingleComponents } from "../components/singleComponents";
import { Hinge } from "../services/enums";

function SinglePage() {
  const height = use100vh();
  const { dataContainer, dataReady } = useContext(DataContainerContext);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectionAccordion, setSelectionAccordion] = useState("");
  const { singleID, productType } = useParams();
  const [searchParmas] = useSearchParams();


  useEffect(() => {
    if (!singleID || !dataContainer || !productType) {
      return;
    }
    const currentProductType = sessionStorage.getItem("productType");
    const modelId = parseInt(singleID);

    if (currentProductType && currentProductType !== productType) {
      dataContainer?.switchSelectedVariations(productType, currentProductType);
      sessionStorage.setItem("productType", productType);
    }

    if (!currentProductType) {
      sessionStorage.setItem("productType", productType);
    }

    dataContainer?.unSelectCurrentModel();
    if (!dataContainer.isVariationSelected(modelId)) {
      dataContainer.toggleVariationSelection(modelId);
      if (!searchParmas.get("s")) {
        dataContainer.updateCurrentConfiguration();
      }
    }
    setSelectedModel(modelId);

    if (dataContainer) {
      dataContainer.logEvent("page_entered", "Single Product page", {
        product: "",
      });
    }
  }, [singleID, dataContainer, productType]);

  useEffect(() => {
    if (!sessionStorage.getItem("hangingVariant")) {
      sessionStorage.setItem("hangingVariant", Hinge.rightHinge);
    }
  }, []);

  return (
    <div style={{ height: height }} className="w-full overflow-hidden">
      <SingleComponents
        dataReady={dataReady}
        selectedModel={selectedModel}
        dataContainer={dataContainer}
        setSelectedModel={setSelectedModel}
        setSelectionAccordion={setSelectionAccordion}
        selectionAccordion={selectionAccordion}
      />
      <BabylonComponent
        dataContainer={dataContainer}
        selectionAccordion={selectionAccordion}
      />
    </div>
  );
}

export { SinglePage };
