import React from "react";
import { IconProps } from "../Types";

const Prev = ({ className }: IconProps) => (
  <svg
    className={className}
    width="9"
    height="17"
    viewBox="0 0 9 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.5 16L1 8.5L8.5 1" stroke="#333333" />
  </svg>
);

export default Prev;
