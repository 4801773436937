export const WindowsAccessoriesByLuftType = {
  "2": ["vertical0", "misc0", "misc1"],
  "2_2": ["misc0", "misc1", "misc2", "misc3", "vertical0", "horizontal0",],
  "1a": ["misc0"],
  "1b": ["misc0"],
  "1_1a": ["misc0", "misc1", "horizontal0"],
  "1_1b": ["misc0", "misc1", "horizontal0"],
  "3a": ["misc0", "misc1", "misc2", "vertical0", "vertical1"],
  "3b": ["misc0", "misc1", "misc2", "vertical0", "vertical1"],
  "3_3a": ["misc0", "misc1", "misc2", "misc3", "misc4", "misc5", "vertical0", "vertical1", "horizontal0"],
  "3_3b": ["misc0", "misc1", "misc2", "misc3", "misc4", "misc5", "vertical0", "vertical1", "horizontal0"],
};
export const SprojsNoneDividers = [
  "sp11",
  "sp21",
  "sp31",
  "sp41",
  "sp51",
  "sp12",
  "sp22",
  "sp32",
];

export const allSprojs = [
  "sp10",
  "sp20",
  "sp30",
  "sp11",
  "sp21",
  "sp31",
  "sp12",
  "sp22",
  "sp32"
];

export const WindowMiscOffsets = {
  wff: {
    left: 0.0199036,
    right: -0.0199036,
    top: 0.0199199,
    bottom: -0.0196804
  },
  wfik: {
    left: 0.0120489,
    right: -0.0120489,
    top: 0.0118261,
    bottom: -0.0211766
  },
  wfuk: {
    left: 0.0211894,
    right: -0.0211894,
    top: 0.0224089,
    bottom: -0.0303832,
  },
  wfuks: {
    left: 0.0218236,
    right: -0.0218236,
    top: 0.022992,
    bottom: -0.0267326
  },
};
