import React, { useState } from "react";
import classnames from "classnames";
import { Logo } from "../logo/Logo";
import { Typography } from "../typography";
import { FooterProps } from "./types";
import { BottomMenu } from "../bottomMenu/BottomMenu";

import ItemCategory from "./ItemCategory";

export const Footer = ({
  product,
  door,
  window,
  menuItems,
  className,
  setAccordionSelected,
}: FooterProps) => {
  const [isDoorsDropdownOpen, setIsDoorsDropdownOpen] = useState(false);
  const [isWindowsDropdownOpen, setIsWindowsDropdownOpen] = useState(false);

  return (
    <div
      className={classnames(
        "flex absolute bottom-0 left-0 w-full sm:justify-between bg-white",
        className
      )}
    >
      <div className="relative flex flex-1 sm:flex-none">
        <div className="hidden md:block pl-10 pt-[1.125rem] pb-[1.188rem] pr-10">
          <Logo to="/" />
        </div>
        <ItemCategory
          category={door}
          dropdownOpen={isDoorsDropdownOpen}
          setDropdownOpen={setIsDoorsDropdownOpen}
          className={classnames(isWindowsDropdownOpen && "hidden sm:flex")}
        />
        <ItemCategory
          category={window}
          dropdownOpen={isWindowsDropdownOpen}
          setDropdownOpen={setIsWindowsDropdownOpen}
          className={classnames(isDoorsDropdownOpen && "hidden sm:flex")}
        />
      </div>
      <BottomMenu
        items={menuItems}
        setAccordionSelected={setAccordionSelected}
        className="lg:mr-32 xl:mr-42 2xl:mr-72"
      />
      <div className="hidden sm:flex flex-col justify-start pr-8 xl:pr-24 py-5">
        <Typography tag="h4" className="text-4xl text-black-faded font-thin">
          {product && product.name}
        </Typography>
        <Typography
          tag="p"
          className="text-4xl overflow-hidden text-primary"
        >
          {product && product.model}
        </Typography>
      </div>
    </div>
  );
};
