import React from "react";
import { IconProps } from "../Types";

const Download = ({ className }: IconProps) => (
  <svg
    className={className}
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 7.5V13.3333H1.66667V7.5H0V13.3333C0 14.25 0.75 15 1.66667 15H13.3333C14.25 15 15 14.25 15 13.3333V7.5H13.3333ZM8.33333 8.05833L10.4917 5.90833L11.6667 7.08333L7.5 11.25L3.33333 7.08333L4.50833 5.90833L6.66667 8.05833V0H8.33333V8.05833Z"
      fill="#8E9D99"
    />
  </svg>
);

export default Download;
