import React from "react";
import { IconProps } from "../Types";

const CoverPlate = ({ className }: IconProps) => (
  <svg
    width="12"
    height="37"
    viewBox="0 0 12 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 37.0011L5.95362 36.9989C5.39554 36.973 4.5401 36.9109 3.7827 36.8066C3.40756 36.755 3.02707 36.6891 2.70988 36.6037C2.5531 36.5616 2.38128 36.5067 2.22235 36.4324C2.09915 36.3749 1.82185 36.2348 1.63316 35.9457C1.48839 35.7239 1.41714 35.4465 1.38085 35.2992C1.33275 35.104 1.28843 34.873 1.24726 34.6316C1.16531 34.1511 1.08433 33.5593 1.00638 32.9897L1.00471 32.9775C0.936405 32.4784 0.870142 31.9946 0.804428 31.5841C0.735278 31.1521 0.677593 30.8749 0.637574 30.7523C0.321493 29.7837 0 28.4742 0 26.8369V3.0741C0 2.73326 0.133291 2.39534 0.395788 2.14425C1.19865 1.37627 3.17417 0 5.55882 0H6.44118C8.82583 0 10.8013 1.37627 11.6042 2.14425L10.913 2.86688C10.2096 2.1941 8.46929 1 6.44118 1H5.55882C3.53071 1 1.79036 2.1941 1.08702 2.86688C1.03009 2.92135 1 2.99442 1 3.0741V26.8369C1 28.3391 1.29412 29.5408 1.58824 30.4421C1.72398 30.858 1.85973 31.85 1.99548 32.8419C2.15385 33.9992 2.31222 35.1565 2.47059 35.3991C2.70588 35.7597 4.92157 35.9499 6 36C7.07843 35.9499 9.29412 35.7597 9.52941 35.3991C9.68778 35.1565 9.84615 33.9992 10.0045 32.8419L10.0045 32.8419C10.1403 31.85 10.276 30.858 10.4118 30.4421C10.7059 29.5408 11 28.3391 11 26.8369V3.0741C11 2.99442 10.9699 2.92135 10.913 2.86688L11.6042 2.14425C11.8667 2.39535 12 2.73326 12 3.0741V26.8369C12 28.4742 11.6785 29.7837 11.3624 30.7523C11.3224 30.8749 11.2647 31.1521 11.1956 31.5841C11.1299 31.9946 11.0636 32.4784 10.9953 32.9775L10.9936 32.9896C10.9157 33.5593 10.8347 34.151 10.7527 34.6316C10.7116 34.873 10.6672 35.104 10.6192 35.2992C10.5829 35.4465 10.5116 35.7239 10.3668 35.9457C10.1782 36.2348 9.90085 36.3749 9.77765 36.4324C9.61872 36.5067 9.4469 36.5616 9.29012 36.6037C8.97293 36.6891 8.59244 36.755 8.2173 36.8066C7.4599 36.9109 6.60446 36.973 6.04638 36.9989L6 37.0011Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 9C9 10.6569 7.65685 12 6 12C4.34315 12 3 10.6569 3 9C3 7.34315 4.34315 6 6 6C7.65685 6 9 7.34315 9 9ZM6 11C7.10457 11 8 10.1046 8 9C8 7.89543 7.10457 7 6 7C4.89543 7 4 7.89543 4 9C4 10.1046 4.89543 11 6 11Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 29C9 30.6569 7.65685 32 6 32C4.34315 32 3 30.6569 3 29C3 27.3431 4.34315 26 6 26C7.65685 26 9 27.3431 9 29ZM6 31C7.10457 31 8 30.1046 8 29C8 27.8954 7.10457 27 6 27C4.89543 27 4 27.8954 4 29C4 30.1046 4.89543 31 6 31Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.02117 20.5H8.33333C8.70152 20.5 9 20.7239 9 21C9 21.2761 8.70152 21.5 8.33333 21.5H8.02117C7.61773 21.7989 6.86366 22 6 22C5.13634 22 4.38227 21.7989 3.97883 21.5H3.66667C3.29848 21.5 3 21.2761 3 21C3 20.7239 3.29848 20.5 3.66667 20.5H3.97883C4.38227 20.2011 5.13634 20 6 20C6.86366 20 7.61773 20.2011 8.02117 20.5ZM3.69421 19.5C4.32784 19.1572 5.17657 19 6 19C6.82343 19 7.67216 19.1572 8.30579 19.5H8.33333C8.71288 19.5 9.09498 19.6141 9.40474 19.8464C9.71481 20.079 10 20.4766 10 21C10 21.5234 9.71481 21.921 9.40474 22.1536C9.09498 22.3859 8.71288 22.5 8.33333 22.5H8.30579C7.67216 22.8428 6.82343 23 6 23C5.17657 23 4.32784 22.8428 3.69421 22.5H3.66667C3.28712 22.5 2.90502 22.3859 2.59526 22.1536C2.28519 21.921 2 21.5234 2 21C2 20.4766 2.28519 20.079 2.59526 19.8464C2.90502 19.6141 3.28712 19.5 3.66667 19.5H3.69421Z"
      fill="#333333"
    />
  </svg>
);

export default CoverPlate;
