import React from "react";
import { IconProps } from "../Types";

const Close = ({ className }: IconProps) => (
  <svg
    className={className}
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="1.35355"
      y1="0.646447"
      x2="21.3536"
      y2="20.6464"
      stroke="#333333"
    />
    <line
      y1="-0.5"
      x2="28.2843"
      y2="-0.5"
      transform="matrix(-0.707107 0.707107 0.707107 0.707107 21 1)"
      stroke="#333333"
    />
  </svg>
);

export default Close;
