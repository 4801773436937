import React from "react";
import { IconProps } from "../Types";

const Link = ({ className }: IconProps) => (
  <svg
    className={className}
    width="13"
    height="7"
    viewBox="0 0 13 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.44141 3.5C1.44141 2.43125 2.31016 1.5625 3.37891 1.5625H5.87891V0.375H3.37891C1.65391 0.375 0.253906 1.775 0.253906 3.5C0.253906 5.225 1.65391 6.625 3.37891 6.625H5.87891V5.4375H3.37891C2.31016 5.4375 1.44141 4.56875 1.44141 3.5ZM4.00391 4.125H9.00391V2.875H4.00391V4.125ZM9.62891 0.375H7.12891V1.5625H9.62891C10.6977 1.5625 11.5664 2.43125 11.5664 3.5C11.5664 4.56875 10.6977 5.4375 9.62891 5.4375H7.12891V6.625H9.62891C11.3539 6.625 12.7539 5.225 12.7539 3.5C12.7539 1.775 11.3539 0.375 9.62891 0.375Z"
      fill="#8E9D99"
    />
  </svg>
);

export default Link;
