import React, { useContext, useEffect, useState } from "react";
import { ColorRadioInput } from "../input/ColorRadioInput";
import { RadioInput } from "../input/RadioInput";
import { Typography } from "../typography";
import { ColorsGrid } from "./colorsGrid/ColorsGrid";
import { Product } from "./product/product";
import { Dimensions } from "../../data/Dimensions";
import { DoorsGrid } from "./index";
import { ImageRadioInput, SelectOption } from "../input";
import { ItemProps, SubAccordionProps } from "./types";
import { useParams, useNavigate } from "react-router";
import { BabylonContext } from "../../common/context/BabylonProvider";
import {
  AccordionEnum,
  BeslagWindowModels,
  DimensionsMultiplier,
  KickplateAttributeId,
  ModelVariants,
} from "src/services/enums";
import { moveActiveCamera } from "../babylon/services/Animation";
import { configuratorKeys } from "../../services/enums";
import {
  AirDividers,
  MaximumDimensionsRequirements,
  MinimumDimensionsRequirements,
} from "./airDividers/AirDividers";
import { Ventilations } from "../../consts/ventilations";
import { Tillvals } from "../../consts/tillvals";
import classnames from "classnames";
import { Image } from "../image";

type GroupedDimensionsProps = {
  [key: string]: SelectOption[];
};

const SubAccordion = ({
  variant,
  label,
  items,
  actionTitle,
  dataContainer,
  imageModal,
  setSliderValues,
  accordionOpen,
}: SubAccordionProps) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { category, productType, singleID } = useParams();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { state } = useContext<any>(BabylonContext);
  const [dimensions, setDimensions] = useState<SelectOption[][]>([]);
  const [materialId, setMaterialId] = useState<number | null>(null);
  const [radioChecked, setRadioChecked] = useState("utsida");
  const [gridReverse, setGridReverse] = useState<number | null>(null);

  const toggleColorVariant = (variant) => {
    sessionStorage.setItem("colorVariant", variant);
    setRadioChecked(variant);
  };

  const toggleSelection = (id) => {
    if (materialId && !dataContainer.isVariationSelected(materialId)) {
      dataContainer?.toggleVariationSelection(materialId, null, true);
    }
    dataContainer?.toggleVariationSelection(id);
    dataContainer?.handleVariationLogic(id);
  };

  const options = dataContainer?.findComponentByName(
    `${label} Types`
  )?.available_variations;

  const colorOptions =
    dataContainer?.findComponentByName("ColorTypes")?.available_variations;

  if (options && label === AccordionEnum.Hinge && category === "double") {
    options[0].key = "Aktiv dörr vänster";
    options[1].key = "Aktiv dörr höger";
  }

  useEffect(() => {
    if (variant === "dimensions") {
      setDimensions(getDimensions(items));
    }
  }, [accordionOpen === AccordionEnum.Size, singleID]);

  useEffect(() => {
    if (dataContainer && options && label !== AccordionEnum.Hinge) {
      setMaterialId(options[0].id);
    }
  }, [dataContainer]);

  dataContainer?.registerSelectionHook(() => {
    setShouldRefresh(!shouldRefresh);
  });

  const toggleGridReverse = () => {
    if (!gridReverse) {
      return;
    }
    const isSelected = dataContainer?.isVariationSelected(gridReverse);

    if (gridReverse) {
      if (isSelected) {
        dataContainer?.toggleVariationSelection(gridReverse, null, false);
      } else {
        dataContainer?.toggleVariationSelection(gridReverse);
      }
    }
  };

  useEffect(() => {
    toggleGridReverse();
  }, [gridReverse]);

  const getSliderValues = (dimensions) => {
    const verticalValues: number[] = [];
    const horizontalValues: number[] = [];

    dimensions.forEach((dimensionPair, index) => {
      if (index === 0) {
        dimensionPair.forEach((dimension) => {
          verticalValues.push(
            Number(dimension.value.height) *
              DimensionsMultiplier[productType || "doors"]
          );
        });
      }

      const width =
        Number(dimensionPair[0].value.width) *
        DimensionsMultiplier[productType || "doors"];
      horizontalValues.push(width);
    });

    setSliderValues &&
      setSliderValues({
        vertical: verticalValues,
        horizontal: horizontalValues,
      });
  };

  const setHanging = (option, i) => {
    sessionStorage.setItem("hangingVariant", option.original_key);
    dataContainer?.toggleVariationSelection(option.id);
    if (i === 0) {
      moveActiveCamera(state.scene, state.camera, {
        radius: 4,
        alpha: 1,
        beta: 0.8,
      });
    }
    if (i === 1) {
      moveActiveCamera(state.scene, state.camera, {
        radius: 4,
        alpha: 1.9,
        beta: 0.8,
      });
    }
  };

  const getDimensions = (items) => {
    const groupedByWidth: GroupedDimensionsProps = {};

    const category = dataContainer?.getCategoryOfCurrentModel();
    const luftType = dataContainer?.findSelectedVariantByComponentName(
      AccordionEnum.Luft_Types
    )?.original_key || "1a";

    items?.forEach((item) => {
      const itemWidth = item.assets[0].attribute_values[0]?.value;
      const itemHeight = item.assets[0].attribute_values[1]?.value;

      if ([17, 18].includes(ModelVariants[category]) && luftType) {
        const [minWidth, minHeight] = MinimumDimensionsRequirements[luftType];
        const [maxWidth, maxHeight] = MaximumDimensionsRequirements[luftType];

        if (Number(itemWidth) > maxWidth || Number(itemWidth) < minWidth) {
          return;
        }

        if (Number(itemHeight) > maxHeight || Number(itemHeight) < minHeight) {
          return;
        }
      }

      if (!groupedByWidth[itemWidth]) {
        groupedByWidth[itemWidth] = [];
      }

      if (
        groupedByWidth[itemWidth].find(
          ({ value }) =>
            value.height === itemHeight && value.width === itemWidth
        )
      ) {
        return;
      }

      groupedByWidth[itemWidth].push({
        value: { width: itemWidth, height: itemHeight, variationId: item.id },
        label: `${
          itemWidth * DimensionsMultiplier[productType || "doors"] - 20
        }mm x ${
          itemHeight * DimensionsMultiplier[productType || "doors"] - 20
        }mm`,
      });
    });

    const result: SelectOption[][] = [];

    Object.keys(groupedByWidth).forEach((key) =>
      result.push(groupedByWidth[key])
    );

    getSliderValues(result);

    return result;
  };

  const renderProduct = (product) => {
    const isSelected = dataContainer.isVariationSelected(product.id);
    const componentName = dataContainer?.findComponentById(
      product.component_id
    ).name;

    return (
      <Product
        key={product.id}
        name={product.key}
        image={{
          src: product.assets[0]?.preview_image_url,
          title: product.key,
          className: "max-h-[66px]",
          objectFit: "contain",
        }}
        description={dataContainer?.sectionKey(
          productType === "doors"
            ? configuratorKeys.doorsDescription
            : configuratorKeys.windowsDescription,
          product.original_key
        )}
        removeButton={{
          isShown: isSelected,
          onClick: toggleSelection.bind(null, product.id),
        }}
        addButton={{
          isShown: true,
          variant: isSelected ? "white" : "black",
          onClick: !isSelected
            ? toggleSelection.bind(null, product.id)
            : undefined,
        }}
        glassButton={
          componentName === "Glass"
            ? {
              isShown: imageModal?.modalData.productId === product.id,
              onClick: () =>
                imageModal?.setModalData({
                  ...imageModal.modalData,
                  isOpen: true,
                  image: {
                    src: product.assets[0].preview_image_url,
                    title: "",
                  },
                  productId: product.id || "",
                }),
            }
            : undefined
        }
      />
    );
  };

  const renderColors = (sub) => (
    <ColorRadioInput
      key={sub.id}
      id={sub.id}
      name={sub.id}
      value={sub.id}
      checked={dataContainer?.isVariationSelected(sub.id)}
      image={{
        src: sub.assets[0].preview_image_url,
        title: sub.key + " image",
      }}
      onChange={() => {
        dataContainer?.toggleVariationSelection(sub.id);
      }}
    />
  );

  const getFilteredVentilations = (id, items) => {
    const variant = dataContainer?.findVariationBy(Number(id));
    const spalt = "spaltventil";
    const forhojningsdel = "forhojningsdel";

    const modelFilteredVentilations =  items.filter((item) => {
      return Ventilations[variant?.original_key?.split("_")?.[0]].some(
        (ventilType) => {
          if (!variant?.original_key.includes("ik")) {
            return (
              item.original_key.includes(ventilType) ||
              (item.original_key.includes(spalt) &&
                !item.original_key.includes(forhojningsdel))
            );
          }
          return item.original_key.includes(ventilType);
        }
      );
    });

    if(!productType) {
      return modelFilteredVentilations;
    }

    const frameDimensions: { width: number; height: number } =
      dataContainer?.getConfigurationMetadata(
        "frameDimensions",
        productType,
        dataContainer?.mCurrentConfigurationIndex[productType]
      );
    
    if(!frameDimensions.width) {
      return modelFilteredVentilations;
    }

    const windowsOffset = 120;

    const sizeFilteredVentilations = modelFilteredVentilations?.filter(ventilation => {
      const ventilationNameParts = ventilation.original_key?.split("_");
      if(ventilationNameParts.length < 2) {
        return true;
      }

      if(!isNaN(Number(ventilationNameParts.at(-2)))) {
        return frameDimensions.width - windowsOffset >= (Number(ventilationNameParts.at(-2)) * 10);
      }

      if(!isNaN(Number(ventilationNameParts.at(-3)))) {
        return frameDimensions.width - windowsOffset >= Number(ventilationNameParts.at(-3));
      }

      return true;

    });
    
    return sizeFilteredVentilations;

  };

  const getFilteredKickplates = (category) => {
    return items?.filter((item) => {
      let equalsCategory = false;
      item.assets[0].attribute_values?.forEach(aValue => {
        if(aValue.attribute_id === KickplateAttributeId) {
          equalsCategory = aValue.value.toLowerCase().includes(category);
        }
      });
      return equalsCategory;
    }
    );
  };

  const getFilteredBeslags = (items: ItemProps[]) => {
    if (!singleID) {
      return items;
    }

    const windowModel = dataContainer
      ?.findVariationBy(Number(singleID))
      ?.original_key?.split("_")?.[0];

    if (!BeslagWindowModels[windowModel]) {
      return items;
    }

    return items?.filter((item) => {
      return BeslagWindowModels[windowModel].some((beslagModel) => {
        return item.original_key?.includes(beslagModel);
      });
    });
  };

  const getFilteredGanjarn = (items, singleID) => {
    if (!singleID) {
      return;
    }

    const modelDetector = "i";
    const svenska = "svenska";

    const variant = dataContainer.findVariationBy(Number(singleID));

    if (variant.original_key?.split("_")?.[0]?.includes(modelDetector)) {
      return items.filter((item) => !item.original_key.includes(svenska));
    }

    return items.filter((item) => item.original_key.includes(svenska));
  };

  const getFilteredWindowsTillval = (items, singleID) => {
    if (!singleID) {
      return;
    }

    const variant = dataContainer.findVariationBy(Number(singleID));

    if(!variant) {
      return;
    }

    return items?.filter(item => {
      return Tillvals[variant?.original_key?.split("_")?.[0]]?.some((tillvalName) => {
        return item.original_key.includes(tillvalName);
      });
    });

  };

  const getFilteredGlass = (items, singleID) => {
    if (!singleID) {
      return;
    }

    const kulturGlass = "kulturglas";
    const ytterbagen = "ytterbagen";
    const antikGlass = "antik";

    const exclusionItem = "kulturglasglas_i_ytterbagen";

    const variant = dataContainer.findVariationBy(Number(singleID));

    if (variant.original_key === "wff") {
      return items.filter(
        (item) =>
          !item.original_key.includes("ytterbage") ||
          item.original_key === exclusionItem
      );
    }

    return items.filter(
      (item) =>
        (!item.original_key.includes(kulturGlass) &&
          !item.original_key.includes(ytterbagen) &&
          !item.original_key.includes(antikGlass)) ||
        item.original_key === exclusionItem
    );
  };

  const renderProducts = (items: ItemProps[]) => {
    if (materialId) {
      const filteredByMaterial = items?.filter(
        (item) =>
          item.dependencies &&
          item.dependencies[0]?.depends_on_variation_id === materialId
      );

      if (label === AccordionEnum.Beslag) {
        return getFilteredBeslags(filteredByMaterial)?.map((item) =>
          renderProduct(item)
        );
      }

      return filteredByMaterial?.map((item) => renderProduct(item));
    }

    if (label === AccordionEnum.Hinge) {
      return getFilteredGanjarn(items, singleID)?.map((item) =>
        renderProduct(item)
      );
    }

    if (label === AccordionEnum.Kickplate) {
      return getFilteredKickplates(category)?.map((item) =>
        renderProduct(item)
      );
    }

    if (label === AccordionEnum.Ventilation) {
      return getFilteredVentilations(singleID, items).map((item) =>
        renderProduct(item)
      );
    }

    if (label === AccordionEnum.Glass) {
      return getFilteredGlass(items, singleID).map((item) =>
        renderProduct(item)
      );
    }

    if (label === AccordionEnum.Tillval && productType === "windows") {
      return getFilteredWindowsTillval(items, singleID).map((item) =>
        renderProduct(item)
      );
    }

    return items?.map((item) => renderProduct(item));
  };

  const renderSubAccordion = () => {
    switch (variant) {
    case "luft":
      return <AirDividers dataContainer={dataContainer} items={items} />;
    case "grid":
      return (
        <DoorsGrid
          variant={category === "double" ? "doubleDoors" : "singleDoor"}
          seeAllModels={{
            URL: `/models/${productType}/${category}`,
            text: "Fler modeller",
          }}
        >
          {items?.slice(0, 6).map((item) => (
            <ImageRadioInput
              key={item.id}
              name={"door_model"}
              value={item.id}
              title={item.key}
              checked={dataContainer?.isVariationSelected(item.id)}
              image={{
                src: item.assets[0]?.preview_image_url,
                title: item.key + " image",
                className: "w-[46px] md:w-[70px]",
              }}
              onChange={() => {
                toggleSelection.bind(null, item.id);
                dataContainer?.updateCurrentConfiguration();
                dataContainer?.unselectDependentVariations();
                navigate(`/${productType}/${category}/${item.id}`, {
                  replace: true,
                });
              }}
            />
          ))}
        </DoorsGrid>
      );
    case "grid_reverse":
      return (
        <>
          <Typography
            tag="h3"
            className="text-black-secondary font-normal"
            parentClassName="h-auto"
          >
            {label
              ? dataContainer?.sectionKey(
                "Accordions labels",
                label?.toLowerCase().split(" ").join("_")
              )
              : ""}
          </Typography>
          <Typography
            tag="p"
            className="text-3xl md:text-4xl leading-3 md:leading-4 text-gray-darker mt-5 break-words pb-14"
            parentClassName="h-auto"
          >
            {label
              ? dataContainer?.sectionKey(
                "Accordions labels",
                label?.toLowerCase().split(" ").join("_") + "_description"
              )
              : ""}
          </Typography>
          <div className="flex flex-row items-start flex-wrap">
            {items?.map((item) => (
              <div className={classnames("flex h-auto w-1/5")} key={item.id}>
                <label
                  htmlFor={item.id}
                  className={classnames(
                    "flex items-center relative cursor-pointer sm:justify-start justify-around h-full",
                    { "flex-col-reverse sm:pr-5 pr-2.5": true },
                  )}
                >
                  <input
                    type="radio"
                    className="absolute w-0 h-0 opacity-0 peer"
                    id={item.id}
                    value={item.id}
                    name={item.id}
                    onClick={() => {
                      setGridReverse(Number(item.id));
                      toggleGridReverse();
                    }}
                    checked={dataContainer?.isVariationSelected(item.id)} />
                  <span
                    className={classnames(
                      "inline-block px-[0.625rem] pt-[0.625rem] pb-[0.875rem] md:pt-[0.625rem] md:pb-[0.563rem] md:px-[0.688rem] border border-transparent peer-checked:border-black-secondary",
                    )}
                  >
                    <Image {...{src: item.assets[0]?.preview_image_url,
                      title: item.key + " image",
                      className: "w-[46px] md:w-[70px]"}} />
                  </span>
                  <h4
                    className={classnames(
                      "text-3xl mt-3 mb-3 text-black text-center",
                    )}
                  >
                    {item.key}
                  </h4>
                </label>
              </div>
            ))}
          </div>
        </>
      );
    case "list":
      return (
        <>
          <Typography
            tag="h3"
            className="text-black-secondary font-normal"
            parentClassName="h-auto"
          >
            {options
              ? dataContainer?.sectionKey(
                "Accordions labels",
                label?.toLowerCase().split(" ").join("_")
              )
              : ""}
          </Typography>
          {options?.length && (
            <div className="grid sm:grid-cols-3 grid-cols-2 gap-x-6 gap-y-10 pt-10 pb-8 border-b">
              {options?.map((option, i) => (
                <RadioInput
                  label={option.key}
                  onChange={(e) => {
                    label !== AccordionEnum.Hinge
                      ? setMaterialId(Number(e.target.value))
                      : setHanging(option, i);
                  }}
                  value={option.id}
                  name={label}
                  id={option.id}
                  key={option.id}
                  checked={
                    materialId === option.id ||
                      sessionStorage.getItem("hangingVariant") ===
                        option.original_key
                  }
                />
              ))}
            </div>
          )}
          {actionTitle?.length && (
            <Typography
              tag="p"
              className="text-xs text-black-secondary pt-8 pb-12 font-normal"
            >
              {actionTitle}
            </Typography>
          )}

          {items && renderProducts(items)}
        </>
      );
    case "color":
      return (
        <>
          <Typography
            tag="h3"
            className="text-black-secondary font-normal sm:pb-8"
            parentClassName="h-auto"
          >
            {colorOptions
              ? dataContainer?.sectionKey(
                "Accordions labels",
                label?.toLowerCase().split(" ").join("_")
              )
              : ""}
          </Typography>
          <div className="flex gap-16 pt-10 pb-8">
            {colorOptions?.map((option) => (
              <RadioInput
                label={`${option.key} ${
                  productType === "doors" ? "dörr" : "fönster"
                }`}
                onChange={() => {
                  toggleColorVariant(option.original_key);
                  option.original_key.includes("utsida")
                    ? moveActiveCamera(state.scene, state.camera, {
                      radius: state.camera.radius,
                      alpha: 1.57,
                      beta: 0.8,
                    })
                    : moveActiveCamera(state.scene, state.camera, {
                      radius: state.camera.radius,
                      alpha: 4.7,
                      beta: 0.8,
                    });
                }}
                checked={option.original_key === radioChecked}
                value={option.id}
                name={label}
                id={option.id}
                key={option.id}
              />
            ))}
          </div>
          <ColorsGrid
            applyBottomBorder={true}
            className="border-t border-white-off"
          >
            {radioChecked === "utsida"
              ? items?.map((sub) => renderColors(sub))
              : dataContainer
                ?.findComponentByName("Color Inside")
                .available_variations.map((sub) => renderColors(sub))}
          </ColorsGrid>
        </>
      );
    case "dimensions":
      return (
        <Dimensions
          dimensionsOptions={dimensions}
          dataContainer={dataContainer}
        />
      );
    }
  };

  return <>{renderSubAccordion()}</>;
};

export default SubAccordion;
