import { useContext, useEffect, useState } from "react";
import { FieldsType, UseFormOptions, UseFormValues } from "./Types";
import { DataContainerContext } from "../../common/context/DataContainerProvider";

export const contactFormValidation = {
  first_name: {
    required: {
      value: true,
      message: "* Du måste ange ett förnamn",
    },
  },
  last_name: {
    required: {
      value: true,
      message: "* Du måste ange ett efternamn",
    },
  },

  address: {
    required: {
      value: true,
      message: "* Du måste ange en adress",
    },
  },
  zip_code: {
    required: {
      value: true,
      message: "* Du måste ange ett postnummer",
    },
  },
  country: {
    required: {
      value: true,
      message: "* Du måste ange en stad",
    },
  },
  email: {
    required: {
      value: true,
      message: "* Du måste ange en e-postadress",
    },
    pattern: {
      //eslint-disable-next-line
      value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, //prettier-ignore
      message: "* E-post är inte giltig",
    },
  },
  message: {
    required: {
      value: true,
      message: "* Du måste ange ett meddelande"
    }
  },
  terms: {
    required: {
      value: true,
      message: "* Du bör godkänna våra villkor",
    },
  },
};

export const useForm = (options: UseFormOptions): UseFormValues => {
  const [data, setData] = useState<FieldsType>({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    address: "",
    co: "",
    zip_code: "",
    country: "",
    message: "",
    terms: false,
    ...options?.initialValues,
  });
  const [errors, setErrors] = useState<FieldsType>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { dataContainer } = useContext(DataContainerContext);

  const handleChange = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>
  ) => {
    setData({
      ...data,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setIsSubmitted(true);
    if (isValid && options?.onSubmit) {
      options.onSubmit(data);
      dataContainer.logEvent("behaviors", "contact_us_clicked", {
        product: "",
      });
    }
  };

  useEffect(() => {
    const validations = options?.validations || contactFormValidation;
    if (validations) {
      setIsValid(true);
      const newErrors = {};
      Object.keys(validations).forEach((key) => {
        const value = data[key];
        const validation = validations[key];
        const pattern = validation?.pattern;

        if (validation?.required?.value && !value) {
          setIsValid(false);
          newErrors[key] = validation?.required?.message;
        } else if (pattern?.value && !RegExp(pattern.value).test(value)) {
          setIsValid(false);
          newErrors[key] = pattern.message;
        }
      });

      if (!isValid) {
        if (isSubmitted) {
          setErrors(newErrors);
        }
        return;
      }

      setErrors({});
    }
  }, [data, isSubmitted]);

  return {
    data,
    handleChange,
    handleSubmit,
    errors,
  };
};
