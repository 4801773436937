import axios from "axios";
import {
  AccordionEnum,
  CategoryIds,
  DimensionsMultiplier,
  ModelVariants,
  otherProducts,
  ProductTypes,
  productsCategoriesId,
  configuratorKeys,
} from "./enums";
import { WindowCategories, DoorCategories } from "./enums";

export default {
  _mState: "passive",
  _productId: 35,
  _applicationId: 11,
  _apiKey: "5zoHM390WoqSu674RHFKcMJuocfaq3HIqgYWA7dF",
  _locale: "sv",
  _localeId: "154",
  mLoadedData: null,
  mComponents: null,
  mAllComponents: null,
  mVariations: null,
  mImages: {},
  mProducts: null,
  mAllProducts: null,
  mAccessories: null,
  mCustomDimensions: null,
  mSizeVariation: null,
  sprojsDuplicated: [],
  mConfigurations: {
    doors: [{ variations: [], metadata: {} }],
    windows: [{ variations: [], metadata: {} }],
  },
  mProductTypeSelectedVariations: { doors: [], windows: [] },
  mCurrentConfigurationIndex: { doors: 0, windows: 0 },
  mHierarchical: null,
  mSelectedVariations: [],
  mSelectionHooks: [],
  mApplicationDataChangeHooks: [],
  mInitLoadedScripts: false,
  mLoadedScripts: {},
  mVariationJsAssetsCount: 0,
  mApplicationData: {},
  mSessionId: null,
  mVisitorId: null,
  instance() {
    if (!window.dataContainer) {
      window.dataContainer = this;
    }
    if (!window.onScriptLoaded) {
      window.onScriptLoaded = (variationIframeKey) => {
        const variationId = variationIframeKey.split("_")[0];
        const object = document
          .getElementById(variationIframeKey)
          .contentWindow.instance();
        const copyOfObject = {};
        const keys = Object.keys(object);
        for (let idx = 0; idx < keys.length; idx += 1) {
          copyOfObject[keys[idx]] = object[keys[idx]];
        }
        window.dataContainer.appendScript(variationId, copyOfObject);
      };
    }
    return this;
  },
  appendScript(variationId, object) {
    this.mLoadedScripts[variationId] = object;
    if (this.mInitLoadedScripts) {
      this.initVariationScripts();
    }
  },
  initVariationScripts() {
    const jsKeys = Object.keys(this.mLoadedScripts);
    if (jsKeys.length === this.mVariationJsAssetsCount) {
      for (let idx = 0; idx < jsKeys.length; idx += 1) {
        this.mLoadedScripts[jsKeys[idx]].onInit(
          parseInt(jsKeys[idx], 10),
          this
        );
      }
    }
  },
  registerSelectionHook(callback) {
    this.mSelectionHooks.push(callback);
  },
  registerApplicationDataChangeHook(callback) {
    this.mApplicationDataChangeHooks.push(callback);
  },
  loadApplicationData(callback) {
    axios
      .get(
        `https://one-app.onecx.rapidimages.com/api/v1/applications/locale/${this._localeId}`,
        {
          headers: {
            "X-Header-ApiKey": this._apiKey,
            "X-Header-AppId": this._applicationId,
          },
        }
      )
      .then((r) => {
        if (r.data.success) {
          this.mApplicationData = r.data.data;
          if (callback) {
            callback();
          }
        }
      });
  },
  loadData(callback) {
    if (this._mState !== "passive") {
      return;
    }
    this._mState = "fetching";
    this.mSessionId = this.uuidv4();
    this.mVisitorId = this.uuidv4();
    axios
      .get(
        "https://one-prod.onecx.rapidimages.com/api/v1/products/data/sv?ignoreComponents=true",
        {
          headers: {
            "X-Header-ApiKey": this._apiKey,
            "X-Header-AppId": this._applicationId,
          },
        }
      )
      .then(async (r) => {
        if (!r.data.success) {
          this._mState = "passive";
          if (callback) {
            callback(null);
          }
        } else {
          this._mState = "fetched";
          const allProducts = [
            {
              name: "doors",
              products: [],
            },
            {
              name: "windows",
              products: [],
            },
          ];
          const initalProducts = r.data.data;
          const promises = [];

          initalProducts.forEach((product) => {
            if (product["cached_url"]) {
              promises.push(axios.get(product["cached_url"]));
            }
          });

          const results = await Promise.allSettled(promises);

          const resolvedResults = results.filter(
            (r) => r.status === "fulfilled"
          );

          const productsData = resolvedResults.map((r) => r.value.data);

          this.mLoadedData = productsData.filter(
            (data) => data.name !== "Doors"
          );
          allProducts[0].products = r.data.data.filter((data) =>
            DoorCategories.includes(data.category_id)
          );
          allProducts[1].products = r.data.data.filter((data) =>
            WindowCategories.includes(data.category_id === 16)
          );

          this.mAllProducts = allProducts;
          this.extractComponentsAndVariations();
          this.loadScriptAssetsForVariations();

          this.loadApplicationData(() => {
            if (callback) {
              callback({
                instance: this,
                plainData: this.mLoadedData,
                variations: this.mVariations,
                components: this.mComponents,
              });
            }
          });
        }
      });
  },
  getProductDescriptions(currentVariation) {
    if (
      productsCategoriesId.doors.includes(currentVariation.product_category_id)
    ) {
      return configuratorKeys.doorsDescription;
    }
    return configuratorKeys.windowsDescription;
  },
  getAllProducts() {
    return this.mAllProducts;
  },
  loadScriptAssetsForVariations() {
    this.mVariations.forEach((v) => {
      if (v.assets.length > 0) {
        v.assets.forEach((vA) => {
          if (vA.asset_type === "js") {
            this.mVariationJsAssetsCount += 1;
            this.loadInstanceForVariation(v, vA.asset_url);
          }
        });
      }
    });
  },
  loadInstanceForVariation(variation, scriptUrl) {
    const iFrameHtml =
      `${"<html>" + "<head>" + "<script src='"}${scriptUrl}'>` +
      "</script>" +
      "</head>" +
      "</html>";

    const iFrameId = `${variation.id}_ifr_${Math.random()}`;

    // eslint-disable-next-line no-unused-vars
    const iframeSource = `<iframe onload='window.onScriptLoaded("${iFrameId}")' id="${iFrameId}" srcdoc="${iFrameHtml}"></iframe>`;
    if (document.getElementById("temp_iframes")) {
      setTimeout(() => {
        document
          .getElementById("temp_iframes")
          .insertAdjacentHTML("beforeend", iframeSource);
      }, 1500);
    } else {
      throw new DOMException("temp_iframes doesn't exist!");
    }
  },
  sectionKey(sectionName, key) {
    if (!this.mApplicationData || !this.mApplicationData.sections) {
      return `${key}`;
    }
    const section = this.mApplicationData.sections.find(
      (s) => s.section_name === sectionName
    );

    if (!section) {
      return `${key}`;
    }

    if (!Object.keys(section.key_values).includes(key)) {
      return `${key}`;
    }

    return section.key_values[key];
  },
  getAllVariations() {
    return this.mVariations;
  },
  getSingleAccordions(id) {
    let singleAccordions = [];

    for (const key in this.mLoadedData) {
      const obj = this.mLoadedData[key];
      if (
        obj.component_groups.length &&
        obj.component_groups[0].components[0]?.available_variations[0]?.id ===
          Number(id)
      ) {
        const lufts = obj.component_groups[0]?.components?.find((component) => {
          return component.name.includes("Types");
        });
        if (lufts) {
          return [...obj.component_groups[1].components, lufts];
        }
        return obj.component_groups[1].components;
      }
    }

    return singleAccordions;
  },
  createNewConfiguration(modelId, productType) {
    if (!productType || !modelId) return;
    const productCategories = CategoryIds[productType];

    if (
      Object.keys(productCategories).includes(this.getCategoryOfCurrentModel())
    ) {
      this.mConfigurations[productType].splice(
        this.mCurrentConfigurationIndex[productType],
        1,
        {
          variations: this.mSelectedVariations,
          metadata:
            this.mConfigurations[productType][
              this.mCurrentConfigurationIndex[productType]
            ].metadata,
        }
      );
    }

    this.mSelectedVariations = [];
    this.toggleVariationSelection(modelId);
    this.mCurrentConfigurationIndex[productType] =
      this.mConfigurations[productType].length;
    this.mConfigurations[productType].splice(
      this.mCurrentConfigurationIndex[productType],
      0,
      {
        variations: this.mSelectedVariations,
        metadata: {},
      }
    );
  },
  getConfigurationModels(productType) {
    const allModelsVariations = this.getAllModelsVariations().map(
      (variation) => variation && variation.id
    );

    const configurationModels = [];

    this.mConfigurations[productType]?.forEach((configuration) => {
      configuration.variations.forEach((variation) => {
        if (allModelsVariations.includes(variation)) {
          configurationModels.push(this.findVariationBy(variation));
        }
      });
    });

    return configurationModels;
  },
  switchConfiguratorModel(idx, isRemoving = false, productType) {
    if (idx === this.mCurrentConfigurationIndex[productType] && !isRemoving) {
      return;
    }
    if (!isRemoving) {
      this.mConfigurations[productType][
        this.mCurrentConfigurationIndex[productType]
      ].variations = this.mSelectedVariations;
    }

    this.mCurrentConfigurationIndex[productType] = idx;
    this.mSelectedVariations =
      this.mConfigurations?.[productType]?.[idx].variations;
  },
  removeModelFromConfiguration(idx, productType) {
    this.mConfigurations?.[productType]?.splice(idx, 1);
    this.switchConfiguratorModel(0, true, productType);

    const currentModelId = this.getCurrentModel();
    const currentModelCategory =
      this.findModelCategoryByVariationId(currentModelId);

    return { model: currentModelId, category: currentModelCategory };
  },
  getConfigurationVariations(idx, productType) {
    return this.mConfigurations[productType]?.[idx].variations.map(
      (variationId) => this.findVariationBy(variationId)
    );
  },
  getCurrentModel() {
    const allModelsVariations = this.getAllModelsVariations().map(
      (variation) => variation && variation.id
    );
    return this.mSelectedVariations.find((variation) =>
      allModelsVariations.includes(variation)
    );
  },
  updateCurrentConfiguration() {
    const currentModelCategory = this.getCategoryOfCurrentModel();
    const productType = WindowCategories.includes(
      ModelVariants[currentModelCategory]
    )
      ? "windows"
      : "doors";
    if (
      productType &&
      this.mConfigurations[productType][
        this.mCurrentConfigurationIndex[productType]
      ]
    ) {
      this.mConfigurations[productType][
        this.mCurrentConfigurationIndex[productType]
      ].variations = this.mSelectedVariations;
    }
  },
  getConfigurations() {
    if (!this.mConfigurations.index) {
      this.updateCurrentConfiguration();
    }

    const doors = this.mConfigurations["doors"].map((configuration, index) => {
      return {
        index,
        variations: configuration.variations.map((variant) =>
          this.findVariationBy(variant)
        ),
        productType: "doors",
      };
    });

    const windows = this.mConfigurations["windows"].map(
      (configuration, index) => {
        return {
          index,
          variations: configuration.variations.map((variant) =>
            this.findVariationBy(variant)
          ),
          productType: "windows",
        };
      }
    );

    return doors.concat(windows);
  },
  setConfigurationMetadata(key, value, productType, index) {
    if (!productType) {
      return;
    }

    const configuration =
      this.mConfigurations[productType][
        !isNaN(index) ? index : this.mCurrentConfigurationIndex[productType]
      ];

    if (configuration && configuration.metadata) {
      configuration.metadata[key] = value;
    }
  },
  getConfigurationMetadata(key, productType, index) {
    if (key) {
      return this.mConfigurations?.[productType]?.[index]?.metadata[key];
    }
    return this.mConfigurations[this.mCurrentConfigurationIndex]?.metadata;
  },
  getModelAccordions(catId) {
    let modelAccordion = {};
    const modelVariant = [];
    let modelParentId = null;

    for (const key in this.mLoadedData) {
      const obj = this.mLoadedData[key];
      if (obj.category_id === catId) {
        modelAccordion = Object.assign(
          modelAccordion,
          obj.component_groups[0].components[0]
        );
        modelParentId = obj.component_groups[0].components[0]?.id;
        modelVariant.push(
          obj.component_groups[0].components[0].available_variations[0]
        );
      }
    }

    modelVariant.forEach((item) => {
      item.component_id = modelParentId;
      return item;
    });
    modelAccordion.available_variations = modelVariant;
    return modelAccordion;
  },
  getLatestModelByProductType(productType) {
    if (!productType) {
      return null;
    }

    this.updateCurrentConfiguration();

    const configurations = this.mConfigurations[productType];
    const latestConfiguration =
      configurations[this.mCurrentConfigurationIndex[productType]].variations;

    const latestModel = this.findVariationByComponentName(
      latestConfiguration,
      AccordionEnum.Model
    );

    if (!latestModel) {
      return null;
    }

    const modelCategory = this.findModelCategoryByVariationId(latestModel);

    return { productType, modelCategory, latestModel };
  },
  unSelectCurrentModel() {
    this.mSelectedVariations.forEach((variationId) => {
      const variation = this.findVariationBy(variationId);
      const component = this.findComponentById(variation.component_id);
      if (component.name === "Model") {
        this.toggleVariationSelection(variationId);
      }
    });
  },
  getAllComponents(productType, category) {
    for (const key in this.mLoadedData) {
      const obj = this.mLoadedData[key];

      if (productType === "windows" && obj.name === "Window - Accessories") {
        const components = obj.component_groups?.map(
          (cGroup) => cGroup.components
        );
        this.mAllComponents = Array.prototype.concat.apply([], components);
      } else if (productType === "doors" && obj.name === "Door - Accessories") {
        const components = obj.component_groups?.map(
          (cGroup) => cGroup.components
        );
        this.mAllComponents = Array.prototype.concat.apply([], components);
      }
    }

    this.mAllComponents = this.mAllComponents.filter((component) => {
      if (
        category === "fixed" &&
        (component.name === AccordionEnum.Beslag ||
          component.name === AccordionEnum.Hinge ||
          component.name === AccordionEnum.Tillval)
      ) {
        return false;
      }
      return true;
    });

    this.mAllComponents.sort((c1, c2) => c1.layer_order - c2.layer_order);
    return this.mAllComponents;
  },
  getImagesVariations(productType) {
    return this.mImages[productType]?.component_groups[0].components[0]
      .available_variations;
  },
  extractComponentsAndVariations() {
    this.mComponents = [];
    this.mVariations = [];
    this.mProducts = [];
    this.mAccessories = [];

    for (const key in this.mLoadedData) {
      const obj = this.mLoadedData[key];
      if (!otherProducts.includes(obj.name)) {
        this.mProducts.push(obj);
      } else {
        this.mAccessories = obj;
      }
      if (obj.name === "Woodworks fonster") {
        this.mImages.windows = obj;
      }
      if (obj.name === "Woodworks dorrar") {
        this.mImages.doors = obj;
      }
      obj.component_groups.forEach((componentGroup) => {
        componentGroup.components.forEach((component) => {
          const tempComponent = {};
          Object.keys(component).forEach((k) => {
            tempComponent[k] = component[k];
          });
          delete tempComponent.available_variations;
          this.mComponents.push(tempComponent);
          component.available_variations.forEach((v) => {
            this.mVariations.push({
              ...v,
              product_category: this.findCategoryByIdAndName(
                obj.category_id,
                obj.name
              ),
              product_id: obj.id,
              product_category_id: obj.category_id,
            });
          });
        });
      });
    }
    this.mComponents.sort((c1, c2) => c1.layer_order - c2.layer_order);
  },
  getSortedComponents(components, productType) {
    if (!components || !productType) {
      return [];
    }
    if (productType !== "doors") {
      const indexOfLuft = components
        .map((c) => c.name)
        .indexOf(AccordionEnum.Luft_Types);

      if (indexOfLuft === -1) {
        return components;
      }

      const luftComponent = components.splice(indexOfLuft, 1)[0];
      components.splice(1, 0, luftComponent);
      return components;
    }

    const indexOfGlass = components
      .map((c) => c.name)
      .indexOf(AccordionEnum.Glass);

    if (indexOfGlass === -1) {
      return components;
    }

    const glassComponent = components.splice(indexOfGlass, 1)[0];
    components.splice(5, 0, glassComponent);

    return components;
  },
  loadedData() {
    return this.mLoadedData;
  },
  findCategoryByIdAndName(id, name) {
    if (WindowCategories.includes(id) || name === "Window - Accessories") {
      return "windows";
    }
    if (DoorCategories.includes(id) || name === "Door - Accessories") {
      return "doors";
    }

    return null;
  },
  findComponentByName(name) {
    return this.mAllComponents.find((component) => component.name === name);
  },
  findModelByName(name) {
    return this.mProducts.find((product) => product.name === name);
  },
  findVariationsByAssetType(assetType) {
    return this.mVariations.filter(
      (variation) => variation.assets[0]?.asset_type === assetType
    );
  },
  findModelCategoryByVariationId(variationId) {
    const model = this.mProducts.find(
      (product) =>
        product.component_groups[0].components[0].available_variations[0].id ===
        variationId
    );

    if (model) {
      return Object.keys(ModelVariants).find((variantName) => {
        return ModelVariants[variantName] === model.category_id;
      });
    }

    return ProductTypes.doors.key;
  },
  findModelDimensionsVariation(modelId, dimensions, productType) {
    const model = this.findModelByVariationId(modelId);
    const dimensionsVariations = model?.component_groups[1].components.find(
      (component) => component.name === "Size"
    )?.available_variations;

    const width = (
      dimensions.width / DimensionsMultiplier[productType]
    ).toString();
    const height = (
      dimensions.height / DimensionsMultiplier[productType]
    ).toString();

    return dimensionsVariations?.find(
      (variation) =>
        variation.assets[0].attribute_values[0].value === width &&
        variation.assets[0].attribute_values[1].value === height
    );
  },
  findModelByVariationId(variationId) {
    return this.mProducts.find(
      (product) =>
        product.component_groups[0].components[0].available_variations[0].id ===
        variationId
    );
  },
  getModelVariation(modelName) {
    const model = this.findModelByName(modelName);
    return model.component_groups[0].components[0].available_variations[0];
  },
  getAllModelsVariations(categories) {
    let products = this.mProducts;
    if (categories) {
      products = this.mProducts.filter((product) =>
        categories.includes(product.category_id)
      );
    }

    return products.map(
      (product) =>
        product.component_groups[0]?.components[0]?.available_variations[0]
    );
  },
  findParentComponents(excludeDependencyVariationIds) {
    const parentComponents = [];
    this.mComponents.forEach((component) => {
      const parentVariations = this.findParentVariationsForComponent(
        component.id,
        excludeDependencyVariationIds
      );
      if (
        parentVariations.length > 0 &&
        parentVariations.some((v) =>
          v.assets.some((a) => a.asset_type !== "png")
        )
      ) {
        parentComponents.push(component);
      }
    });
    return parentComponents;
  },
  findSelectedVariantByComponentName(name) {
    return this.getSelectedVariations().find(
      (variation) =>
        this.findComponentById(variation.component_id).name === name
    );
  },
  findFirstVariationByComponentNameAndCategory(name, category) {
    const component = this.mAllComponents.find(
      (component) => component.name === name
    );

    return component.available_variations.find(
      (variation) => variation.assets[0].attribute_values[1].value === category
    );
  },
  findNonAssetsParentComponents(excludeDependencyVariationIds) {
    const parentComponents = [];
    this.mComponents.forEach((component) => {
      const parentVariations = this.findParentVariationsForComponent(
        component.id,
        excludeDependencyVariationIds,
        false
      );
      if (
        parentVariations.length > 0 &&
        parentVariations.every(
          (v) =>
            v.assets.length === 0 ||
            (!v.is_default && v.assets.some((a) => a.asset_type === "png"))
        )
      ) {
        parentComponents.push(component);
      }
    });
    return parentComponents;
  },
  findVariationType(componentName) {
    componentName = componentName.toLowerCase();
    const key = `component_${componentName}_subaccordion_type`;
    return this.mApplicationData.sections[0].key_values[key];
  },
  findVariationBy(id) {
    return this.mVariations.find((v) => v.id === id);
  },
  findComponentById(id) {
    return this.mComponents.find((c) => c.id === id);
  },
  findComponentByVariationId(id) {
    const variation = this.findVariationBy(id);
    if (!variation) {
      return null;
    }
    return this.findComponentById(variation.component_id);
  },
  changeComponentId(id) {
    this.mComponents.forEach((element) => {
      if (element.name === "Color") {
        element.id = id;
      }
    });
  },
  findComponentVariations(id) {
    return this.mVariations.filter((v) => v.component_id === id);
  },
  findParentVariationsForComponent(
    id,
    excludeDependencyVariationIds,
    excludeWithoutAssets = true
  ) {
    const componentVariations = this.findComponentVariations(id);
    const variations = [];
    componentVariations.forEach((v) => {
      if (excludeWithoutAssets && v.assets.length === 0) {
        return;
      }
      if (v.dependencies.length === 0) {
        variations.push(v);
      }
      if (
        excludeDependencyVariationIds &&
        v.dependencies.find((tempV) =>
          excludeDependencyVariationIds.includes(tempV.depends_on_variation_id)
        )
      ) {
        variations.push(v);
      }
    });
    return variations;
  },
  findChildrenForVariation(id) {
    const childrenVariations = [];
    this.mVariations.forEach((v) => {
      if (v.dependencies.find((d) => d.depends_on_variation_id === id)) {
        childrenVariations.push(v);
      }
    });
    return childrenVariations;
  },
  findChildrenComponentsForVariation(variationId) {
    const variations = this.findChildrenForVariation(variationId);
    if (variations.length === 0) {
      return [];
    }

    const componentIds = [...new Set(variations.map((v) => v.component_id))];
    if (!componentIds) {
      return [];
    }
    const components = [];
    componentIds.forEach((cId) => components.push(this.findComponentById(cId)));

    return components;
  },
  isVariationSelected(id) {
    return this.mSelectedVariations.includes(id);
  },
  handleVariationLogic(id) {
    const variation = this.findVariationBy(id);
    const component = this.findComponentById(variation.component_id);

    if (
      component.name === AccordionEnum.Handle &&
      variation.original_key.includes("yale")
    ) {
      this.unselectVariationByComponentName(AccordionEnum.Cover_Plate);
      this.unselectVariationByComponentName(AccordionEnum.Lock);
      return;
    }

    if (
      component.name === AccordionEnum.Lock ||
      component.name === AccordionEnum.Cover_Plate
    ) {
      const handleVariation = this.findSelectedVariantByComponentName(
        AccordionEnum.Handle
      );
      if (handleVariation?.original_key.includes("yale")) {
        this.unselectVariationByComponentName(AccordionEnum.Handle);
      }
    }
  },
  toggleVariationSelection(id, action, ignoreHooks = false) {
    const oldSelectionState = [];
    if (!ignoreHooks) {
      this.mSelectedVariations.forEach((v) => oldSelectionState.push(v));
    }
    const index = this.mSelectedVariations.indexOf(id);
    const variation = this.findVariationBy(id);
    const component = this.findComponentById(variation?.component_id);
    let event;

    if (action !== "add" && (index !== -1 || action === "delete")) {
      if (index > -1) {
        if (
          !ignoreHooks &&
          component.required_choice &&
          this.isVariationSelected(id)
        ) {
          // if the component has only two variations switch to the other variations
          const componentVariations = this.mVariations.filter(
            (v) => v.component_id === component.id && v.id !== id
          );
          if (componentVariations.length === 1) {
            this.mSelectionHooks.forEach((cb) => {
              cb([id], "unselect");
            });
            this.mSelectedVariations.splice(index, 1);
            if (typeof this.mLoadedScripts[variation.id] !== "undefined") {
              this.mLoadedScripts[variation.id].onUnselect(variation, this);
            }
            this.toggleVariationSelection(componentVariations[0].id);
          } else {
            this.mSelectionHooks.forEach((cb) => {
              cb([id], "select");
            });

            let selectedVariation = this.getmLoadedDataSingleProduct(variation);

            if (
              selectedVariation.name === "Door - Accessories" ||
              selectedVariation.name === "Window - Accessories"
            ) {
              selectedVariation = this.getmLoadedDataSingleProduct(
                this.findVariationBy(this.getCurrentModel())
              );
            }

            if (selectedVariation) {
              this.logEvent("variation", variation.id, {
                product_id: selectedVariation.id,
                description: selectedVariation.product_info.description,
              });
            }
          }
          return;
        }
        this.mSelectedVariations.splice(index, 1);
        event = "unselect";
        if (typeof this.mLoadedScripts[variation.id] !== "undefined") {
          this.mLoadedScripts[variation.id].onUnselect(variation, this);
        }
      }
      if (!component.multiple && !ignoreHooks) {
        this.clearSameComponentSelection(id);
      }
      this.removeNestedVariationSelection(id);
    } else if (!action || action === "add") {
      this.mSelectedVariations.push(id);
      if (typeof this.mLoadedScripts[variation.id] !== "undefined") {
        this.mLoadedScripts[variation.id].onSelect(variation, this);
      }
      event = "select";
      if (!component.multiple) {
        this.clearSameComponentSelection(id);
        this.selectNestedDefaultVariation(id);
      }

      let selectedVariation = this.getmLoadedDataSingleProduct(variation);

      if (
        selectedVariation.name === "Door - Accessories" ||
        selectedVariation.name === "Window - Accessories"
      ) {
        selectedVariation = this.getmLoadedDataSingleProduct(
          this.findVariationBy(this.getCurrentModel())
        );
      }

      if (selectedVariation) {
        this.logEvent("variation", variation.id, {
          product_id: selectedVariation.id,
          description: selectedVariation.product_info.description,
        });
      }
    }
    if (!ignoreHooks) {
      this.mSelectionHooks.forEach((cb) => {
        cb([id], event);
      });

      const newSelectionState = [];
      this.mSelectedVariations.forEach((v) => newSelectionState.push(v));

      const jsKeys = Object.keys(this.mLoadedScripts);
      if (jsKeys.length > 0) {
        for (let idx = 0; idx < jsKeys.length; idx += 1) {
          this.mLoadedScripts[jsKeys[idx]].onSelectionChanged(
            id,
            oldSelectionState,
            newSelectionState,
            this
          );
        }
      }
    }
  },
  isVariationDependentOn(variation, onVariation) {
    return variation.dependencies.some(
      (v) => v.depends_on_variation_id === onVariation.id
    );
  },
  clearSameComponentSelection(id) {
    const selectedVariation = this.findVariationBy(id);
    const deletedVariationIds = [];
    for (let idx = 0; idx < this.mSelectedVariations.length; idx += 1) {
      if (this.mSelectedVariations[idx] !== id) {
        const variation = this.findVariationBy(this.mSelectedVariations[idx]);
        if (
          selectedVariation.component_id === variation.component_id &&
          !this.isVariationDependentOn(selectedVariation, variation)
        ) {
          this.mSelectedVariations.splice(idx - deletedVariationIds.length, 1);
          deletedVariationIds.push(variation.id);
        }
      }
    }
    deletedVariationIds.forEach((v) =>
      this.removeNestedVariationSelection(v, id)
    );
  },
  removeNestedVariationSelection(id, excludeVariation = 0) {
    const childrenComponents = this.findChildrenComponentsForVariation(id);

    if (childrenComponents) {
      childrenComponents.forEach((c) =>
        this.mVariations
          .filter((v) => v.component_id === c.id)
          .forEach((variationToDelete) => {
            if (
              this.mSelectedVariations.includes(variationToDelete.id) &&
              excludeVariation !== variationToDelete.id
            ) {
              this.toggleVariationSelection(
                variationToDelete.id,
                "delete",
                true
              );
            }
          })
      );
    }
  },
  unselectDependentVariations() {
    this.unselectVariationByComponentName(AccordionEnum.Size, true);
    this.unselectVariationByComponentName(AccordionEnum.Luft_Types, true);
    this.unselectVariationByComponentName(AccordionEnum.Beslag, true);
    this.unselectVariationByComponentName(AccordionEnum.Hinge, true);
    this.unselectVariationByComponentName(AccordionEnum.Ventilation, true);
    this.unselectVariationByComponentName(AccordionEnum.Kickplate, true);
  },
  selectNestedDefaultVariation(id) {
    const childrenComponents = this.findChildrenComponentsForVariation(id);

    if (childrenComponents) {
      childrenComponents.forEach((c) => {
        if (c.required_choice) {
          const componentVariations = this.findComponentVariations(c.id);
          componentVariations.forEach((cv) => {
            if (cv.is_default && !this.isVariationSelected(cv.id)) {
              this.toggleVariationSelection(cv.id, "add", true);
            }
          });
        }
      });
    }
  },
  getSelectedVariations(filterOut = []) {
    const selectedVariations = [];
    this.mSelectedVariations.forEach((vId) =>
      selectedVariations.push(this.findVariationBy(vId))
    );

    if (filterOut.length) {
      return selectedVariations.filter((variation) => {
        return !this.hasSubstring(filterOut, variation);
      });
    }

    return selectedVariations;
  },
  hasSubstring(substrings, variation) {
    const component = this.findComponentById(variation.component_id);
    return substrings.some((componentNameSubstring) =>
      component.name.includes(componentNameSubstring)
    );
  },
  findVariationByComponentName(variations, cName) {
    return variations.find((variation) => {
      const component = variation.component_id
        ? this.findComponentById(variation.component_id)
        : this.findComponentById(this.findVariationBy(variation).component_id);
      return component?.name === cName;
    });
  },
  extractModelDescription(variations, index) {
    if (!variations) {
      return "";
    }
    const descriptionItems = [];

    const dimensionsVariation = this.findVariationByComponentName(
      variations,
      AccordionEnum.Size
    );

    const modelVariation = this.findVariationByComponentName(
      variations,
      AccordionEnum.Model
    );

    if (!modelVariation) {
      return;
    }

    const modelCategory = this.findModelCategoryByVariationId(
      modelVariation.id
    );

    const productType = WindowCategories.includes(ModelVariants[modelCategory])
      ? "windows"
      : "doors";

    const dimensionsMultiplier = WindowCategories.includes(
      ModelVariants[modelCategory]
    )
      ? 1
      : 100;

    if (dimensionsVariation) {
      const width =
        Number(dimensionsVariation.assets?.[0]?.attribute_values?.[0]?.value) *
          dimensionsMultiplier -
        20;
      const height = `${
        Number(dimensionsVariation.assets?.[0]?.attribute_values?.[1]?.value) *
          dimensionsMultiplier -
        20
      }mm`;

      descriptionItems.push(`${width}x${height}`);
    } else {
      if (
        this.getConfigurationMetadata("customDimensions", productType, index)
      ) {
        descriptionItems.push(
          this.getConfigurationMetadata("customDimensions", productType, index)
        );
      }
    }

    const colorOutsideVariation = this.findVariationByComponentName(
      variations,
      AccordionEnum.Color
    );
    const colorInsideVariation = this.findVariationByComponentName(
      variations,
      AccordionEnum.Color_Inside
    );

    const hingeVariation = this.findVariationByComponentName(
      variations,
      "Hinge Types"
    );

    if (colorInsideVariation) {
      descriptionItems.push(
        this.sectionKey(
          "Product Descriptions",
          colorInsideVariation.original_key
        )
      );
    }

    if (colorOutsideVariation) {
      descriptionItems.push(
        this.sectionKey(
          "Product Descriptions",
          colorOutsideVariation.original_key
        )
      );
    }

    if (hingeVariation) {
      descriptionItems.push(
        this.sectionKey("Translations", hingeVariation.original_key)
      );
    } else {
      descriptionItems.push(
        this.sectionKey(
          "Translations",
          sessionStorage.getItem("hangingVariant")
        )
      );
    }

    return descriptionItems.join(", ");
  },
  unselectVariationByComponentName(cName, ignoreHooks = false) {
    const variations = this.getSelectedVariations();
    const componentVariation = this.findVariationByComponentName(
      variations,
      cName
    );
    if (componentVariation && this.isVariationSelected(componentVariation.id)) {
      this.toggleVariationSelection(componentVariation.id, null, ignoreHooks);
    }
  },
  clearCustomDimensions() {
    this.mCustomDimensions = null;
  },
  isVariationUnselectable(id) {
    const variation = this.findVariationBy(id);
    if (!variation) {
      return true;
    }

    const component = this.findComponentById(variation.component_id);
    if (!component) {
      return true;
    }

    if (
      variation.is_default &&
      component.required_choice &&
      this.mVariations.filter((v) => v.component_id === component.id).length < 2
    ) {
      return false;
    }

    return true;
  },
  generateShareableLink(baseLink, isArLink = false) {
    const base = baseLink || window.location.href?.split("?")[0];
    const ARPart = isArLink ? "&ar=ok" : "";
    return `${base}?s=${this.generateUniqueHashForSelections()}${ARPart}`
  },
  generateUniqueHashForSelections() {
    const content = {
      dateTime: new Date().getTime(),
      selections: this.mConfigurations,
      currentIndex: this.mCurrentConfigurationIndex,
    };

    return btoa(JSON.stringify(content));
  },
  loadSelectionFromHash(hash) {
    const productType = location.hash.split("/")[1];
    try {
      const content = JSON.parse(atob(hash));
      this.mCurrentConfigurationIndex[productType] =
        content.currentIndex[productType];
      this.mSelectedVariations = [];
      this.mConfigurations = content.selections;
      if (content.selections) {
        content.selections[productType]?.[
          this.mCurrentConfigurationIndex[productType]
        ]?.variations?.forEach((s) => this.mSelectedVariations.push(s));
        this.mSelectionHooks.forEach((cb) =>
          cb(this.mSelectedVariations, "select")
        );
      }
    } catch (e) {
      // silient exceptioon
    }
  },
  submitForm(data, callback) {
    axios
      .post("https://onecx.rapidimages.com/api/v1/contacts", data, {
        headers: {
          "X-Header-ApiKey": this._apiKey,
          "X-Header-AppId": this._applicationId,
        },
      })
      .then((r) => {
        if (callback) {
          callback(r);
        }
      })
      .catch((err) => {
        if (callback) {
          callback(err);
        }
      });
  },
  uuidv4() {
    // eslint-disable-next-line no-mixed-operators
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  },
  findSelectedVariantByComponentName(name) {
    return this.getSelectedVariations().find(
      (variation) =>
        this.findComponentById(variation.component_id).name === name
    );
  },
  logEvent(event, variation, taglist) {
    if (!this.mSessionId && !this.mVisitorId) {
      this.mSessionId = this.uuidv4();
      this.mVisitorId = this.uuidv4();
    }

    axios.post(
      "https://one-ins.onecx.rapidimages.com/api/logs/analytics/analyticTrack",
      {
        e: event,
        e_v: variation,
        a: this._applicationId,
        s: this.mSessionId,
        v: this.mVisitorId,
        tags: taglist,
      }
    );
  },
  getCategoryOfCurrentModel() {
    return this.findModelCategoryByVariationId(this.getCurrentModel());
  },
  switchSelectedVariations(newProductType, oldProductType) {
    this.mProductTypeSelectedVariations[oldProductType] =
      this.mSelectedVariations;
    this.mSelectedVariations =
      this.mProductTypeSelectedVariations[newProductType];
  },
  getDeviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  },
  getmLoadedDataSingleProduct(variation) {
    return this.mLoadedData.find((x) => x.id === variation.product_id);
  },
};
