import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { Header } from "../components/header/Header";
import { List } from "../components/list/List";
import { Logo } from "../components/logo/Logo";
import { ProductIntro } from "../components/productIntro/";
import { DataContainerContext } from "../common/context/DataContainerProvider";
import { CategoryIds, configuratorKeys } from "../services/enums";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import Loading from "../components/loading/Loading";
import "../components/productIntro/style.css";

function HomePage() {
  const [isSwitch, setIsSwitch] = useState(0);
  const [selectedCategory, setSelectedCategory] =
    useState<{ id: number; name: string }>();
  const navigate = useNavigate();
  const [title, setTitle] = useState<string>("");
  const location = useLocation();

  const productType = location.pathname === "/" ? "doors" : "windows";

  const { dataContainer } = useContext(DataContainerContext);

  useEffect(() => {
    if (dataContainer) {
      dataContainer
        .getSelectedVariations()
        .forEach((variation) =>
          dataContainer.toggleVariationSelection(variation.id, "delete")
        );

      dataContainer?.mConfigurations["doors"].map((item) => {
        item.metadata.quantity = 1;
        return item;
      });

      dataContainer?.mConfigurations["windows"].map((item) => {
        item.metadata.quantity = 1;
        return item;
      });

      dataContainer.logEvent("page_entered", "homepage", {product: ""});
    }
  }, [dataContainer]);

  useEffect(() => {
    if (productType === "windows") {
      const window_title = dataContainer?.sectionKey(
        "Translations",
        "homepage_list_window_title"
      );
      setTitle(window_title);
    } else {
      const door_title = dataContainer?.sectionKey(
        "Translations",
        "homepage_list_door_title"
      );
      setTitle(door_title);
    }
  });

  useEffect(() => {
    const categories = Object.keys(CategoryIds[productType]);
    setSelectedCategory({
      id: CategoryIds[productType][categories[0]].id,
      name: categories[0],
    });
  }, [productType]);

  const products = dataContainer?.getAllModelsVariations([
    selectedCategory?.id,
  ]);

  useEffect(() => {
    sessionStorage.removeItem("hangingVariant");
  }, []);

  const productData = (
    id: number,
    image: string,
    name: string,
    category: string,
    original_key
  ) => ({
    title: name,
    description: dataContainer?.sectionKey(
      productType === "doors"
        ? configuratorKeys.doorsDescription
        : configuratorKeys.windowsDescription,
      original_key
    ),
    button: {
      onClick: () => navigate(`/${productType}/${category}/${id}`),
      text: "VÄLJ DENNA",
    },
    image: {
      title: "door image",
      src: image,
    },
  });

  const productCategories = Object.keys(CategoryIds[productType]).map(
    (productCategory) => {
      const value = {
        id: CategoryIds[productType][productCategory].id,
        value: productCategory,
      };
      return {
        id: value.id,
        name: dataContainer
          ?.sectionKey(
            "Translations",
            CategoryIds[productType][productCategory].name
              .replaceAll(" ", "_")
              .toLowerCase()
          )
          .toUpperCase(),
        value,
      };
    }
  );

  return (
    <div className="w-full h-full bg-smoke flex flex-col sm:flex-row gap-5 overflow-auto">
      {dataContainer ? (
        <>
          <div className="sm:relative w-full sm:w-1/3">
            <div className="pt-6 sm:pt-0 sm:fixed sm:top-0 left-0 h-full sm:w-1/3 flex items-center sm:items-start justify-between flex-col pb-5">
              <Header
                className="w-full sm:order-none order-1"
                toggleMenu={() =>
                  navigate(
                    `/models/${productType}/${
                      productType === "doors" ? "single" : "side"
                    }`
                  )
                }
              />
              <div className="sm:px-10 px-0 sm:pt-0 pt-8 sm:order-none order-3">
                <List
                  listOnChange={(value) => {
                    setSelectedCategory({ id: value.id, name: value.value });
                    setIsSwitch(isSwitch ? 0 : 1);
                  }}
                  items={productCategories}
                  title={title}
                />
              </div>
              <Logo to="/" className="ml-10 hidden sm:flex" />
            </div>
          </div>
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={isSwitch}
              addEndListener={(node: HTMLElement, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="switch"
            >
              <div className="relative sm:w-2/3 w-full sm:overflow-x-hidden sm:pt-48 mt flex flex-col gap-[8rem] md:gap-[6rem] pb-32">
                {products?.map((product, idx) => (
                  <ProductIntro
                    key={product?.id + " " + idx}
                    primaryProductData={productData(
                      product?.id,
                      product?.assets?.[0].preview_image_url,
                      product?.key,
                      selectedCategory?.name,
                      product.original_key
                    )}
                  />
                ))}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </>
      ) : (
        <div className="h-screen w-screen flex flex-col items-center justify-center sm:text-6xl">
          <Loading productType="none" />
        </div>
      )}
    </div>
  );
}

export { HomePage };
