import { AbstractAssetTask, AbstractMesh, MeshAssetTask, Vector3 } from "@babylonjs/core";
import { AccordionEnum } from "src/services/enums";
import {
  getSprojsNodes,
  getSprojsVerticalNodes,
} from "./AirDividersHandlers";
import { getDoorWindowMisc, getWindowMiscs } from "./BeslagHandlers";

const horizontalSprojs = {
  sp11: "sp10",
  sp12: "sp10",
  sp21: "sp20",
  sp22: "sp20",
  sp31: "sp30",
  sp32: "sp30",
} as const;

const visibleSprojs = {
  "1a": ["0"],
  "1b": ["0"],
  "1_1a": ["0", "1"],
  "1_1b": ["0","1"],
  "2": ["0","1"],
  "2_2": ["0","1", "2", "3"],
  "3a": ["0","1", "2"],
  "3b": ["0","1", "2"],
  "3_3a": ["0","1", "2", "3", "4", "5"],
  "3_3b": ["0","1", "2", "3", "4", "5"],
};

let allMeshes: AbstractMesh[] = [];

export const saveWindowSprojsData = (tasks: AbstractAssetTask[]) => {
  allMeshes = [];

  tasks.forEach((task, index) => {
    if (task.name.includes(AccordionEnum.Sprojs)) {
      const assetTask = task as MeshAssetTask;
      const taskNameParts = task.name.split(" - ");
      const variationName = taskNameParts?.[1];

      if (!variationName) {
        return;
      }

      assetTask?.loadedMeshes?.forEach((mesh) => {
        allMeshes.push(mesh);

        if (!mesh.metadata) {
          mesh.metadata = {};
        }

        const sprojsPlacement = taskNameParts[1].split("-");
        mesh.metadata.id = taskNameParts?.[0];
        mesh.metadata.task = taskNameParts?.[1].split("-")[0];
        mesh.metadata.sprojsOrder = variationName.split("-").length > 1 ? sprojsPlacement[1] : "0";

        if(!taskNameParts?.[3]){
          mesh.metadata.cId = tasks[index + 1]?.name.split(" - ")[3];
        }else{
          mesh.metadata.cId = taskNameParts?.[3];
        } 
      });
    }
  });
};

const hideAllMeshes = () => {
  allMeshes?.forEach((mesh) => (mesh.isVisible = false));
};

const showMeshes = (sprojsVariation, luftType) => {
  const visibleHingeTypes = visibleSprojs[luftType] || [];
  const sprojsVariationName = sprojsVariation?.original_key;
  const sprojsVariationId = sprojsVariation?.id;
  const sprojsVariationCid = sprojsVariation?.component_id;

  allMeshes.forEach((mesh) => {
    if ((sprojsVariationName === mesh.metadata.task &&
    visibleHingeTypes.includes(mesh.metadata.sprojsOrder) &&
    sprojsVariationId === Number(mesh.metadata.id)) || (
      horizontalSprojs[sprojsVariationName] === mesh.metadata.task &&
      sprojsVariationCid === Number(mesh.metadata.cId) &&
      visibleHingeTypes.includes(mesh.metadata.sprojsOrder)
    )) {
      mesh.isVisible = true;
    }
  });
};


export const handleSprojsPositions = (scene, dataContainer) => {
  hideAllMeshes();

  const sprojsType = dataContainer?.findSelectedVariantByComponentName(AccordionEnum.Sprojs);

  if (!sprojsType) {
    return;
  }

  const luftTypeVariation = dataContainer?.findSelectedVariantByComponentName(
    AccordionEnum.Luft_Types
  );
  const miscNodes = getWindowMiscs(scene)?.firstMisc
    ? getWindowMiscs(scene)
    : getDoorWindowMisc(scene);
  const luftType = luftTypeVariation ? luftTypeVariation.original_key : "1a";

  showMeshes(sprojsType, luftType);
  handlePositions(miscNodes, luftType, scene, sprojsType);
};

const handlePositions = (miscNodes, luftType, scene, sprojsType) => {
  switch (luftType) {
  case "1a":
  case "1b": 
    handleSingleMiscWindows(miscNodes, scene, sprojsType);
    break;
  case "2":
  case "1_1a":
  case "1_1b":
    handleDoubleMiscWindows(miscNodes, scene, sprojsType);
    break;
  case "2_2":
    handleFourMiscWindows(miscNodes, scene, sprojsType);
    break;
  case "3a":
  case "3b":
    handleTripleMiscWindows(miscNodes, scene, sprojsType);
    break;
  case "3_3a":
  case "3_3b":
    handleSixMiscWindows(miscNodes, scene, sprojsType);
    break;
  }
};

const handleSingleMiscWindows = (miscNodes, scene, sprojsType) => {
  const firstMisc = miscNodes?.firstMisc;
  const firstHorizon2Sprojs = getSprojsNodes(horizontalSprojs[sprojsType?.key], scene, "0");
  const firstHorizonSprojs = getSprojsNodes(sprojsType?.original_key, scene, "0");
  const firstVerticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "0");

  if(firstMisc && firstHorizonSprojs){
    firstHorizonSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstHorizonSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstHorizonSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstHorizonSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );
  }

  if(firstMisc && firstVerticalSprojs && firstHorizon2Sprojs){
    firstHorizon2Sprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstHorizon2Sprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstHorizon2Sprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstHorizon2Sprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );

    firstVerticalSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstVerticalSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstVerticalSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );
  }

  if(firstMisc && firstVerticalSprojs){
    firstVerticalSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstVerticalSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstVerticalSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );
  }

};

const handleDoubleMiscWindows = (miscNodes, scene, sprojsType) => {
  const firstMisc = miscNodes?.firstMisc;
  const secondMisc = miscNodes?.secondMisc;

  const firstHorizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "0");
  const firstHorizon2Sprojs = getSprojsNodes(sprojsType?.original_key, scene, "0");
  const firstVerticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "0");

  const horizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "1");
  const horizon2Sprojs = getSprojsNodes(sprojsType?.original_key, scene, "1");
  const verticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "1");

  if(firstMisc && firstVerticalSprojs && firstHorizonSprojs){
    firstHorizonSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstHorizonSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstHorizonSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstHorizonSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );

    firstVerticalSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstVerticalSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstVerticalSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );
  }

  if(firstMisc && firstVerticalSprojs){
    firstVerticalSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstVerticalSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstVerticalSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );
  }

  if(firstMisc && firstHorizon2Sprojs){
    firstHorizon2Sprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstHorizon2Sprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstHorizon2Sprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstHorizon2Sprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );
  }

  if(secondMisc && verticalSprojs && horizonSprojs){
    horizonSprojs.topLeftSprojs.position = new Vector3(
      secondMisc?.topLeftMisc.position.x,
      secondMisc?.topLeftMisc.position.y
    );
    horizonSprojs.bottomRightSprojs.position = new Vector3(
      secondMisc?.bottomRightMisc.position.x,
      secondMisc?.bottomRightMisc.position.y
    );
    horizonSprojs.topRightSprojs.position = new Vector3(
      secondMisc?.topRightMisc.position.x,
      secondMisc?.topRightMisc.position.y
    );
    horizonSprojs.bottomLeftSprojs.position = new Vector3(
      secondMisc?.bottomLeftMisc.position.x,
      secondMisc?.bottomLeftMisc.position.y
    );

    verticalSprojs.topLeftSprojs.position = new Vector3(
      secondMisc?.topLeftMisc.position.x,
      secondMisc?.topLeftMisc.position.y
    );
    verticalSprojs.bottomRightSprojs.position = new Vector3(
      secondMisc?.bottomRightMisc.position.x,
      secondMisc?.bottomRightMisc.position.y
    );
    verticalSprojs.topRightSprojs.position = new Vector3(
      secondMisc?.topRightMisc.position.x,
      secondMisc?.topRightMisc.position.y
    );
    verticalSprojs.bottomLeftSprojs.position = new Vector3(
      secondMisc?.bottomLeftMisc.position.x,
      secondMisc?.bottomLeftMisc.position.y
    );
  }

  if(secondMisc && horizon2Sprojs){
    horizon2Sprojs.topLeftSprojs.position = new Vector3(
      secondMisc?.topLeftMisc.position.x,
      secondMisc?.topLeftMisc.position.y
    );
    horizon2Sprojs.bottomRightSprojs.position = new Vector3(
      secondMisc?.bottomRightMisc.position.x,
      secondMisc?.bottomRightMisc.position.y
    );
    horizon2Sprojs.topRightSprojs.position = new Vector3(
      secondMisc?.topRightMisc.position.x,
      secondMisc?.topRightMisc.position.y
    );
    horizon2Sprojs.bottomLeftSprojs.position = new Vector3(
      secondMisc?.bottomLeftMisc.position.x,
      secondMisc?.bottomLeftMisc.position.y
    );
  }

  if(secondMisc && verticalSprojs){
    verticalSprojs.topLeftSprojs.position = new Vector3(
      secondMisc?.topLeftMisc.position.x,
      secondMisc?.topLeftMisc.position.y
    );
    verticalSprojs.bottomRightSprojs.position = new Vector3(
      secondMisc?.bottomRightMisc.position.x,
      secondMisc?.bottomRightMisc.position.y
    );
    verticalSprojs.topRightSprojs.position = new Vector3(
      secondMisc?.topRightMisc.position.x,
      secondMisc?.topRightMisc.position.y
    );
    verticalSprojs.bottomLeftSprojs.position = new Vector3(
      secondMisc?.bottomLeftMisc.position.x,
      secondMisc?.bottomLeftMisc.position.y
    );
  }

};

const handleFourMiscWindows = (miscNodes, scene, sprojsType) => {
  const firstMisc = miscNodes?.firstMisc;
  const secondMisc = miscNodes?.secondMisc;
  const thirdMisc = miscNodes?.thirdMisc;
  const fourthMisc = miscNodes?.fourthMisc;

  const firstHorizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "0");
  const firstHorizon2Sprojs = getSprojsNodes(sprojsType?.original_key, scene, "0");
  const firstVerticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "0");

  const horizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "1");
  const horizon2Sprojs = getSprojsNodes(sprojsType?.original_key, scene, "1");
  const verticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "1");

  const thirdHorizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "2");
  const thirdHorizon2Sprojs = getSprojsNodes(sprojsType?.original_key, scene, "2");
  const thirdVerticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "2");

  const fourthHorizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "3");
  const fourthHorizon2Sprojs = getSprojsNodes(sprojsType?.original_key, scene, "3");
  const fourthVerticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "3");

  if(firstMisc && firstVerticalSprojs && firstHorizonSprojs){
    firstHorizonSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstHorizonSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstHorizonSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstHorizonSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );

    firstVerticalSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstVerticalSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstVerticalSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );
  }

  if(firstMisc && firstHorizon2Sprojs){
    firstHorizon2Sprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstHorizon2Sprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstHorizon2Sprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstHorizon2Sprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );
  }

  if(secondMisc && verticalSprojs && horizonSprojs){
    horizonSprojs.topLeftSprojs.position = new Vector3(
      secondMisc?.topLeftMisc.position.x,
      secondMisc?.topLeftMisc.position.y
    );
    horizonSprojs.bottomRightSprojs.position = new Vector3(
      secondMisc?.bottomRightMisc.position.x,
      secondMisc?.bottomRightMisc.position.y
    );
    horizonSprojs.topRightSprojs.position = new Vector3(
      secondMisc?.topRightMisc.position.x,
      secondMisc?.topRightMisc.position.y
    );
    horizonSprojs.bottomLeftSprojs.position = new Vector3(
      secondMisc?.bottomLeftMisc.position.x,
      secondMisc?.bottomLeftMisc.position.y
    );

    verticalSprojs.topLeftSprojs.position = new Vector3(
      secondMisc?.topLeftMisc.position.x,
      secondMisc?.topLeftMisc.position.y
    );
    verticalSprojs.bottomRightSprojs.position = new Vector3(
      secondMisc?.bottomRightMisc.position.x,
      secondMisc?.bottomRightMisc.position.y
    );
    verticalSprojs.topRightSprojs.position = new Vector3(
      secondMisc?.topRightMisc.position.x,
      secondMisc?.topRightMisc.position.y
    );
    verticalSprojs.bottomLeftSprojs.position = new Vector3(
      secondMisc?.bottomLeftMisc.position.x,
      secondMisc?.bottomLeftMisc.position.y
    );
  }

  if(secondMisc && horizon2Sprojs){
    horizon2Sprojs.topLeftSprojs.position = new Vector3(
      secondMisc?.topLeftMisc.position.x,
      secondMisc?.topLeftMisc.position.y
    );
    horizon2Sprojs.bottomRightSprojs.position = new Vector3(
      secondMisc?.bottomRightMisc.position.x,
      secondMisc?.bottomRightMisc.position.y
    );
    horizon2Sprojs.topRightSprojs.position = new Vector3(
      secondMisc?.topRightMisc.position.x,
      secondMisc?.topRightMisc.position.y
    );
    horizon2Sprojs.bottomLeftSprojs.position = new Vector3(
      secondMisc?.bottomLeftMisc.position.x,
      secondMisc?.bottomLeftMisc.position.y
    );
  }

  if(thirdMisc && thirdVerticalSprojs && thirdHorizonSprojs){
    thirdHorizonSprojs.topLeftSprojs.position = new Vector3(
      thirdMisc?.topLeftMisc.position.x,
      thirdMisc?.topLeftMisc.position.y
    );
    thirdHorizonSprojs.bottomRightSprojs.position = new Vector3(
      thirdMisc?.bottomRightMisc.position.x,
      thirdMisc?.bottomRightMisc.position.y
    );
    thirdHorizonSprojs.topRightSprojs.position = new Vector3(
      thirdMisc?.topRightMisc.position.x,
      thirdMisc?.topRightMisc.position.y
    );
    thirdHorizonSprojs.bottomLeftSprojs.position = new Vector3(
      thirdMisc?.bottomLeftMisc.position.x,
      thirdMisc?.bottomLeftMisc.position.y
    );

    thirdVerticalSprojs.topLeftSprojs.position = new Vector3(
      thirdMisc?.topLeftMisc.position.x,
      thirdMisc?.topLeftMisc.position.y
    );
    thirdVerticalSprojs.bottomRightSprojs.position = new Vector3(
      thirdMisc?.bottomRightMisc.position.x,
      thirdMisc?.bottomRightMisc.position.y
    );
    thirdVerticalSprojs.topRightSprojs.position = new Vector3(
      thirdMisc?.topRightMisc.position.x,
      thirdMisc?.topRightMisc.position.y
    );
    thirdVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      thirdMisc?.bottomLeftMisc.position.x,
      thirdMisc?.bottomLeftMisc.position.y
    );
  }

  if(thirdMisc && thirdHorizon2Sprojs){
    thirdHorizon2Sprojs.topLeftSprojs.position = new Vector3(
      thirdMisc?.topLeftMisc.position.x,
      thirdMisc?.topLeftMisc.position.y
    );
    thirdHorizon2Sprojs.bottomRightSprojs.position = new Vector3(
      thirdMisc?.bottomRightMisc.position.x,
      thirdMisc?.bottomRightMisc.position.y
    );
    thirdHorizon2Sprojs.topRightSprojs.position = new Vector3(
      thirdMisc?.topRightMisc.position.x,
      thirdMisc?.topRightMisc.position.y
    );
    thirdHorizon2Sprojs.bottomLeftSprojs.position = new Vector3(
      thirdMisc?.bottomLeftMisc.position.x,
      thirdMisc?.bottomLeftMisc.position.y
    );
  }

  if(fourthMisc && fourthVerticalSprojs && fourthHorizonSprojs){
    fourthHorizonSprojs.topLeftSprojs.position = new Vector3(
      fourthMisc?.topLeftMisc.position.x,
      fourthMisc?.topLeftMisc.position.y
    );
    fourthHorizonSprojs.bottomRightSprojs.position = new Vector3(
      fourthMisc?.bottomRightMisc.position.x,
      fourthMisc?.bottomRightMisc.position.y
    );
    fourthHorizonSprojs.topRightSprojs.position = new Vector3(
      fourthMisc?.topRightMisc.position.x,
      fourthMisc?.topRightMisc.position.y
    );
    fourthHorizonSprojs.bottomLeftSprojs.position = new Vector3(
      fourthMisc?.bottomLeftMisc.position.x,
      fourthMisc?.bottomLeftMisc.position.y
    );

    fourthVerticalSprojs.topLeftSprojs.position = new Vector3(
      fourthMisc?.topLeftMisc.position.x,
      fourthMisc?.topLeftMisc.position.y
    );
    fourthVerticalSprojs.bottomRightSprojs.position = new Vector3(
      fourthMisc?.bottomRightMisc.position.x,
      fourthMisc?.bottomRightMisc.position.y
    );
    fourthVerticalSprojs.topRightSprojs.position = new Vector3(
      fourthMisc?.topRightMisc.position.x,
      fourthMisc?.topRightMisc.position.y
    );
    fourthVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      fourthMisc?.bottomLeftMisc.position.x,
      fourthMisc?.bottomLeftMisc.position.y
    );
  }

  if(fourthMisc && fourthHorizon2Sprojs){
    fourthHorizon2Sprojs.topLeftSprojs.position = new Vector3(
      fourthMisc?.topLeftMisc.position.x,
      fourthMisc?.topLeftMisc.position.y
    );
    fourthHorizon2Sprojs.bottomRightSprojs.position = new Vector3(
      fourthMisc?.bottomRightMisc.position.x,
      fourthMisc?.bottomRightMisc.position.y
    );
    fourthHorizon2Sprojs.topRightSprojs.position = new Vector3(
      fourthMisc?.topRightMisc.position.x,
      fourthMisc?.topRightMisc.position.y
    );
    fourthHorizon2Sprojs.bottomLeftSprojs.position = new Vector3(
      fourthMisc?.bottomLeftMisc.position.x,
      fourthMisc?.bottomLeftMisc.position.y
    );
  }

  if(firstMisc && firstVerticalSprojs){
    firstVerticalSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstVerticalSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstVerticalSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );
  }

  if(secondMisc && verticalSprojs){
    verticalSprojs.topLeftSprojs.position = new Vector3(
      secondMisc?.topLeftMisc.position.x,
      secondMisc?.topLeftMisc.position.y
    );
    verticalSprojs.bottomRightSprojs.position = new Vector3(
      secondMisc?.bottomRightMisc.position.x,
      secondMisc?.bottomRightMisc.position.y
    );
    verticalSprojs.topRightSprojs.position = new Vector3(
      secondMisc?.topRightMisc.position.x,
      secondMisc?.topRightMisc.position.y
    );
    verticalSprojs.bottomLeftSprojs.position = new Vector3(
      secondMisc?.bottomLeftMisc.position.x,
      secondMisc?.bottomLeftMisc.position.y
    );
  }

  if(thirdMisc && thirdVerticalSprojs){
    thirdVerticalSprojs.topLeftSprojs.position = new Vector3(
      thirdMisc?.topLeftMisc.position.x,
      thirdMisc?.topLeftMisc.position.y
    );
    thirdVerticalSprojs.bottomRightSprojs.position = new Vector3(
      thirdMisc?.bottomRightMisc.position.x,
      thirdMisc?.bottomRightMisc.position.y
    );
    thirdVerticalSprojs.topRightSprojs.position = new Vector3(
      thirdMisc?.topRightMisc.position.x,
      thirdMisc?.topRightMisc.position.y
    );
    thirdVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      thirdMisc?.bottomLeftMisc.position.x,
      thirdMisc?.bottomLeftMisc.position.y
    );
  }

  if(fourthMisc && fourthVerticalSprojs){
    fourthVerticalSprojs.topLeftSprojs.position = new Vector3(
      fourthMisc?.topLeftMisc.position.x,
      fourthMisc?.topLeftMisc.position.y
    );
    fourthVerticalSprojs.bottomRightSprojs.position = new Vector3(
      fourthMisc?.bottomRightMisc.position.x,
      fourthMisc?.bottomRightMisc.position.y
    );
    fourthVerticalSprojs.topRightSprojs.position = new Vector3(
      fourthMisc?.topRightMisc.position.x,
      fourthMisc?.topRightMisc.position.y
    );
    fourthVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      fourthMisc?.bottomLeftMisc.position.x,
      fourthMisc?.bottomLeftMisc.position.y
    );
  }

};

const handleTripleMiscWindows = (miscNodes, scene, sprojsType) => {
  const firstMisc = miscNodes?.firstMisc;
  const secondMisc = miscNodes?.secondMisc;
  const thirdMisc = miscNodes?.thirdMisc;

  const firstHorizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "0");
  const firstVerticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "0");

  const horizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "1");
  const verticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "1");

  const thirdHorizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "2");
  const thirdVerticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "2");

  if(firstMisc && firstVerticalSprojs && firstHorizonSprojs){
    firstHorizonSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstHorizonSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstHorizonSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstHorizonSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );

    firstVerticalSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstVerticalSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstVerticalSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );
  }

  if(firstMisc && firstVerticalSprojs){
    firstVerticalSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstVerticalSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstVerticalSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );
  }

  if(secondMisc && verticalSprojs && horizonSprojs){
    horizonSprojs.topLeftSprojs.position = new Vector3(
      secondMisc?.topLeftMisc.position.x,
      secondMisc?.topLeftMisc.position.y
    );
    horizonSprojs.bottomRightSprojs.position = new Vector3(
      secondMisc?.bottomRightMisc.position.x,
      secondMisc?.bottomRightMisc.position.y
    );
    horizonSprojs.topRightSprojs.position = new Vector3(
      secondMisc?.topRightMisc.position.x,
      secondMisc?.topRightMisc.position.y
    );
    horizonSprojs.bottomLeftSprojs.position = new Vector3(
      secondMisc?.bottomLeftMisc.position.x,
      secondMisc?.bottomLeftMisc.position.y
    );

    verticalSprojs.topLeftSprojs.position = new Vector3(
      secondMisc?.topLeftMisc.position.x,
      secondMisc?.topLeftMisc.position.y
    );
    verticalSprojs.bottomRightSprojs.position = new Vector3(
      secondMisc?.bottomRightMisc.position.x,
      secondMisc?.bottomRightMisc.position.y
    );
    verticalSprojs.topRightSprojs.position = new Vector3(
      secondMisc?.topRightMisc.position.x,
      secondMisc?.topRightMisc.position.y
    );
    verticalSprojs.bottomLeftSprojs.position = new Vector3(
      secondMisc?.bottomLeftMisc.position.x,
      secondMisc?.bottomLeftMisc.position.y
    );
  }

  if(secondMisc && verticalSprojs){
    verticalSprojs.topLeftSprojs.position = new Vector3(
      secondMisc?.topLeftMisc.position.x,
      secondMisc?.topLeftMisc.position.y
    );
    verticalSprojs.bottomRightSprojs.position = new Vector3(
      secondMisc?.bottomRightMisc.position.x,
      secondMisc?.bottomRightMisc.position.y
    );
    verticalSprojs.topRightSprojs.position = new Vector3(
      secondMisc?.topRightMisc.position.x,
      secondMisc?.topRightMisc.position.y
    );
    verticalSprojs.bottomLeftSprojs.position = new Vector3(
      secondMisc?.bottomLeftMisc.position.x,
      secondMisc?.bottomLeftMisc.position.y
    );
  }

  if(thirdMisc && thirdVerticalSprojs && thirdHorizonSprojs){
    thirdHorizonSprojs.topLeftSprojs.position = new Vector3(
      thirdMisc?.topLeftMisc.position.x,
      thirdMisc?.topLeftMisc.position.y
    );
    thirdHorizonSprojs.bottomRightSprojs.position = new Vector3(
      thirdMisc?.bottomRightMisc.position.x,
      thirdMisc?.bottomRightMisc.position.y
    );
    thirdHorizonSprojs.topRightSprojs.position = new Vector3(
      thirdMisc?.topRightMisc.position.x,
      thirdMisc?.topRightMisc.position.y
    );
    thirdHorizonSprojs.bottomLeftSprojs.position = new Vector3(
      thirdMisc?.bottomLeftMisc.position.x,
      thirdMisc?.bottomLeftMisc.position.y
    );

    thirdVerticalSprojs.topLeftSprojs.position = new Vector3(
      thirdMisc?.topLeftMisc.position.x,
      thirdMisc?.topLeftMisc.position.y
    );
    thirdVerticalSprojs.bottomRightSprojs.position = new Vector3(
      thirdMisc?.bottomRightMisc.position.x,
      thirdMisc?.bottomRightMisc.position.y
    );
    thirdVerticalSprojs.topRightSprojs.position = new Vector3(
      thirdMisc?.topRightMisc.position.x,
      thirdMisc?.topRightMisc.position.y
    );
    thirdVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      thirdMisc?.bottomLeftMisc.position.x,
      thirdMisc?.bottomLeftMisc.position.y
    );
  }

  if(thirdMisc && thirdVerticalSprojs){
    thirdVerticalSprojs.topLeftSprojs.position = new Vector3(
      thirdMisc?.topLeftMisc.position.x,
      thirdMisc?.topLeftMisc.position.y
    );
    thirdVerticalSprojs.bottomRightSprojs.position = new Vector3(
      thirdMisc?.bottomRightMisc.position.x,
      thirdMisc?.bottomRightMisc.position.y
    );
    thirdVerticalSprojs.topRightSprojs.position = new Vector3(
      thirdMisc?.topRightMisc.position.x,
      thirdMisc?.topRightMisc.position.y
    );
    thirdVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      thirdMisc?.bottomLeftMisc.position.x,
      thirdMisc?.bottomLeftMisc.position.y
    );
  }

};

const handleSixMiscWindows = (miscNodes, scene, sprojsType) => {
  const firstMisc = miscNodes?.firstMisc;
  const secondMisc = miscNodes?.secondMisc;
  const thirdMisc = miscNodes?.thirdMisc;
  const fourthMisc = miscNodes?.fourthMisc;
  const fifthMisc = miscNodes?.fifthMisc;
  const sixMisc = miscNodes?.sixthMisc;

  const firstHorizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "0");
  const firstVerticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "0");

  const horizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "1");
  const verticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "1");

  const thirdHorizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "2");
  const thirdVerticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "2");

  const fourthHorizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "3");
  const fourthVerticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "3");

  const fifthHorizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "4");
  const fifthVerticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "4");

  const sixHorizonSprojs = getSprojsNodes(horizontalSprojs[sprojsType?.original_key], scene, "5");
  const sixVerticalSprojs = getSprojsVerticalNodes(sprojsType?.original_key, scene, "5");

  if(firstMisc && firstVerticalSprojs && firstHorizonSprojs){
    firstHorizonSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstHorizonSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstHorizonSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstHorizonSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );

    firstVerticalSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstVerticalSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstVerticalSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );
  }

  if(firstMisc && firstVerticalSprojs){
    firstVerticalSprojs.topLeftSprojs.position = new Vector3(
      firstMisc?.topLeftMisc.position.x,
      firstMisc?.topLeftMisc.position.y
    );
    firstVerticalSprojs.bottomRightSprojs.position = new Vector3(
      firstMisc?.bottomRightMisc.position.x,
      firstMisc?.bottomRightMisc.position.y
    );
    firstVerticalSprojs.topRightSprojs.position = new Vector3(
      firstMisc?.topRightMisc.position.x,
      firstMisc?.topRightMisc.position.y
    );
    firstVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      firstMisc?.bottomLeftMisc.position.x,
      firstMisc?.bottomLeftMisc.position.y
    );
  }

  if(secondMisc && verticalSprojs && horizonSprojs){
    horizonSprojs.topLeftSprojs.position = new Vector3(
      secondMisc?.topLeftMisc.position.x,
      secondMisc?.topLeftMisc.position.y
    );
    horizonSprojs.bottomRightSprojs.position = new Vector3(
      secondMisc?.bottomRightMisc.position.x,
      secondMisc?.bottomRightMisc.position.y
    );
    horizonSprojs.topRightSprojs.position = new Vector3(
      secondMisc?.topRightMisc.position.x,
      secondMisc?.topRightMisc.position.y
    );
    horizonSprojs.bottomLeftSprojs.position = new Vector3(
      secondMisc?.bottomLeftMisc.position.x,
      secondMisc?.bottomLeftMisc.position.y
    );

    verticalSprojs.topLeftSprojs.position = new Vector3(
      secondMisc?.topLeftMisc.position.x,
      secondMisc?.topLeftMisc.position.y
    );
    verticalSprojs.bottomRightSprojs.position = new Vector3(
      secondMisc?.bottomRightMisc.position.x,
      secondMisc?.bottomRightMisc.position.y
    );
    verticalSprojs.topRightSprojs.position = new Vector3(
      secondMisc?.topRightMisc.position.x,
      secondMisc?.topRightMisc.position.y
    );
    verticalSprojs.bottomLeftSprojs.position = new Vector3(
      secondMisc?.bottomLeftMisc.position.x,
      secondMisc?.bottomLeftMisc.position.y
    );
  }

  if(secondMisc && verticalSprojs){
    verticalSprojs.topLeftSprojs.position = new Vector3(
      secondMisc?.topLeftMisc.position.x,
      secondMisc?.topLeftMisc.position.y
    );
    verticalSprojs.bottomRightSprojs.position = new Vector3(
      secondMisc?.bottomRightMisc.position.x,
      secondMisc?.bottomRightMisc.position.y
    );
    verticalSprojs.topRightSprojs.position = new Vector3(
      secondMisc?.topRightMisc.position.x,
      secondMisc?.topRightMisc.position.y
    );
    verticalSprojs.bottomLeftSprojs.position = new Vector3(
      secondMisc?.bottomLeftMisc.position.x,
      secondMisc?.bottomLeftMisc.position.y
    );
  }

  if(thirdMisc && thirdVerticalSprojs && thirdHorizonSprojs){
    thirdHorizonSprojs.topLeftSprojs.position = new Vector3(
      thirdMisc?.topLeftMisc.position.x,
      thirdMisc?.topLeftMisc.position.y
    );
    thirdHorizonSprojs.bottomRightSprojs.position = new Vector3(
      thirdMisc?.bottomRightMisc.position.x,
      thirdMisc?.bottomRightMisc.position.y
    );
    thirdHorizonSprojs.topRightSprojs.position = new Vector3(
      thirdMisc?.topRightMisc.position.x,
      thirdMisc?.topRightMisc.position.y
    );
    thirdHorizonSprojs.bottomLeftSprojs.position = new Vector3(
      thirdMisc?.bottomLeftMisc.position.x,
      thirdMisc?.bottomLeftMisc.position.y
    );

    thirdVerticalSprojs.topLeftSprojs.position = new Vector3(
      thirdMisc?.topLeftMisc.position.x,
      thirdMisc?.topLeftMisc.position.y
    );
    thirdVerticalSprojs.bottomRightSprojs.position = new Vector3(
      thirdMisc?.bottomRightMisc.position.x,
      thirdMisc?.bottomRightMisc.position.y
    );
    thirdVerticalSprojs.topRightSprojs.position = new Vector3(
      thirdMisc?.topRightMisc.position.x,
      thirdMisc?.topRightMisc.position.y
    );
    thirdVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      thirdMisc?.bottomLeftMisc.position.x,
      thirdMisc?.bottomLeftMisc.position.y
    );
  }

  if(thirdMisc && thirdVerticalSprojs){
    thirdVerticalSprojs.topLeftSprojs.position = new Vector3(
      thirdMisc?.topLeftMisc.position.x,
      thirdMisc?.topLeftMisc.position.y
    );
    thirdVerticalSprojs.bottomRightSprojs.position = new Vector3(
      thirdMisc?.bottomRightMisc.position.x,
      thirdMisc?.bottomRightMisc.position.y
    );
    thirdVerticalSprojs.topRightSprojs.position = new Vector3(
      thirdMisc?.topRightMisc.position.x,
      thirdMisc?.topRightMisc.position.y
    );
    thirdVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      thirdMisc?.bottomLeftMisc.position.x,
      thirdMisc?.bottomLeftMisc.position.y
    );
  }

  if(fourthMisc && fourthVerticalSprojs && fourthHorizonSprojs){
    fourthHorizonSprojs.topLeftSprojs.position = new Vector3(
      fourthMisc?.topLeftMisc.position.x,
      fourthMisc?.topLeftMisc.position.y
    );
    fourthHorizonSprojs.bottomRightSprojs.position = new Vector3(
      fourthMisc?.bottomRightMisc.position.x,
      fourthMisc?.bottomRightMisc.position.y
    );
    fourthHorizonSprojs.topRightSprojs.position = new Vector3(
      fourthMisc?.topRightMisc.position.x,
      fourthMisc?.topRightMisc.position.y
    );
    fourthHorizonSprojs.bottomLeftSprojs.position = new Vector3(
      fourthMisc?.bottomLeftMisc.position.x,
      fourthMisc?.bottomLeftMisc.position.y
    );

    fourthVerticalSprojs.topLeftSprojs.position = new Vector3(
      fourthMisc?.topLeftMisc.position.x,
      fourthMisc?.topLeftMisc.position.y
    );
    fourthVerticalSprojs.bottomRightSprojs.position = new Vector3(
      fourthMisc?.bottomRightMisc.position.x,
      fourthMisc?.bottomRightMisc.position.y
    );
    fourthVerticalSprojs.topRightSprojs.position = new Vector3(
      fourthMisc?.topRightMisc.position.x,
      fourthMisc?.topRightMisc.position.y
    );
    fourthVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      fourthMisc?.bottomLeftMisc.position.x,
      fourthMisc?.bottomLeftMisc.position.y
    );
  }

  if(fourthMisc && fourthVerticalSprojs){
    fourthVerticalSprojs.topLeftSprojs.position = new Vector3(
      fourthMisc?.topLeftMisc.position.x,
      fourthMisc?.topLeftMisc.position.y
    );
    fourthVerticalSprojs.bottomRightSprojs.position = new Vector3(
      fourthMisc?.bottomRightMisc.position.x,
      fourthMisc?.bottomRightMisc.position.y
    );
    fourthVerticalSprojs.topRightSprojs.position = new Vector3(
      fourthMisc?.topRightMisc.position.x,
      fourthMisc?.topRightMisc.position.y
    );
    fourthVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      fourthMisc?.bottomLeftMisc.position.x,
      fourthMisc?.bottomLeftMisc.position.y
    );
  }

  if(fifthMisc && fifthVerticalSprojs && fifthHorizonSprojs){
    fifthHorizonSprojs.topLeftSprojs.position = new Vector3(
      fifthMisc?.topLeftMisc.position.x,
      fifthMisc?.topLeftMisc.position.y
    );
    fifthHorizonSprojs.bottomRightSprojs.position = new Vector3(
      fifthMisc?.bottomRightMisc.position.x,
      fifthMisc?.bottomRightMisc.position.y
    );
    fifthHorizonSprojs.topRightSprojs.position = new Vector3(
      fifthMisc?.topRightMisc.position.x,
      fifthMisc?.topRightMisc.position.y
    );
    fifthHorizonSprojs.bottomLeftSprojs.position = new Vector3(
      fifthMisc?.bottomLeftMisc.position.x,
      fifthMisc?.bottomLeftMisc.position.y
    );

    fifthVerticalSprojs.topLeftSprojs.position = new Vector3(
      fifthMisc?.topLeftMisc.position.x,
      fifthMisc?.topLeftMisc.position.y
    );
    fifthVerticalSprojs.bottomRightSprojs.position = new Vector3(
      fifthMisc?.bottomRightMisc.position.x,
      fifthMisc?.bottomRightMisc.position.y
    );
    fifthVerticalSprojs.topRightSprojs.position = new Vector3(
      fifthMisc?.topRightMisc.position.x,
      fifthMisc?.topRightMisc.position.y
    );
    fifthVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      fifthMisc?.bottomLeftMisc.position.x,
      fifthMisc?.bottomLeftMisc.position.y
    );
  }

  if(fifthMisc && fifthVerticalSprojs){
    fifthVerticalSprojs.topLeftSprojs.position = new Vector3(
      fifthMisc?.topLeftMisc.position.x,
      fifthMisc?.topLeftMisc.position.y
    );
    fifthVerticalSprojs.bottomRightSprojs.position = new Vector3(
      fifthMisc?.bottomRightMisc.position.x,
      fifthMisc?.bottomRightMisc.position.y
    );
    fifthVerticalSprojs.topRightSprojs.position = new Vector3(
      fifthMisc?.topRightMisc.position.x,
      fifthMisc?.topRightMisc.position.y
    );
    fifthVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      fifthMisc?.bottomLeftMisc.position.x,
      fifthMisc?.bottomLeftMisc.position.y
    );
  }

  if(sixMisc && sixVerticalSprojs && sixHorizonSprojs){
    sixHorizonSprojs.topLeftSprojs.position = new Vector3(
      sixMisc?.topLeftMisc.position.x,
      sixMisc?.topLeftMisc.position.y
    );
    sixHorizonSprojs.bottomRightSprojs.position = new Vector3(
      sixMisc?.bottomRightMisc.position.x,
      sixMisc?.bottomRightMisc.position.y
    );
    sixHorizonSprojs.topRightSprojs.position = new Vector3(
      sixMisc?.topRightMisc.position.x,
      sixMisc?.topRightMisc.position.y
    );
    sixHorizonSprojs.bottomLeftSprojs.position = new Vector3(
      sixMisc?.bottomLeftMisc.position.x,
      sixMisc?.bottomLeftMisc.position.y
    );

    sixVerticalSprojs.topLeftSprojs.position = new Vector3(
      sixMisc?.topLeftMisc.position.x,
      sixMisc?.topLeftMisc.position.y
    );
    sixVerticalSprojs.bottomRightSprojs.position = new Vector3(
      sixMisc?.bottomRightMisc.position.x,
      sixMisc?.bottomRightMisc.position.y
    );
    sixVerticalSprojs.topRightSprojs.position = new Vector3(
      sixMisc?.topRightMisc.position.x,
      sixMisc?.topRightMisc.position.y
    );
    sixVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      sixMisc?.bottomLeftMisc.position.x,
      sixMisc?.bottomLeftMisc.position.y
    );
  }

  if(sixMisc && sixVerticalSprojs){
    sixVerticalSprojs.topLeftSprojs.position = new Vector3(
      sixMisc?.topLeftMisc.position.x,
      sixMisc?.topLeftMisc.position.y
    );
    sixVerticalSprojs.bottomRightSprojs.position = new Vector3(
      sixMisc?.bottomRightMisc.position.x,
      sixMisc?.bottomRightMisc.position.y
    );
    sixVerticalSprojs.topRightSprojs.position = new Vector3(
      sixMisc?.topRightMisc.position.x,
      sixMisc?.topRightMisc.position.y
    );
    sixVerticalSprojs.bottomLeftSprojs.position = new Vector3(
      sixMisc?.bottomLeftMisc.position.x,
      sixMisc?.bottomLeftMisc.position.y
    );
  }
};
