import React from "react";
import { ImageRadioInputProps } from "./Types";
import { Image } from "../image";
import classnames from "classnames";

export const ImageRadioInput = ({
  id,
  name,
  value,
  uniqueKey,
  image,
  onChange,
  title,
  disabled,
  checked,
  reverse,
  className,
}: ImageRadioInputProps) => {
  return (
    <div className={classnames("flex h-auto", className)}>
      <label
        htmlFor={id}
        className={classnames(
          "flex items-center relative cursor-pointer sm:justify-start justify-around h-full",
          { "flex-col-reverse sm:pr-5 pr-2.5": reverse },
          { "flex-col": !reverse }
        )}
      >
        <input
          type="radio"
          className="absolute w-0 h-0 opacity-0 peer"
          id={id}
          value={value}
          name={name}
          onChange={(e) => onChange(e)}
          disabled={disabled}
          data-unique-key={uniqueKey}
          checked={checked}
        />
        <span
          className={classnames(
            "inline-block px-[0.625rem] pt-[0.625rem] pb-[0.875rem] md:pt-[0.625rem] md:pb-[0.563rem] md:px-[0.688rem] border border-transparent peer-checked:border-black-secondary",
            { "opacity-50 cursor-not-allowed": disabled }
          )}
        >
          {image && <Image {...image} />}
        </span>
        <h4
          className={classnames(
            "text-3xl mt-3 text-black text-center",
            {
              "opacity-50 cursor-not-allowed": disabled,
            },
            { "mb-3": reverse }
          )}
        >
          {title}
        </h4>
      </label>
    </div>
  );
};
