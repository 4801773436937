import React from "react";
import { LogoProps } from "./Types";
import LogoImg from "../../assets/images/logo.svg";
import classnames from "classnames";

export const Logo = ({ to, className }: LogoProps) => {
  return (
    <a href={to}>
      <img
        src={LogoImg}
        alt="Logo"
        className={classnames("sm:w-auto w-14 h-12", className)}
      />
    </a>
  );
};
