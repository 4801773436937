import React from "react";
import { IconProps } from "../Types";

const Hamburger = ({ className }: IconProps) => (
  <svg
    className={className}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="9.16667" height="9.16667" fill="#454545" />
    <rect y="12.832" width="9.16667" height="9.16667" fill="#454545" />
    <rect x="12.832" width="9.16667" height="9.16667" fill="#454545" />
    <rect
      x="12.832"
      y="12.832"
      width="9.16667"
      height="9.16667"
      fill="#454545"
    />
  </svg>
);

export default Hamburger;
