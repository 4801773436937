import React from "react";
import { IconProps } from "../Types";

const LeftLarge = ({ className }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="39"
    viewBox="0 0 16 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15 1L1 19.5L15 38" stroke="#A2A2A2" strokeLinecap="round" />
  </svg>
);

export default LeftLarge;
