export const AccordionEnum = {
  Model: "Model",
  Glass: "Glass",
  Handle: "Handle",
  Lock: "Lock",
  Dimensions: "Dimensions",
  Color: "Color",
  Profile: "Profile",
  Cover_Plate: "Cover Plate",
  Size: "Size",
  Kickplate: "Kickplate",
  Hinge: "Hinge",
  Color_Inside: "Color Inside",
  Ventilation: "Ventilation",
  Sprojs: "Sprojs",
  Luft_Types: "Luft Types",
  Beslag: "Beslag",
  Tillval: "Tillval",
} as const;

export const MeshNames = {
  ASSA: "ASSA",
  Door_Accessory: "Door_Accessory",
  YALE: "Yale",
} as const;

export const YaleDoorman = "Yale Doorman";

export const Hinge = {
  leftHinge: "vansterstalld",
  rightHinge: "hogerstalld",
};

export const AssetCategories = {
  Single: "Single",
  Double: "Double",
};
export const ModelVariants = {
  single: 14,
  double: 15,
  side: 17,
  fixed: 18,
  "door-window": 19,
  "door-window-double": 27,
};

export const DimensionsMeshes = [
  "Top_Left",
  "Top_Right",
  "Bottom_Left",
  "Top_Left_Door",
  "Top_Right_Door",
  "Bottom_Left_Door",
  "BottomLRight",
  "Bottom_Left_DoorL",
  "Top_Left_DoorL",
  "Top_Left_DoorR",
  "Bottom_Right_DoorR",
  "Top_Right_DoorR",
  "Top_Right_DoorL",
  "bone_frame_top_right",
  "bone_frame_top_left",
  "bone_frame_bottom_left",
  "bone_frame_bottom_right",
  "bone_frame_misc_top_left",
  "bone_frame_misc_top_right",
  "bone_frame_misc_bottom_left",
  "bone_frame_misc_bottom_right",
  "bone_door_top_left",
  "bone_door_top_right",
  "bone_door_bottom_left",
  "bone_door_mid_left",
  "bone_doorL_top_left",
  "bone_doorL_top_right",
  "bone_doorL_bottom_left",
  "bone_doorR_top_right",
  "bone_doorR_top_left",
  "bone_doorR_bottom_right",
  "bone_doorR_mid_right",
  "bone_doorL_mid_left",
];

export const PositionNodes = {
  doors: {
    bottom_right: "BottomLRight",
    top_right: "Top_Right",
    top_left: "Top_Left",
  },
  windows: {
    bottom_right: "bone_frame_bottom_right",
    top_right: "bone_frame_top_right",
    top_left: "bone_frame_top_left",
  },
};

export const BaseDimensions = {
  single: { x: 0.499, y: 2.099 },
  double: { x: 0.699, y: 2.099 },
  "double-asymmetric": { x: 0.752, y: 2.099 },
  side: { x: 1, y: 1 },
  fixed: { x: 1, y: 1 },
  "door-window": { x: 1, y: 1 },
  "door-window-double": { x: 1, y: 1 },
};

export const ComponentNames = {
  Lock_Types: "Lock Types",
  Handle_Types: "Handle Types",
  Lock: "Lock",
  Handle: "Handle",
  Cover_Plate: "Cover Plate",
  Cover_Plate_Types: "Cover Plate Types",
  Kickplate: "Kickplate",
  Beslag: "Beslag",
  Beslag_Types: "Beslag Types",
  Ventilation: "Ventilation",
  Sprojs: "Sprojs",
  Luft_Types: "Luft Types",
};

export const CategoryIds = {
  doors: {
    single: { id: 14, name: "Single Doors" },
    double: { id: 15, name: "Double Doors" },
  },
  windows: {
    side: { id: 17, name: "Side Windows" },
    fixed: { id: 18, name: "Fixed Windows" },
    "door-window": { id: 19, name: "Door Windows" },
    "door-window-double": { id: 27, name: "Door Windows Double" },
  },
};

export const otherProducts = [
  "Door - Accessories",
  "Window - Accessories",
  "Ytterfönster WFUKS",
  "Info Door",
];
export const WindowCategories = [17, 18, 19, 27];
export const DoorCategories = [14, 15];

export const DimensionsMultiplier = { doors: 100, windows: 1 };
export const DimensionsDivider = { doors: 1, windows: 100 };

export const ProductTypes = {
  doors: { name: "Ytterdörr", key: "doors" },
  windows: { name: "Fönster", key: "windows" },
};

export const ExcludedFromSummaryComponents = [
  AccordionEnum.Size,
  "Types",
  "Color",
];

export const AccessoriesEnums = {
  Back: "Back",
  Base: "Base",
  3212: "3212",
  Lock_Null: "lock_null",
  Handle_Null: "handle_null",
} as const;

export const ProfileNames = {
  svanhals: "svanhals",
  rak: "ingen",
  parlan: "parlan",
};

export const configuratorKeys = {
  windowsDescription: "Windows Product Descriptions",
  doorsDescription: "Product Descriptions",
};
export const productTypes = {
  singleDoors: "single",
  doubleDoors: "double",
  doubleDoorWindows: "door-window-double",
};

export const WFF_FRAME_MISC = "WFF_frame_misc";

export const ColorPosition = {
  inside: "insida",
  outside: "utsida",
};

export const BeslagWindowModels = {
  wfik: ["roca_bjorko_center_", "roca_prasto_center_", "assa_fix_42_center_"],
  wfuk: ["roca_bjorko_center_", "roca_prasto_center_", "assa_fix_42_center"],
  wfuks: [
    "fonsterlas_vm280281_right",
    "fonsterlas_vm380_right",
    "fonsterlas_mp380_right",
  ],
  wff: [],
  wfdik: ["roca_bjorko_center_", "roca_prasto_center_", "assa_fix_42_center"],
  wfduk: ["roca_bjorko_center_", "roca_prasto_center_", "assa_fix_42_center"],
};

export const productsCategoriesId = {
  doors: [13, 14, 15],
  windows: [16, 17, 18, 19, 27, 32],
};

export const WindowModels = {
  WFIK: "wfik",
  WFUK: "wfuk",
  WFF: "wff",
  WFUKS: "wfuks",
  WFDIK: "wfdik",
  WFDUK: "wfduk",
};

export const KickplateAttributeId = 173;
