import React from "react";
import Slider from "rc-slider";
import { SliderRangeProps } from "./types";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

function SliderRange({
  values,
  defaultValue,
  vertical,
  className,
  step,
  onChange,
  onAfterChange,
  reverse,
  prefix,
  subtractor,
  value,
}: SliderRangeProps): JSX.Element {
  const prefixValue = prefix === undefined ? "" : prefix;
  const marks = values.reduce((total, mark) => {
    total[`${mark}`] = `${mark - (subtractor ? subtractor : 0)}${prefixValue}`;
    return total;
  }, {});

  return (
    <Range
      min={Math.min(...values)}
      max={Math.max(...values)}
      marks={marks}
      step={step}
      defaultValue={[defaultValue]}
      dots={true}
      value={value}
      onChange={(e) => onChange(e[0])}
      vertical={vertical}
      className={className}
      reverse={reverse}
      onAfterChange={onAfterChange}
    />
  );
}

export { SliderRange };
