import classnames from "classnames";
import React from "react";
import { Button } from "../button/Button";
import { ModalProps } from "./Types";
import QRCode from "qrcode.react";
import useCheckMobileScreen from "../../../src/hooks/CheckMobileScreen";

export const Modal = ({
  isOpen,
  text,
  className,
  contentClassName,
  close,
  QRLink,
}: ModalProps) => {
  const isMobile = useCheckMobileScreen();
  return (
    <>
      {isOpen && (
        <>
          <div
            onClick={close}
            className="absolute w-full h-full py-10 left-0 top-0 z-40 before:content-[''] before:w-full before:h-full before:absolute before:left-0 before:top-0 before:bg-black-secondary before:opacity-5"
          ></div>
          <div
            className={classnames(
              "Modal backdrop-blur-sm sm:max-w-[39.4rem] sm:max-h-[25rem] z-50 flex justify-center absolute bottom-1/2 right-1/2 sm:h-full sm:w-full h-3/5 w-11/12 translate-y-1/2 translate-x-2/4 bg-white",
              className
            )}
          >
            <div className="Modal__close absolute right-7 top-7">
              <Button icon={"Close"} onClick={close} />
            </div>
            <div
              className={classnames(
                "Modal__content flex flex-col justify-center items-center ",
                contentClassName
              )}
            >
              <div className="prose mb-7 flex justify-center">{text}</div>
              <QRCode
                value={QRLink}
                size={isMobile ? 130 : 180}
                includeMargin
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
