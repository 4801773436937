import React from "react";
import { IconProps } from "../Types";

const Hand = ({ className }: IconProps) => (
  <svg
    className={className}
    width="8"
    height="39"
    viewBox="0 0 8 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4V19H1V4C1 2.34315 2.34315 1 4 1C5.65685 1 7 2.34315 7 4ZM0 19V4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4V19V20V35C8 37.2091 6.20914 39 4 39C1.79086 39 0 37.2091 0 35V20V19ZM1 20V35C1 36.6569 2.34315 38 4 38C5.65685 38 7 36.6569 7 35V20H1Z"
      fill="black"
    />
  </svg>
);

export default Hand;
