import React from "react";
import { IconProps } from "../Types";

const Profile = ({ className }: IconProps) => (
  <svg
    className={className}
    width="40"
    height="36"
    viewBox="0 0 40 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.9999 6H32V30H8L8.00001 28H17.6321C18.1844 28 18.6321 27.5523 18.6321 27V25.7251C18.6321 25.1634 19.0977 24.7187 19.6573 24.6706C24.5297 24.2518 26.3379 19.9531 26.7475 17.0771C26.8231 16.5461 27.2604 16.1229 27.7967 16.1229H27.9999C28.5522 16.1229 28.9999 15.6752 28.9999 15.1229V6ZM27.9999 6L27.4285 6L8.00001 6L8.00001 27L17.6321 27V25.7251C17.6321 24.5735 18.5687 23.7605 19.5717 23.6742C21.6886 23.4923 23.1114 22.4844 24.0753 21.1962C25.0599 19.8803 25.5691 18.2593 25.7575 16.9361C25.8951 15.9704 26.7061 15.1229 27.7967 15.1229H27.9999L27.9999 6ZM27.9999 5H8C7.44772 5 7 5.44772 7 6V30C7 30.5523 7.44772 31 8 31H32C32.5523 31 33 30.5523 33 30V6C33 5.44772 32.5523 5 32 5H27.9999Z"
      fill="black"
    />
  </svg>
);

export default Profile;
