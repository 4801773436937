import React from "react";
import { IconProps } from "../Types";

const VerticalSlider = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="22"
      height="37"
      viewBox="0 0 22 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="22"
        width="37"
        height="22"
        rx="11"
        transform="rotate(90 22 0)"
        fill="#658481"
      />
      <path d="M17 25L11 30L5 25" stroke="white" />
      <path d="M5 12L11 7L17 12" stroke="white" />
    </svg>
  );
};

export default VerticalSlider;
