import React from "react";
import { CSSTransition } from "react-transition-group";
import { ProductModal } from "../productModal/ProductModal";
import { ProductModalProps } from "./Types";
import "./style.css";

export const ProductModalComponent = ({
  dataContainer,
  isModalOpen,
  setIsModalOpen,
  productType,
}: ProductModalProps) => (
  <CSSTransition
    in={isModalOpen}
    timeout={300}
    classNames="switch"
    unmountOnExit
    onEnter={() => setIsModalOpen(isModalOpen)}
    onExited={() => setIsModalOpen(isModalOpen)}
  >
    <ProductModal
      isOpen={isModalOpen}
      title={dataContainer?.sectionKey(
        "Translations",
        productType === "windows" ? "windows_info_title" : "doors_info_title"
      )}
      close={() => {
        setIsModalOpen(false);
      }}
      images={dataContainer?.getImagesVariations(productType).map((image) => ({
        src: image.assets[0].preview_image_url,
        title: image.assets[0].key,
      }))}
      text={dataContainer?.sectionKey(
        "Product Descriptions",
        dataContainer?.mImages[productType].name
          .toLowerCase()
          .split(" ")
          .join("")
      )}
    />
  </CSSTransition>
);
