import React from "react";
import { IconProps } from "../Types";

const Lock = ({ className }: IconProps) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29ZM15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2985 16.5783V20.6994C16.2985 21.4174 15.7165 21.9994 14.9985 21.9994C14.2805 21.9994 13.6985 21.4174 13.6985 20.6994V16.5779C12.1841 16.0423 11.0991 14.5979 11.0991 12.9C11.0991 10.7461 12.8452 9 14.9991 9C17.1531 9 18.8991 10.7461 18.8991 12.9C18.8991 14.5983 17.8136 16.0431 16.2985 16.5783ZM19.8991 12.9C19.8991 14.7764 18.8449 16.4046 17.2985 17.2278V20.6994C17.2985 21.9696 16.2687 22.9994 14.9985 22.9994C13.7282 22.9994 12.6985 21.9697 12.6985 20.6994V17.2271C11.1528 16.4037 10.0991 14.7759 10.0991 12.9C10.0991 10.1938 12.2929 8 14.9991 8C17.7053 8 19.8991 10.1938 19.8991 12.9Z"
      fill="black"
    />
  </svg>
);

export default Lock;
