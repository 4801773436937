import { Vector2 } from "@babylonjs/core";
import { ModelVariants, WindowCategories } from "src/services/enums";

export const getCameraValues = (
  category: string,
  state: string,
  type: string,
  isMobile: boolean,
  frameDimensions: { width: number; height: number },
  alphaValue = 1,
) => {
  if (type === VALUE_TYPES.RADIUS) {
    let radiusAdder = 0;

    if (WindowCategories.includes(ModelVariants[category]) && frameDimensions) {
      radiusAdder = getWindowsRadiusAdder(frameDimensions, isMobile);
    } else {
      radiusAdder = getDoorsRadiusAdder(frameDimensions);
    }

    const radiusValues =
      cameraValues[state || CAMERA_STATES.DEFAULT][category || "single"][type][
        isMobile ? "mobile" : "desktop"
      ];

    return { 
      ...radiusValues, 
      radius: radiusValues.radius + radiusAdder,
      alpha: radiusValues.alpha * alphaValue,
    };
  }

  let yAdder = 0;

  if (WindowCategories.includes(ModelVariants[category]) && frameDimensions) {
    yAdder = getWindowsYAdder(frameDimensions);
  }

  const x =
    cameraValues[state || CAMERA_STATES.DEFAULT][category || "single"][type][
      isMobile ? "mobile" : "desktop"
    ].x;

  const y =
    cameraValues[state || CAMERA_STATES.DEFAULT][category || "single"][type][
      isMobile ? "mobile" : "desktop"
    ].y;

  if (
    state === CAMERA_STATES.MENU_OPENED ||
    state === CAMERA_STATES.BESLAG_ANIMATION ||
    state === CAMERA_STATES.FIRST_ANIMATION
  ) {
    return new Vector2(x + getXAdder(frameDimensions, isMobile), y + yAdder);
  }

  return new Vector2(x, y + yAdder);
};

const getXAdder = (frameDimensions, isMobile) => {
  let xAdder = 0;

  if (frameDimensions?.width < 1550) {
    xAdder = 0.5;
  }

  if (frameDimensions?.width > 1700 && isMobile) {
    xAdder = 0.5;
  }

  if (frameDimensions?.width <= 1800 && isMobile) {
    xAdder = 0.7;
  }

  return xAdder;
};

const getWindowsRadiusAdder = (frameDimensions, isMobile): number => {
  if (!frameDimensions) {
    return 0;
  }  

  let radiusAdder = 0;

  if (frameDimensions.width >= 1500 || frameDimensions.height >= 1300) {
    radiusAdder = 1;
  }

  if (frameDimensions.width >= 2000 || frameDimensions.height >= 1500) {
    radiusAdder = 2;
  }

  if (frameDimensions.width >= 2400 || frameDimensions.height >= 1900) {
    radiusAdder = 2.5;
  }

  if (frameDimensions.height >= 1800) {
    radiusAdder = 1.7;
  }

  if (isMobile && frameDimensions.height > 1000) {
    radiusAdder = 3;
  }

  if (isMobile && frameDimensions.height > 1500) {
    radiusAdder = 4;
  }

  return radiusAdder;
};

const getDoorsRadiusAdder = (frameDimensions) => {
  if (!frameDimensions) {
    return 0;
  }
  let radiusAdder = 0;

  if (frameDimensions.height > 2000) {
    radiusAdder = 0.2;
  }

  if (frameDimensions.height > 2100) {
    radiusAdder = 0.5;
  }

  if (frameDimensions.height > 2200) {
    radiusAdder = 2.5;
  }

  return radiusAdder;
};

const getWindowsYAdder = (frameDimensions) => {
  let yAdder = 0;

  if (frameDimensions.height < 800) {
    yAdder = 0.3;
  }

  if (frameDimensions.height > 2000) {
    yAdder = -0.5;
  }

  if (frameDimensions.height > 2500) {
    yAdder = -1;
  }

  return yAdder;
};

export const CAMERA_STATES = {
  DEFAULT: "defaultState",
  MENU_OPENED: "menuOpenedState",
  FIRST_ANIMATION: "firstAnimation",
  SECOND_ANIMATION: "secondAnimation",
  SECOND_ANIMATION_RIGHT: "secondAnimationRight",
  BESLAG_ANIMATION: "beslagAnimation",
};

export const VALUE_TYPES = {
  RADIUS: "radius",
  OFFSET: "offset",
};

const cameraValues = {
  defaultState: {
    single: {
      radius: {
        desktop: { radius: 4, alpha: 1.57, beta: 2 },
        mobile: { radius: 4.5, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: 0, y: -1.05 },
        mobile: { x: 0, y: -1.05 },
      },
    },
    double: {
      radius: {
        desktop: { radius: 4, alpha: 1.57, beta: 2 },
        mobile: { radius: 7, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: 0, y: -1.05 },
        mobile: { x: 0, y: -1.05 },
      },
    },
    fixed: {
      radius: {
        desktop: { radius: 3, alpha: 1.57, beta: 2 },
        mobile: { radius: 3, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: 0, y: 0.4 },
        mobile: { x: 0, y: 0.7 },
      },
    },
    side: {
      radius: {
        desktop: { radius: 3, alpha: 1.57, beta: 0 },
        mobile: { radius: 3, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: 0, y: 0.4 },
        mobile: { x: 0, y: 0.7 },
      },
    },
    "door-window": {
      radius: {
        desktop: { radius: 3, alpha: 1.57, beta: 2 },
        mobile: { radius: 3, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: 0, y: -1 },
        mobile: { x: 0, y: -1 },
      },
    },
    "door-window-double": {
      radius: {
        desktop: { radius: 6, alpha: 1.57, beta: 2 },
        mobile: { radius: 6, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: 0, y: -1.2 },
        mobile: { x: 0, y: -1.3 },
      },
    },
  },
  menuOpenedState: {
    single: {
      radius: {
        desktop: { radius: 4, alpha: 1.57, beta: 2 },
        mobile: { radius: 4, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: 0, y: -1.05 },
        mobile: { x: 0, y: -1.05 },
      },
    },
    double: {
      radius: {
        desktop: { radius: 4, alpha: 1.57, beta: 2 },
        mobile: { radius: 4, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: 0, y: -1.05 },
        mobile: { x: -1, y: -1.05 },
      },
    },
    fixed: {
      radius: {
        desktop: { radius: 3, alpha: 1.57, beta: 2 },
        mobile: { radius: 3, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: -0.5, y: 0.4 },
        mobile: { x: -0.5, y: 1.7 },
      },
    },
    side: {
      radius: {
        desktop: { radius: 3, alpha: 1.57, beta: 2 },
        mobile: { radius: 4, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: -0.5, y: 0.4 },
        mobile: { x: -1, y: 1 },
      },
    },
    "door-window": {
      radius: {
        desktop: { radius: 3, alpha: 1.57, beta: 2 },
        mobile: { radius: 3, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: -0.5, y: -1 },
        mobile: { x: -1, y: -1.3 },
      },
    },
    "door-window-double": {
      radius: {
        desktop: { radius: 6, alpha: 1.57, beta: 2 },
        mobile: { radius: 6, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: -0.5, y: -1.2 },
        mobile: { x: -1, y: -1.3 },
      },
    },
  },
  firstAnimation: {
    fixed: {
      radius: {
        desktop: { radius: 1.7, alpha: 1.57, beta: 2 },
        mobile: { radius: 1.7, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: -0.4, y: 0.4 },
        mobile: { x: -1, y: 0.3 },
      },
    },
    side: {
      radius: {
        desktop: { radius: 1.7, alpha: 1.57, beta: 2 },
        mobile: { radius: 1.7, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: -0.4, y: 0.4 },
        mobile: { x: -0.5, y: 0.3 },
      },
    },
    "door-window": {
      radius: {
        desktop: { radius: 1, alpha: 1.57, beta: 2 },
        mobile: { radius: 1, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: -0.4, y: -1 },
        mobile: { x: -1, y: -1.3 },
      },
    },
    "door-window-double": {
      radius: {
        desktop: { radius: 5, alpha: 1.57, beta: 2 },
        mobile: { radius: 5, alpha: 1.57, beta: 2 },
      },
      offset: {
        desktop: { x: 0, y: -1.4 },
        mobile: { x: -1, y: -1.3 },
      },
    },
  },
  secondAnimation: {
    single: {
      radius: {
        desktop: { radius: 2, alpha: 0.7, beta: 0.8 },
        mobile: { radius: 1.4, alpha: 0.7, beta: 0.8 },
      },
      offset: {
        desktop: { x: 0, y: -1.05 },
        mobile: { x: 0, y: -1.05 },
      },
    },
    double: {
      radius: {
        desktop: { radius: 2.5, alpha: 1.6, beta: 0.8 },
        mobile: { radius: 1.4, alpha: 0.7, beta: 0.8 },
      },
      offset: {
        desktop: { x: 0.5, y: -1.05 },
        mobile: { x: 0, y: -1.05 },
      },
    },
  },
  secondAnimationRight: {
    single: {
      radius: {
        desktop: { radius: 1.8, alpha: 2, beta: 0.8 },
        mobile: { radius: 1.4, alpha: 2.3, beta: 0.8 },
      },
      offset: {
        desktop: { x: -0.5, y: -1.05 },
        mobile: { x: 0, y: -1.05 },
      },
    },
    double: {
      radius: {
        desktop: { radius: 2.5, alpha: 1.5, beta: 0.8 },
        mobile: { radius: 1.4, alpha: 2.3, beta: 0.8 },
      },
      offset: {
        desktop: { x: 0.5, y: -1.05 },
        mobile: { x: 0, y: -1.05 },
      },
    },
  },
  beslagAnimation: {
    fixed: {
      radius: {
        desktop: { radius: 1.7, alpha: Math.PI * 1.5, beta: 2 },
        mobile: { radius: 1.7, alpha: Math.PI * 1.5, beta: 2 },
      },
      offset: {
        desktop: { x: -0.4, y: 0.4 },
        mobile: { x: 0, y: 0.3 },
      },
    },
    side: {
      radius: {
        desktop: { radius: 1.7, alpha: Math.PI * 1.5, beta: 2 },
        mobile: { radius: 2.5, alpha: Math.PI * 1.5, beta: 2 },
      },
      offset: {
        desktop: { x: -0.4, y: 0.4 },
        mobile: { x: -0.5, y: 0.8 },
      },
    },
    "door-window": {
      radius: {
        desktop: { radius: 1.7, alpha: Math.PI * 1.5, beta: 2 },
        mobile: { radius: 2, alpha: Math.PI * 1.5, beta: 2 },
      },
      offset: {
        desktop: { x: -0.4  , y: -1 },
        mobile: { x: 0, y: -1.3 },
      },
    },
    "door-window-double": {
      radius: {
        desktop: { radius: 4, alpha: Math.PI * 1.5, beta: 2 },
        mobile: { radius: 4, alpha: Math.PI * 1.5, beta: 2 },
      },
      offset: {
        desktop: { x: 0, y: -1.3 },
        mobile: { x: 0, y: -1.3 },
      },
    },
  },
};
