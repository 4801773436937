import React from "react";

import { Typography } from "../../typography";
import { SelectGroupProps } from "./types";

export const SelectGroup = ({
  title,
  description,
  children,
}: SelectGroupProps) => {
  return (
    <div>
      {title && (
        <Typography
          tag="h3"
          className="text-4xl md:text-5xl leading-4 text-black-faded tracking-widest font-normal"
        >
          {title}
        </Typography>
      )}

      {description && (
        <Typography
          tag="p"
          className="text-3xl md:text-4xl leading-3 md:leading-4 text-black-faded mt-5 break-words"
        >
          {description}
        </Typography>
      )}

      {children && (
        <div className="flex flex-wrap sm:border-0 border-b-2 pb-7 border-smoke sm:gap-[0.625rem] gap-3.5 md:gap-[0.938rem] mt-[1.875rem]">
          {children}
        </div>
      )}
    </div>
  );
};
