import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Typography } from "../typography";
import { ItemCategoryProps } from "./types";
import { Icon } from "../icon/Icon";
import { Image } from "../image";
import { Button } from "../button/Button";
import { colorsVariants } from "../../consts/colors";
import { useNavigate } from "react-router-dom";

const ItemCategory = ({
  category,
  dropdownOpen,
  setDropdownOpen,
  className,
}: ItemCategoryProps) => {
  const navigate = useNavigate();

  const showItems = () => {
    if (category?.isActive && category?.items && category.items.length > 1) {
      return (
        <div className="flex items-center justify-center rounded-full bg-white text-3xl w-[19px] h-[19px]">
          <Typography tag="p" className="text-primary">
            {category.items.length}
          </Typography>
        </div>
      );
    }
    return (
      <Button
        icon={"Add"}
        to={category.showAll?.URL}
        className={classnames("hover:opacity-80 rounded-full", {
          "bg-primary": category?.isActive,
          "bg-white": !category?.isActive,
        })}
        additionalIconProps={{
          name: "Add",
          stroke: category?.isActive ? undefined : colorsVariants.primary.hex,
          fill: category?.isActive ? undefined : colorsVariants.primary.hex,
        }}
      />
    );
  };

  const showDropdownArrows = () => {
    if (category.items && category.items.length >= 2) {
      return (
        <Button
          icon={dropdownOpen ? "UpArrow" : "DownArrow"}
          className="hover:opacity-80"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        />
      );
    }
  };

  const clickHandler = (click) => {
    click && click();
    setDropdownOpen(false);
  };

  const mainWrapperOnClick = () => {
    if (category.items && category.items.length >= 2 && category?.isActive) {
      setDropdownOpen(!dropdownOpen);
      return;
    }

    navigate(category.showAll.URL);
  };

  return (
    <div
      className={classnames(
        "relative flex flex-1 sm:flex-none cursor-pointer items-center sm:px-[0.850rem] px-[1.375rem]  py-4 sm:pt-[1.313rem] sm:pb-[1.375rem] sm:gap-[1.313rem] justify-between sm:justify-items-start transition ease-in-out delay-150",
        { "text-white bg-primary": category?.isActive },
        { "text-black bg-white": !category?.isActive },
        { "": dropdownOpen },
        className
      )}
      onClick={mainWrapperOnClick}
    >
      <div className="flex gap-2 items-center">
        {category?.isActive ? (
          <Icon name={category.icon} fill="#FFFFFF" />
        ) : (
          <Icon name={category.icon} />
        )}
        <Typography
          tag="h4"
          className={classnames(
            "text-3xl font-medium leading-3 ml-3",
            { "text-white": category?.isActive },
            { "text-black opacity-70": !category?.isActive }
          )}
        >
          {category?.title}
        </Typography>
      </div>
      <div className="flex gap-6">
        {showItems()}
        {showDropdownArrows()}
      </div>
      <div
        className={classnames(
          "absolute top-0 left-0  inline-flex flex-col-reverse -translate-y-full pl-[22px] pr-4 w-full sm:min-w-[23.438rem] overflow-scroll text-black bg-white z-50 max-h-[60vh]",
          { block: dropdownOpen && category?.isActive },
          { hidden: !dropdownOpen }
        )}
      >
        <div className="py-[1.125rem] ">
          <Link
            to={category.showAll.URL}
            className="text-3xl hover:cursor-pointer hover:opacity-80 active:opacity-90"
          >
            {category.showAll.text}
          </Link>
        </div>
        {category?.items?.map((item) => (
          <div
            className="pt-7 pb-8 border-b border-gray-200 flex sm:max-w-[23.438rem] w-full justify-between items-center gap-1"
            key={item.id}
          >
            <div
              className="flex gap-4 cursor-pointer"
              onClick={() => clickHandler(item.onClick)}
            >
              <Image
                title={item.image.title}
                src={item.image.src}
                width="19px"
                height="40px"
              />
              <div className="flex flex-col">
                <Typography
                  tag="h4"
                  className="text-primary text-4xl font-extralight tracking-wider leading-4"
                >
                  {item.name}
                </Typography>
                <Typography tag="p" className="mt-3 leadint-[0.781rem]">
                  {`${item.type}, ${item.model}`}
                </Typography>
                <Typography
                  tag="p"
                  className="mt-[0.625rem] text-primary text-2xl"
                >
                  {item.description}
                </Typography>
              </div>
            </div>
            <div
              className="flex items-center gap-2 hover:cursor-pointer hover:opacity-80 active:opacity-90"
              onClick={() => clickHandler(item.onRemoveClick)}
            >
              <span className="text-3xl hidden sm:inline">Ta bort</span>
              <Icon name="Clear" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemCategory;
