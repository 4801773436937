import React from "react";
import { IconProps } from "../Types";

const UpArrow = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.59 7.44189L6 2.84214L1.41 7.44189L1.68866e-08 6.02581L6 -6.00077e-05L12 6.02581L10.59 7.44189Z"
        fill="#F5F7F5"
      />
    </svg>
  );
};

export default UpArrow;
