import React from "react";

const Kickplate = () => (
  <svg
    width="36"
    height="25"
    viewBox="0 0 36 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2123 10.5555C18.4608 10.5555 18.6623 10.7628 18.6623 11.0184V16.0186C18.6623 16.2742 18.4608 16.4815 18.2123 16.4815C17.9637 16.4815 17.7623 16.2742 17.7623 16.0186V11.0184C17.7623 10.7628 17.9637 10.5555 18.2123 10.5555Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.6 8.82274H21.4467C19.7701 6.48555 16.3465 6.42565 14.6042 8.77098L14.5658 8.82274H5.4C5.15146 8.82274 4.95 9.03004 4.95 9.28571V19.4444C4.95 19.7001 5.15146 19.9074 5.4 19.9074H30.6C30.8485 19.9074 31.05 19.7001 31.05 19.4444V9.28571C31.05 9.03004 30.8485 8.82274 30.6 8.82274ZM15.3193 9.33318C16.734 7.42888 19.5521 7.53242 20.8316 9.53369C20.9115 9.663 21.0519 9.74867 21.2118 9.74867H30.15V18.9815H5.85V9.74867H14.7882C14.9284 9.74867 15.0606 9.68153 15.1458 9.56692L15.3193 9.33318Z"
      fill="#333333"
    />
    <path
      d="M6.75 3.7037C6.50146 3.7037 6.3 3.911 6.3 4.16667C6.3 4.42234 6.50146 4.62963 6.75 4.62963H29.25C29.4985 4.62963 29.7 4.42234 29.7 4.16667C29.7 3.911 29.4985 3.7037 29.25 3.7037H6.75Z"
      fill="#333333"
    />
    <path
      d="M33.696 2.51849C33.696 2.88674 33.4059 3.18513 33.048 3.18513C32.6901 3.18513 32.4 2.88674 32.4 2.51849C32.4 2.15025 32.6901 1.85185 33.048 1.85185C33.4059 1.85185 33.696 2.15025 33.696 2.51849Z"
      fill="#333333"
    />
    <path
      d="M2.448 3.18513C2.80588 3.18513 3.096 2.88674 3.096 2.51849C3.096 2.15025 2.80588 1.85185 2.448 1.85185C2.09012 1.85185 1.8 2.15025 1.8 2.51849C1.8 2.88674 2.09012 3.18513 2.448 3.18513Z"
      fill="#333333"
    />
    <path
      d="M33.696 21.9629C33.696 22.3312 33.4059 22.6296 33.048 22.6296C32.6901 22.6296 32.4 22.3312 32.4 21.9629C32.4 21.5947 32.6901 21.2963 33.048 21.2963C33.4059 21.2963 33.696 21.5947 33.696 21.9629Z"
      fill="#333333"
    />
    <path
      d="M2.448 22.6296C2.80588 22.6296 3.096 22.3312 3.096 21.9629C3.096 21.5947 2.80588 21.2963 2.448 21.2963C2.09012 21.2963 1.8 21.5947 1.8 21.9629C1.8 22.3312 2.09012 22.6296 2.448 22.6296Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.925926C0 0.414587 0.402951 0 0.9 0H35.1C35.597 0 36 0.414587 36 0.925926V24.0741C36 24.5854 35.597 25 35.1 25H0.9C0.402951 25 0 24.5854 0 24.0741V0.925926ZM0.9 0.925926H35.1V24.0741H0.9V0.925926Z"
      fill="#333333"
    />
  </svg>
);

export default Kickplate;
