import React from "react";
import classnames from "classnames";
import { NumberInputProps } from "./index";

export const NumberInput = ({
  id,
  name,
  value,
  onChange,
  disabled,
  clearButton,
  label,
  uniqueKey,
}: NumberInputProps) => (
  <div className="relative flex gap-2 w-full sm:p-0 pr-4">
    <label className="sm:mr-0 mr-5 text-black-faded" htmlFor={id}>
      {label}
    </label>
    <input
      type="number"
      name={name}
      id={id}
      data-unique-key={uniqueKey}
      className="no-spinner-input border-b border-black focus:outline-none w-10 flex-grow pr-5 pl-2"
      pattern="[0-9.]{1,6}"
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
    <span
      className={classnames(
        "absolute right-2 text-5xl bottom-[2px] opacity-60 hover:cursor-pointer active:opacity-80",
        { hidden: !clearButton?.isShown }
      )}
      onClick={clearButton?.onClick}
    >
      x
    </span>
  </div>
);
