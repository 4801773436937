import React from "react";
import { IconProps } from "../Types";

const RightArrow = ({ className, stroke }: IconProps) => (
  <svg
    stroke={stroke}
    className={className}
    width="10"
    height="17"
    viewBox="0 0 10 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L8.5 8.5L1 16" stroke={stroke || "#333333"} />
  </svg>
);

export default RightArrow;
