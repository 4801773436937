import React from "react";
import { CardProps } from "./Types";
import { Typography } from "../typography/Typography";
import { Icon } from "../icon/Icon";
import { Button } from "../button/Button";
import { Image } from "../image";
import classnames from "classnames";

export function Card({
  title,
  showAddButton,
  onAddButtonClick,
  image,
}: CardProps) {
  return (
    <div className="flex flex-col p-1">
      <div className="flex flex-col gap-4 items-center mb-6">
        <Typography
          tag="h3"
          className="sm:text-4xl text-3xl font-normal text-center h-[50px] mx-9"
        >
          {title}
        </Typography>
        <Icon name="OpenDoor" />
        {image && (
          <Image src={image.src} title={image.title} height="8.25rem" />
        )}
      </div>
      <div
        className={classnames("flex justify-center", {
          invisible: !showAddButton,
        })}
      >
        <Button
          icon={"CardSelect"}
          onClick={onAddButtonClick}
          className="hover:opacity-70 active:opacity-80"
        />
      </div>
    </div>
  );
}
