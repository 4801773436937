import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { Button } from "../components/button/Button";
import { Icon } from "../components/icon/Icon";
import { Logo } from "../components/logo/Logo";
import { Typography } from "../components/typography";
import { ContactForm } from "../components/contactForm/ContactForm";
import { useForm } from "../components/contactForm/useForm";
import { DataContainerContext } from "../common/context/DataContainerProvider";
import { ResponseType } from "../components/contactForm/Types";
import { generateUID } from "../services/Helpers";
import QRCode from "qrcode.react";
import classnames from "classnames";
import { Image } from "../components/image";
import { Counter } from "../components/counter/index";

import {
  AccordionEnum,
  ExcludedFromSummaryComponents,
} from "../services/enums";

export const OrderPage = () => {
  const [selectedVariations, setSelectedVariations] = useState([]);
  const { dataContainer } = useContext(DataContainerContext);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [response, setResponse] = useState<ResponseType>();
  const location = useLocation();

  const getSelectedVariations = () => {
    setSelectedVariations(dataContainer?.getConfigurations());
  };

  const filterSelectedVariations = (variations) => {
    const unSortedVariations = variations.filter((variation) => {
      return !dataContainer.hasSubstring(
        ExcludedFromSummaryComponents,
        variation
      );
    });

    return unSortedVariations.sort(function (a, b) {
      return a.id - b.id;
    });
  };

  const removeSelectedProduct = (id, i) => {
    const productType = dataContainer?.findVariationBy(id);
    dataContainer?.removeModelFromConfiguration(
      i,
      productType.product_category
    );
    setShouldRefresh(!shouldRefresh);
  };

  const getUniqueHashForSelections = () => {
    return dataContainer?.generateShareableLink(location.state);
  };

  const navigate = useNavigate();

  const successMailMessage = dataContainer?.sectionKey(
    "Translations",
    "success_mail_message"
  );

  const errorMailMessage = dataContainer?.sectionKey(
    "Translations",
    "error_mail_message"
  );

  const contactForm = useForm({
    onSubmit: async (data) => {
      const request = {
        properties: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone_number: data.phone_number,
          address: data.address,
          co: data.co,
          zip_code: data.zip_code,
          country: data.country,
          message: data.message,
          configuration_link: getUniqueHashForSelections(),
        },
      };
      dataContainer.submitForm(request, (res) => {
        if (res) {
          setResponse({ message: successMailMessage, success: true });
          navigate("/confirmation");
        } else {
          setResponse({ message: errorMailMessage, success: false });
        }
      });
    },
  });

  const getDescription = (currentVariation, selectedVariations, index) => {
    const component = dataContainer?.findComponentById(
      currentVariation.component_id
    );
    if (component.name !== AccordionEnum.Model) {
      return dataContainer?.sectionKey(
        dataContainer?.getProductDescriptions(currentVariation),
        currentVariation.original_key
      );
    }
    return dataContainer?.extractModelDescription(selectedVariations, index);
  };

  useEffect(() => {
    if (dataContainer) {
      getSelectedVariations();
      dataContainer.logEvent("page_entered", "Order page", { product: "" });
    } else {
      navigate("/");
    }
  }, [dataContainer, shouldRefresh]);

  return (
    <div className="w-full h-full bg-smoke sm:pt-7 sm:pl-10 sm:pr-20 sm:pb-12 p-8 overflow-auto">
      <div className="flex flex-col sm:items-start items-center justify-center">
        <Logo to="/" className="sm:hidden flex mb-6" />
        <Button
          secondary
          className="sm:mb-9 mb-8"
          textClassName="ml-3.5"
          icon="Prev"
          label="Tillbaka"
          onClick={() => navigate(-1)}
        />
      </div>
      <Typography
        tag="h1"
        className="text-primary leading-snug mb-3 font-normal sm:text-6xl text-4xl"
        parentClassName="h-auto"
      >
        {dataContainer?.sectionKey("Translations", "order_page_title")}
      </Typography>
      <Typography
        tag="h3"
        className="mb-5 font-normal text-black-faded sm:text-4xl text-3xl"
        parentClassName="h-auto"
      >
        {dataContainer?.sectionKey("Translations", "order_page_description")}
      </Typography>
      <div className="flex mb-6 sm:flex-row flex-col">
        <div className="sm:w-7/12 w-full bg-white py-7 px-2.5 mr-5 sm:mb-0 mb-5">
          {selectedVariations?.map((configuration) => (
            <div className="product mb-10" key={generateUID(9)}>
              <Typography
                tag="h3"
                className="text-black-faded leading-4 mb-5 pl-7 font-normal"
              >
                {`${
                  dataContainer?.findVariationByComponentName(
                    configuration.variations,
                    AccordionEnum.Model
                  )?.key || ""
                }`}
              </Typography>
              {filterSelectedVariations(configuration.variations).map(
                (product) => (
                  <div
                    key={product.key}
                    className="items flex items-center justify-between border border-gray-mid mb-1 px-7 py-5 relative"
                  >
                    <div className="flex items-center sm:mb-0 mb-8">
                      <Image
                        src={product.assets?.[0]?.preview_image_url}
                        title={product.name}
                        className="w-12 h-12 flex relative items-center justify-center object-contain"
                      />
                      <div className="pl-6">
                        <Typography
                          tag="h4"
                          className="leading-5 mb-1 text-black-faded"
                          parentClassName="h-auto"
                        >
                          {product.key}
                        </Typography>
                        <Typography
                          tag="p"
                          parentClassName="h-auto"
                          className="text-black-faded opacity-70 sm:w-full w-2/3"
                        >
                          {product &&
                            getDescription(
                              product,
                              configuration.variations,
                              configuration.index
                            )}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex">
                      {dataContainer
                        .findComponentById(product.component_id)
                        ?.name.includes("Model") && (
                        <Counter
                          className="mr-14"
                          configuration={configuration}
                          dataContainer={dataContainer}
                        />
                      )}
                      <Button
                        icon="Remove"
                        onClick={() => {
                          removeSelectedProduct(
                            product.id,
                            configuration.index
                          );
                        }}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
        </div>
        <div className="sm:w-5/12 w-full bg-white p-7 sm:pb-[9.75rem] pb-9">
          <Typography
            tag="h3"
            className="font-normal leading-5 mb-5 text-black-faded"
            parentClassName="h-auto"
          >
            {dataContainer?.sectionKey("Translations", "order_title")}
          </Typography>
          <Typography
            tag="p"
            className="font-normal sm:leading-3 leading-4 pb-5 border-b border-gray-mid text-black-faded"
            parentClassName="h-auto"
          >
            {dataContainer?.sectionKey("Translations", "order_text")}
          </Typography>
          <div className="flex flex-col lg:flex-row justify-between mt-5 mb-16">
            <div className="flex flex-col">
              <Typography
                tag="h3"
                className="font-normal mb-6 text-black-faded"
                parentClassName="h-auto"
              >
                {dataContainer?.sectionKey("Translations", "order_links_title")}
              </Typography>
              <a
                href={getUniqueHashForSelections()}
                className="flex flex-wrap items-center sm:text-xs text-3xl text-primary font-normal mb-5"
              >
                {dataContainer?.sectionKey(
                  "Translations",
                  "order_link_3_label"
                )}
                <Icon name="Link" className="ml-2.5" />
              </a>
            </div>
            {dataContainer && (
              <QRCode
                id={getUniqueHashForSelections()}
                value={getUniqueHashForSelections()}
                size={200}
                includeMargin
                className="mt-3.5 ml-5 mr-4 sm:flex hidden"
              />
            )}
          </div>
          <a
            href={dataContainer?.sectionKey(
              "Translations",
              "order_link_4_website"
            )}
            target="_blank"
            rel="noopener noreferrer"
            className="text-4xl text-primary sm:font-normal font-medium"
          >
            {dataContainer?.sectionKey("Translations", "order_link_4_label")}
          </a>
          <ContactForm
            data={contactForm.data}
            errors={contactForm.errors}
            handleChange={contactForm.handleChange}
            handleSubmit={contactForm.handleSubmit}
            dataContainer={dataContainer}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <Logo to="/" className="sm:flex hidden" />
        <div>
          <Button
            label="KONTAKTA MIG"
            icon="RightGreenArrow"
            secondary
            className="flex-row-reverse bg-white py-3 px-6 text-black-faded"
            iconClassName="pl-4"
            onClick={() => contactForm.handleSubmit()}
          />
          {response && (
            <p
              className={classnames(
                "mt-5 text-xs",
                { "text-green-500": response.success },
                { "text-red": !response.success }
              )}
            >
              {response.message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
