import React from "react";
import { IconProps } from "../Types";

const BottomArrow = ({ className }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="8"
    viewBox="0 0 16 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15 1L8 7L1 0.999999" stroke="#333333" strokeLinecap="round" />
  </svg>
);

export default BottomArrow;
