import React from "react";
import { Icon } from "../icon/Icon";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { styles } from "./consts";
import { SelectInputProps } from "./Types";

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <Icon name={props.selectProps.menuIsOpen ? "TopArrow" : "BottomArrow"} />
  </components.DropdownIndicator>
);

export const SelectInput = ({
  name,
  id,
  onChange,
  options,
  disabled,
  placeholder,
}: SelectInputProps) => (
  <div className="inline-flex relative text-xs">
    <Select
      id={id}
      name={name}
      options={options}
      onChange={onChange}
      isDisabled={disabled}
      styles={styles}
      isSearchable={false}
      placeholder={placeholder}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      className="text-4xl"
    />
  </div>
);
