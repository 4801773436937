import React from "react";
import { RadioInputProps } from "./Types";
import classNames from "classnames";

export const RadioInput = ({
  label,
  name,
  value,
  uniqueKey,
  id,
  onChange,
  disabled,
  className,
  checked,
}: RadioInputProps) => {
  const labelClassNames =
    "relative inline-flex items-center text-black-secondary text-3xl peer-checked:after:bg-primary pl-6 cursor-pointer";
  const beforeClassNames =
    "before:absolute before:left-0 before:top-[calc(50% - 0.375rem)] before:w-3 before:h-3 before:border-solid before:border before:border-gray";
  const afterClassNames =
    "after:absolute after:left-[0.125rem] after:top-[calc(50% - 1rem)] after:w-2 after:h-2 after:border-solid";

  return (
    <div className={`relative flex items-center ${className}`}>
      <input
        type="radio"
        id={id}
        value={value}
        name={name}
        className="absolute opacity-0 peer h-0 w-0"
        onChange={onChange}
        disabled={disabled}
        checked={checked}
        data-unique-key={uniqueKey}
      />
      <label
        htmlFor={id}
        className={classNames(
          labelClassNames,
          beforeClassNames,
          afterClassNames,
          { "before:bg-gray opacity-50 cursor-not-allowed": disabled }
        )}
      >
        {label}
      </label>
    </div>
  );
};
