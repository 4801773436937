import React from "react";
import { IconProps } from "../Types";

const AddWindow = ({ className }: IconProps) => (
  <svg
    width="15"
    height="20"
    viewBox="0 0 15 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="0.5" y="0.5" width="13.3182" height="18.0909" stroke="white" />
    <line
      x1="7.18359"
      y1="-2.18557e-08"
      x2="7.18359"
      y2="19.0909"
      stroke="white"
    />
    <line
      x1="14.3164"
      y1="9.08984"
      x2="-0.00177574"
      y2="9.08984"
      stroke="white"
    />
  </svg>
);

export default AddWindow;
